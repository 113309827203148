<template>
    <v-container fluid>

        <!-- Titulo e Botões -->
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-toolbar-title class="ml-2 mt-2">Acordos Comerciais</v-toolbar-title>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">
                <v-btn
                    v-if="modelos.length > 0"
                    class="ml-2 my-1"
                    color="primary"
                    @click="novo()"
                >
                    <v-icon left>mdi-plus</v-icon> Criar Acordo
                </v-btn>
                <v-btn
                    class="ml-2 my-1"
                    color="primary"
                    @click="exportar()"
                >
                    <v-icon left>mdi-microsoft-excel</v-icon> Exportar
                </v-btn>
                <v-btn
                    class="ml-2 my-1"
                    color="primary"
                    @click="detalhado()"
                >
                    <v-icon left>mdi-microsoft-excel</v-icon> Detalhado
                </v-btn>
                <v-menu offset-y v-if="importarLote.lista.length">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                        class="ml-2 my-1"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        >
                            <v-icon left>mdi-upload</v-icon> Importar Lote
                        </v-btn>
                    </template>
                    <v-list dense nav>
                        <v-list-item
                            v-for="(item, index) in importarLote.lista"
                            :key="index"
                            link
                            @click="abrirImportarLote(item)"
                        >
                            <v-list-item-content>
                                <v-list-item-title>{{ item.desc }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>

        <!-- Filtros -->
        <v-card class="mt-2 py-4" elevation="1">
            <v-row no-gutters>
                <v-col cols="12" class="ml-4">
                    <span 
                        @click="mostrarOcultar('filtros')"
                        style="cursor: pointer; font-weight: bold;"
                    >
                        {{mostrar.filtros.nome}}
                        <v-icon
                            class="ml-2 mr-4"
                            color="primary"
                        >
                            {{mostrar.filtros.icone}}
                        </v-icon>
                    </span>
                </v-col>
            </v-row>
            <v-expand-transition>
                <v-form ref="formFiltro" v-show="mostrar.filtros.flag">
                    <v-row no-gutters class="mt-2">
                        <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                            <v-text-field
                                label="Período Inicial"
                                v-model="main.filtro.mesInicial"
                                :rules="main.regra.mesInicial"
                                required
                                outlined
                                dense 
                                type="month"
                                :style="$vuetify.theme.dark ? `color-scheme: dark;` : ``"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                            <v-text-field
                                label="Período Final"
                                v-model="main.filtro.mesFinal"
                                :rules="main.regra.mesFinal"
                                required
                                outlined
                                dense 
                                type="month"
                                :style="$vuetify.theme.dark ? `color-scheme: dark;` : ``"
                            ></v-text-field>
                        </v-col> 
                        <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                            <v-text-field
                                label="Acordo"
                                outlined
                                dense 
                                v-model="main.filtro.id_acordo"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                            <v-text-field
                                label="Contrato"
                                outlined
                                dense 
                                v-model="main.filtro.id_contrato"
                            ></v-text-field>
                        </v-col>
                        <v-col v-for="(obj, index) in main.lista.filtro" :key="index" cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                            <v-autocomplete
                                :label="obj.nome"
                                :items="main.lista[obj.tabela]"
                                v-model="main.filtro[obj.var_id]"
                                multiple
                                clearable
                                outlined
                                dense 
                            >
                                <template v-slot:prepend-item>
                                    <v-list-item
                                    ripple
                                    @mousedown.prevent
                                    @click="selecionarTudo(obj.var_id, obj.tabela, main.filtro, main.lista)"
                                    >
                                    <v-list-item-action>
                                        <v-icon :color="main.filtro[obj.var_id].length > 0 ? 'indigo darken-4' : ''">
                                        {{ iconeSelecionarTudo(obj.var_id, obj.tabela, main.filtro, main.lista) }}
                                        </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                        Selecionar Tudo
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>
                                <template v-slot:selection="{item, index}">
                                    <div 
                                        v-if="main.filtro[obj.var_id].length === 1 && index === 0"
                                        style="width: 70% !important; white-space: nowrap !important; text-overflow: ellipsis !important; overflow: hidden;"
                                    >{{ item.text }}</div>
                                    <span
                                        v-if="main.filtro[obj.var_id].length > 1 && index === 0"
                                        class="grey--text caption mr-1"
                                    >{{ main.filtro[obj.var_id].length }} selecionados</span>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="5" lg="3" xl="3" class="px-2 ml-auto" align="end">
                            <v-btn
                                class="ml-2 mb-1"
                                color="primary"
                                @click="limpar()"
                            >
                                <v-icon left>mdi-filter-off</v-icon> Limpar
                            </v-btn> 
                            <v-btn
                                class="ml-2 mb-1"
                                color="primary"
                                @click="filtrar()"
                            >
                                <v-icon left>mdi-filter</v-icon> Filtrar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-expand-transition>
        </v-card>
        
        <!-- Indicadores -->
        <v-card class="mt-2 py-4" elevation="1">
            <v-row no-gutters>
                <v-col cols="12" class="ml-4">
                    <span 
                        @click="mostrarOcultar('indicadores')"
                        style="cursor: pointer; font-weight: bold;"
                    >
                        {{mostrar.indicadores.nome}}
                        <v-icon
                            class="ml-2 mr-4"
                            color="primary" 
                        >
                            {{mostrar.indicadores.icone}}
                        </v-icon>
                    </span>
                </v-col>
            </v-row>
            <v-expand-transition>
                <v-row no-gutters v-show="mostrar.indicadores.flag">
                    <v-tooltip 
                        v-for="indicador in indicadoresTotal"
                        :key="indicador.nome"
                        top 
                        :color="indicador.cor"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-card
                                class="elevation-1 ml-3 mt-3 indicadoresAcordoCartao"
                                @click="indicador.acao()"
                                v-bind="attrs"
                                v-on="on"
                                :color="indicador.cor"
                            >
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div 
                                            class="indicadoresAcordoTitulo"
                                            v-bind="attrs"
                                            v-on="on"
                                        >{{indicador.nome}}</div>
                                    </template>
                                    <span>{{indicador.desc}}</span>
                                </v-tooltip>
                                <v-avatar 
                                    class="indicadoresAcordoIcone"
                                    size="46"
                                    :color="indicador.cor2 ? indicador.cor2 : indicador.cor + ' darken-1'"
                                >
                                    <v-icon dark>{{indicador.icone}}</v-icon>
                                </v-avatar>
                                <div v-show="indicador.quantSufixo"
                                    class="indicadoresAcordoQuant"
                                >{{(indicador.quant*1)}}</div>
                                <div v-show="indicador.valorSufixo"
                                    class="indicadoresAcordoValor"
                                >{{(indicador.valor*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</div>
                            </v-card>
                        </template>
                        <span>{{indicador.acaoTitulo}}</span>
                    </v-tooltip>
                </v-row>
            </v-expand-transition>
        </v-card>

        <!-- Listagem -->
        <v-card class="mt-2 py-4" elevation="1">
            <v-row no-gutters>
                <v-col cols="12" class="ml-4">
                    <span 
                        @click="mostrarOcultar('lista')"
                        style="cursor: pointer; font-weight: bold;"
                    >
                        {{mostrar.lista.nome}}
                        <v-icon
                            class="ml-2 mr-4"
                            color="primary"
                        >
                            {{mostrar.lista.icone}}
                        </v-icon>
                    </span>
                </v-col>
            </v-row>
            <v-expand-transition>
                <v-data-table
                    v-show="mostrar.lista.flag"
                    :headers="headers"
                    :items="dados"
                    :server-items-length="total" 
                    :items-per-page="5"
                    :footer-props="{
                        'items-per-page-options': [5, 10, 15, 25, 50, 100]
                    }"
                    :options.sync="pagination"
                    multi-sort
                    class="elevation-0 mx-2"
                >
                    <template v-slot:[`item.acordo_modelo_data`]="{ item }">
                        {{item.acordo_modelo_data ? $moment(item.acordo_modelo_data).format('DD/MM/YYYY') : ''}}
                    </template>
                    <template v-slot:[`item.acordo_ad_6`]="{ item }">
                        {{item.acordo_ad_6 ? $moment(item.acordo_ad_6).format('DD/MM/YYYY') : ''}}
                    </template>
                    <template v-slot:[`item.acordo_ad_7`]="{ item }">
                        {{item.acordo_ad_7 ? $moment(item.acordo_ad_7).format('DD/MM/YYYY') : ''}}
                    </template>
                    <template v-slot:[`item.acordo_ad_8`]="{ item }">
                        {{item.acordo_ad_8 ? $moment(item.acordo_ad_8).format('DD/MM/YYYY') : ''}}
                    </template>
                    <template v-slot:[`item.acordo_ad_9`]="{ item }">
                        {{item.acordo_ad_9 ? $moment(item.acordo_ad_9).format('DD/MM/YYYY') : ''}}
                    </template>
                    <template v-slot:[`item.acordo_ad_10`]="{ item }">
                        {{item.acordo_ad_10 ? $moment(item.acordo_ad_10).format('DD/MM/YYYY') : ''}}
                    </template>
                    <template v-slot:[`item.percentual_executado`]="{ item }">
                        {{item.percentual_executado + '%'}}
                    </template>
                    <template v-slot:[`item.menu`]="{ item }">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    class="mr-2"
                                    color="primary"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    mdi-menu
                                </v-icon>
                            </template>
                            <v-card>
                                <v-list dense nav>
                                    <v-list-item 
                                        link 
                                        @click="buscaHistorico(item)"
                                    >
                                        <v-list-item-icon>
                                            <v-icon color="primary">mdi-file-document-outline</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Histórico</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item 
                                        v-if="item.auditorias > 0"
                                        link 
                                        @click="buscaAuditorias(item)"
                                    >
                                        <v-list-item-icon>
                                            <v-icon color="primary">mdi-image</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Auditorias</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item
                                        link 
                                        @click="buscaRecibo(item)"
                                    >
                                        <v-list-item-icon>
                                            <v-icon color="primary">mdi-receipt-text</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Recibo</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item 
                                        v-if="item.modelo_arquivo && camposListagem.find(e => e.campo == 'modelo_arquivo')"
                                        link 
                                        @click="mostraArquivo(item)"
                                    >
                                        <v-list-item-icon>
                                            <v-icon color="primary">mdi-file-image-outline</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{camposListagem.find(e => e.campo == 'modelo_arquivo').nome}}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="modelosLista.find(e => e.id_modelo == item.id_modelo).tipo == 'Faturamento'"
                                        link 
                                        @click="buscaAcordoInvestimentoFaturamento(item)"
                                    >
                                        <v-list-item-icon>
                                            <v-icon color="primary">mdi-invoice-list</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Investimento / Faturado</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="modelosLista.find(e => e.id_modelo == item.id_modelo).passos.find(e => e.tipo == 'filiais')"
                                        link 
                                        @click="buscaAcordoFiliais(item)"
                                    >
                                        <v-list-item-icon>
                                            <v-icon color="primary">mdi-store</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Filiais</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item 
                                        v-for="(processo, index) in (item.processos ? item.processos.filter(e => e.function != 'comprovaAcordo' || (e.function == 'comprovaAcordo' && item.acoes_por_acordo > 0) ) : [])" :key="index"
                                        link
                                        @click="processar(item, processo)"
                                    >
                                        <v-list-item-icon>
                                            <v-icon :color="processo.color">{{processo.icon}}</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{processo.nome}}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item 
                                        v-for="(upload, index) in (item.uploads)" :key="index+1000"
                                        link
                                        @click="mostraUpload(item, upload)"
                                    >
                                        <v-list-item-icon>
                                            <v-icon :color="upload.color">{{upload.icon}}</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{upload.nome}}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-menu>
                    </template>
                    <template v-slot:[`item.sla_minutos`]="{ item }">
                        <v-chip
                            v-if="item.sla"
                            class="ma-1"
                            :color=" item.sla_minutos > 0 ? ( item.sla_minutos > 60*24 ? 'success' : 'warning' ) : 'error' "
                            label
                            text-color="white"
                            small
                        >
                            <span style="width: 70px;">
                                {{ item.sla_d != 0 ? item.sla_d + 'd' : '' }} {{ item.sla_h != 0 && 10 > Math.abs(item.sla_d) ? item.sla_h + 'h' : '' }} {{ item.sla_m != 0 && item.sla_d == 0? item.sla_m + 'm' : '' }}
                            </span>
                        </v-chip>
                    </template>
                </v-data-table>
            </v-expand-transition>
        </v-card>

        <!-- Modelo de Acordo -->
        <v-dialog
            v-model="dialogoModelo"
            width="300"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Selecione o Tipo do Acordo
                </v-card-title>
                    <div v-for="(m, i) in modelos" :key="i">
                        <v-list dense nav>
                            <v-list-item link @click="setModelo(m)">
                                <v-list-item-icon>
                                    <v-icon>mdi-file-document</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{m.nome}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <v-divider></v-divider>
                    </div>
            </v-card>
        </v-dialog>

        <!-- Formulario do Acordo -->
        <v-dialog
            v-model="formulario.dialogo"
            width="94%"
            scrollable
        >
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Acordo - {{modelo.nome}}
                </v-card-title>
                <v-card-text class="pa-0">
                    <v-stepper v-model="formulario.passo" class="elevation-0">
                        <v-stepper-header class="elevation-1" :style="`position: fixed; width: calc(94%); z-index: 4; background-color: ${$vuetify.theme.dark ? '#1E1E1E' : '#fff'};`">
                            <div  v-for="(p, i) in modelo.passos" :key="i">
                                <v-stepper-step
                                    :complete="formulario.passo > (i+1)" 
                                    :step="(i+1)"
                                >{{p.nome}}</v-stepper-step>
                                <v-divider v-if="(i+1) < modelo.passos.length"></v-divider>
                            </div>
                        </v-stepper-header>
                        <v-stepper-items class="mt-13" style="min-height: 200px;">
                            <v-stepper-content 
                                v-for="(p, i) in modelo.passos"
                                :key="i"
                                :step="(i+1)"
                            >
                                <v-form ref="form">
                                    <v-row no-gutters class="mt-4">
                                        <!-- Periodo Inicial -->
                                        <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="px-2"  v-if="p.tipo == 'periodo'">
                                            <v-text-field
                                                label="Período Inicial"
                                                v-model="acordo.mesInicial"
                                                :rules="regra.mesInicial"
                                                :min="mesLimite"
                                                :disabled="acordo.fnEdicao"
                                                outlined
                                                dense 
                                                type="month"
                                                :style="$vuetify.theme.dark ? `color-scheme: dark;` : ``"
                                            ></v-text-field>
                                        </v-col>
                                        <!-- Periodo Final -->
                                        <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="px-2"  v-if="p.tipo == 'periodo'">
                                            <v-text-field
                                                label="Período Final"
                                                v-model="acordo.mesFinal"
                                                :rules="regra.mesFinal"
                                                :min="mesLimite"
                                                :disabled="acordo.fnEdicao"
                                                outlined
                                                dense 
                                                type="month"
                                                :style="$vuetify.theme.dark ? `color-scheme: dark;` : ``"
                                            ></v-text-field>
                                        </v-col>
                                        <!-- Data de vencimento -->
                                        <v-col v-for="m in (p.tipo == 'periodo' && p.data ? getMeses : [])" :key="'mes_'+m" cols="12" sm="12" md="4" lg="4" xl="4" class="px-2">
                                            <v-text-field
                                                :label="p.data_nome + ' - ' + meses[ m - 1 ]"
                                                v-model="acordo.modelo_data[m]"
                                                :rules="p.data_obrigatorio ? regra.obrigatorio : [true]"
                                                outlined
                                                dense 
                                                type="date"
                                                :style="$vuetify.theme.dark ? `color-scheme: dark;` : ``"
                                            ></v-text-field>
                                        </v-col>
                                        <!-- Campos do cadastro -->
                                        <v-col v-for="(obj, index) in p.tabelas" :key="index" v-show="['campo', 'filial', 'filtro'].includes(obj.tipo)" cols="12" sm="12" 
                                            :md="obj.exibicao && obj.exibicao != 'ID Loja - CNPJ - Razão Social' ? 8 : 4"
                                            :lg="obj.exibicao && obj.exibicao != 'ID Loja - CNPJ - Razão Social' ? 8 : 4"
                                            :xl="obj.exibicao && obj.exibicao != 'ID Loja - CNPJ - Razão Social' ? 8 : 4"
                                            class="px-2"
                                        >
                                            <v-autocomplete
                                                v-if="obj.tipo == 'campo' && (!acordo.fnEdicao || (!obj.origem && obj.busca_inventario == 0 && obj.valida_inventario == 0 && obj.tabela != 'loja'))"
                                                :label="obj.exibicao && obj.exibicao != 'ID Loja - CNPJ - Razão Social' ? obj.exibicao : obj.nome"
                                                :items="lista[obj.tabela]"
                                                v-model="acordo[obj.var_id]"
                                                :rules="regra.obrigatorio"
                                                v-on:change="buscaCampoProximo(obj)"
                                                outlined
                                                dense 
                                            >
                                            </v-autocomplete>
                                            <v-text-field
                                                v-if="obj.tipo == 'campo' && acordo.fnEdicao && (obj.origem || obj.busca_inventario != 0 || obj.valida_inventario != 0 || obj.tabela == 'loja')"
                                                :label="obj.exibicao && obj.exibicao != 'ID Loja - CNPJ - Razão Social' ? obj.exibicao : obj.nome"
                                                v-model="acordo[obj.tabela]"
                                                disabled
                                                outlined
                                                dense
                                            ></v-text-field>
                                            <!-- Filtro de Filiais -->
                                            <v-autocomplete
                                                v-if="obj.tipo == 'filial'"
                                                :label="obj.nome"
                                                :items="lista[obj.var_id]"
                                                v-model="filtroFilial[obj.var_id]"
                                                v-on:change="filtrarFilial"
                                                multiple
                                                clearable
                                                outlined
                                                dense 
                                            >
                                                <template v-slot:prepend-item>
                                                    <v-list-item
                                                    ripple
                                                    @mousedown.prevent
                                                    @click="selecionarTudo(obj.var_id, obj.var_id, filtroFilial, lista)"
                                                    >
                                                    <v-list-item-action>
                                                        <v-icon :color="filtroFilial[obj.var_id].length > 0 ? 'indigo darken-4' : ''">
                                                        {{ iconeSelecionarTudo(obj.var_id, obj.var_id, filtroFilial, lista) }}
                                                        </v-icon>
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                        Selecionar Tudo
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                    </v-list-item>
                                                    <v-divider class="mt-2"></v-divider>
                                                </template>
                                                <template v-slot:selection="{item, index}">
                                                    <div 
                                                        v-if="filtroFilial[obj.var_id].length === 1 && index === 0"
                                                        style="width: 70% !important; white-space: nowrap !important; text-overflow: ellipsis !important; overflow: hidden;"
                                                    >{{ item.text }}</div>
                                                    <span
                                                        v-if="filtroFilial[obj.var_id].length > 1 && index === 0"
                                                        class="grey--text caption mr-1"
                                                    >{{ filtroFilial[obj.var_id].length }} selecionados</span>
                                                </template>
                                            </v-autocomplete>
                                            <!-- Filtro de Investimento -->
                                            <v-autocomplete
                                                v-if="obj.tipo == 'filtro'"
                                                :label="obj.nome"
                                                :items="lista[obj.tabela]"
                                                v-model="filtro[obj.var_id]"
                                                v-on:change="filtrarInvestimento(obj.tabela)"
                                                multiple
                                                clearable
                                                outlined
                                                dense 
                                            >
                                                <template v-slot:prepend-item>
                                                    <v-list-item
                                                    ripple
                                                    @mousedown.prevent
                                                    @click="selecionarTudo(obj.var_id, obj.tabela, filtro, lista)"
                                                    >
                                                    <v-list-item-action>
                                                        <v-icon :color="filtro[obj.var_id].length > 0 ? 'indigo darken-4' : ''">
                                                        {{ iconeSelecionarTudo(obj.var_id, obj.tabela, filtro, lista) }}
                                                        </v-icon>
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                        Selecionar Tudo
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                    </v-list-item>
                                                    <v-divider class="mt-2"></v-divider>
                                                </template>
                                                <template v-slot:selection="{item, index}">
                                                    <div 
                                                        v-if="filtro['id_'+obj.tabela].length === 1 && index === 0"
                                                        style="width: 70% !important; white-space: nowrap !important; text-overflow: ellipsis !important; overflow: hidden;"
                                                    >{{ item.text }}</div>
                                                    <span
                                                        v-if="filtro['id_'+obj.tabela].length > 1 && index === 0"
                                                        class="grey--text caption mr-1"
                                                    >{{ filtro['id_'+obj.tabela].length }} selecionados</span>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    <!-- E-mail aprovador, Nota fiscal e observações -->
                                    <v-row no-gutters class="mt-0">
                                        <v-col v-if="p.tipo == 'cadastro' && ['Faturamento', 'Faturamento_produto', 'Faturamento_total'].includes(modelo.tipo)" cols="12" sm="12" md="4" lg="4" xl="4" class="px-2">
                                            <v-text-field
                                                label="Nota Fiscal"
                                                v-model="acordo.nota_fiscal"
                                                :rules="regra.nota_fiscal"
                                                readonly
                                                outlined
                                                dense 
                                                type="email"
                                                @click="buscaNotas()"
                                            ></v-text-field>
                                        </v-col> 
                                        <v-col v-for="i in (p.tipo == 'cadastro' && p.email ? 5 : 0)" :key="'email_'+i" cols="12" sm="12" md="4" lg="4" xl="4" class="px-2">
                                            <v-text-field
                                                :label="'E-mail do aprovador do recibo'"
                                                v-model="acordo.email[i-1]"
                                                :rules="regra.email"
                                                outlined
                                                dense 
                                                type="email"
                                            ></v-text-field>
                                        </v-col> 
                                        <v-col cols="12" v-if="p.tipo == 'cadastro' && p.email_exibir && acordo.id_loja" class="ma-1 body-2">
                                            E-mail(s) do(s) aprovador(es) do recibo: {{acordo.email.join(', ')}}
                                        </v-col>
                                        <v-col v-for="m in (p.tipo == 'cadastro' && p.acordo_cliente_1 ? ( p.acordo_cliente_1_periodo ? getMeses : [0] ) : [])" :key="'mes_1_'+m" cols="12" sm="12" md="4" lg="4" xl="4" class="px-2">
                                            <v-text-field
                                                :label="p.acordo_cliente_1_nome + (p.acordo_cliente_1_periodo ? ' - ' + meses[ m - 1 ] : '')"
                                                v-model="acordo.modelo_acordo_1[m]"
                                                :rules="p.acordo_cliente_1_obrigatorio ? regra.obrigatorio : [true]"
                                                v-mask="'############'"
                                                outlined
                                                dense
                                            ></v-text-field>
                                        </v-col> 
                                        <v-col v-for="m in (p.tipo == 'cadastro' && p.acordo_cliente_2 ? ( p.acordo_cliente_2_periodo ? getMeses : [0] ) : [])" :key="'mes_2_'+m" cols="12" sm="12" md="4" lg="4" xl="4" class="px-2">
                                            <v-text-field
                                                :label="p.acordo_cliente_2_nome + (p.acordo_cliente_2_periodo ? ' - ' + meses[ m - 1 ] : '')"
                                                v-model="acordo.modelo_acordo_2[m]"
                                                :rules="p.acordo_cliente_2_obrigatorio ? regra.obrigatorio : [true]"
                                                v-mask="'############'"
                                                outlined
                                                dense
                                            ></v-text-field>
                                        </v-col> 
                                        <v-col v-for="m in (p.tipo == 'cadastro' && p.acordo_cliente_3 ? ( p.acordo_cliente_3_periodo ? getMeses : [0] ) : [])" :key="'mes_3_'+m" cols="12" sm="12" md="4" lg="4" xl="4" class="px-2">
                                            <v-text-field
                                                :label="p.acordo_cliente_3_nome + (p.acordo_cliente_3_periodo ? ' - ' + meses[ m - 1 ] : '')"
                                                v-model="acordo.modelo_acordo_3[m]"
                                                :rules="p.acordo_cliente_3_obrigatorio ? regra.obrigatorio : [true]"
                                                v-mask="'############'"
                                                outlined
                                                dense
                                            ></v-text-field>
                                        </v-col> 
                                        <v-col cols="12" class="px-2"  v-if="p.tipo == 'cadastro' && p.arquivo">
                                            <b>{{p.arquivo_texto}}</b>
                                        </v-col>
                                        <v-col cols="8" class="px-2"  v-if="p.tipo == 'cadastro' && p.arquivo">
                                            <v-file-input 
                                                :label="p.arquivo_nome" 
                                                v-model="acordo.modelo_arquivo"
                                                :rules="p.arquivo_obrigatorio && !acordo.fnEdicao ? regra.arquivo_obrigatorio : regra.arquivo"
                                                outlined 
                                                dense
                                                small-chips
                                                counter
                                                multiple
                                                show-size
                                                clearable
                                                @change="changeFile('arquivo')"
                                                @click:clear="clearFile('arquivo')"
                                            >
                                            <template v-slot:selection="{ text, index }">
                                                <v-chip small close @click:close="removeFile(index, 'arquivo')">
                                                    {{ text }}
                                                </v-chip>
                                            </template>
                                            </v-file-input>
                                        </v-col>
                                        <v-col cols="8" class="px-2"  v-if="p.obs">
                                            <v-textarea
                                                :label="p.obs_nome"
                                                v-model="acordo.obs[p.id_passo]"
                                                rows="2"
                                                outlined
                                                dense 
                                            ></v-textarea>
                                        </v-col>
                                        <v-col cols="4" class="px-2"  v-if="p.tipo == 'investimento' && modelo.historico_investimento">
                                            <v-btn
                                                color="primary"
                                                @click="abrirHistoricoInvestimento"
                                            >
                                                <v-icon left>mdi-currency-usd</v-icon>
                                                Histórico de Investimento
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                                <!-- Seleção de filiais -->
                                <v-data-table
                                    v-if="p.tipo == 'filiais'"
                                    :headers="headersFiliais"
                                    :items="filiais"
                                    :items-per-page="5"
                                    :footer-props="{
                                        'items-per-page-options': [5, 10, 15, 25, 50, 100]
                                    }"
                                    :options.sync="paginationFiliais"
                                    multi-sort
                                    class="elevation-0"
                                >
                                    <template v-slot:[`header.fn_filial`]="{ }">
                                        <div style="width: 24px; margin-left: auto; margin-right: auto; display: block;">
                                            <v-checkbox 
                                                v-model="fn_filial"
                                                @click="selecionarTudoFilial"
                                                color="#fff"
                                                class="ma-0 pa-0"
                                                hide-details
                                            ></v-checkbox >
                                        </div>
                                    </template>
                                    <template v-slot:[`item.fn_filial`]="{ item }">
                                        <v-simple-checkbox
                                            :ripple="false" 
                                            class="ml-2"
                                            v-model="item.fn_filial"
                                        ></v-simple-checkbox>
                                    </template>
                                </v-data-table>
                                <v-row no-gutters class="mt-2" v-if="p.tipo == 'filiais'">
                                    <v-col cols="6">
                                        <v-btn
                                            color="primary"
                                            :disabled="lojasSelecionadas==0"
                                            @click="importarFilial.dialogoSelecionadas=true"
                                        >
                                            <v-icon left>mdi-store-check</v-icon> 
                                            Filiais selecionadas: {{lojasSelecionadas}}
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="6" align="end">
                                        <v-btn
                                            class="ml-2 my-1"
                                            color="primary"
                                            @click="abrirImportarFiliais"
                                        >
                                            <v-icon left>mdi-upload</v-icon> Importar Filiais
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <!-- Investimento -->
                                <v-data-table
                                    v-if="p.tipo == 'investimento'"
                                    :headers="headersInvestimento"
                                    :items="investimento"
                                    :item-class="investimentoClass"
                                    :items-per-page="5"
                                    :footer-props="{
                                        'items-per-page-options': [5, 10, 15, 25, 50, 100]
                                    }"
                                    :options.sync="paginationInvestimento"
                                    multi-sort
                                    class="elevation-0"
                                >
                                    <template v-slot:header="{}">
                                        <thead>
                                            <tr>
                                                <th v-for="(h, i) in headersInvestimentoGroup" :key="i" :colspan="h.colspan" :class="h.class">
                                                    {{h.text}}
                                                </th>
                                            </tr>
                                        </thead>
                                    </template>
                                    <template v-for="m in 12" v-slot:[`header.fn_investimento_${m}`]="{ }">
                                        <div :key="m" style="width: 24px; margin-left: auto; margin-right: auto; display: block;">
                                            <v-checkbox 
                                                :key="m" 
                                                v-model="fn_investimento[m]"
                                                @click="selecionarTudoInvestimento(m)"
                                                color="#fff"
                                                class="ma-0 pa-0"
                                                hide-details
                                            ></v-checkbox >
                                        </div>
                                    </template>
                                    <template v-for="m in 12" v-slot:[`item.fn_investimento_${m}`]="{ item }">
                                        <v-simple-checkbox
                                            :key="m" 
                                            :ripple="false" 
                                            class="ml-2"
                                            v-model="item['fn_investimento_'+m]"
                                            @click="alteraInvestimento(item)"
                                        ></v-simple-checkbox>
                                    </template>
                                    <template v-for="m in 12" v-slot:[`item.produtos_${m}`]="{ item }">
                                        <div 
                                            :key="m" 
                                        >
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="abreProdutoInvestimento(item, m)"
                                            >
                                                {{item['produtos_'+m].filter(e => e.fn_produto).length}}
                                            </v-btn>
                                        </div>
                                    </template>
                                    <template v-for="m in 12" v-slot:[`item.valor_${m}`]="{ item }">
                                        <div 
                                            :key="m" 
                                        >
                                            {{(item['valor_'+m]*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                        </div>
                                    </template>
                                    <template v-for="m in 12" v-slot:[`item.percentual_${m}`]="{ item }">
                                        <div style="width: 70px; display: inline-block;" :key="m">
                                            <vuetify-money
                                                :key="m" 
                                                :ripple="false" 
                                                v-model="item['percentual_'+m]"
                                                outlined
                                                dense 
                                                hide-details
                                                backgroundColor=""
                                                @input="alteraInvestimento(item)"
                                                class="input_tabela input-moeda"
                                            />
                                        </div>
                                    </template>
                                    <template v-for="m in 12" v-slot:[`item.investimento_${m}`]="{ item }">
                                        <div  
                                            v-if="modelo.tipo == 'Planejado'" 
                                            style="width: 110px; display: inline-block;" 
                                            :key="m"
                                        >
                                            <vuetify-money
                                                :ripple="false" 
                                                v-model="item['investimento_'+m]"
                                                outlined
                                                dense 
                                                hide-details
                                                backgroundColor=""
                                                @input="alteraInvestimento(item)"
                                                class="input_tabela"
                                            />
                                        </div>
                                        <div v-if="modelo.tipo != 'Planejado'"
                                            :key="m" 
                                        >
                                            {{(item['investimento_'+m]*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                        </div>
                                    </template>
                                    <template v-for="m in 12" v-slot:[`item.investimento_valor_${m}`]="{ item }">
                                        <div 
                                            :key="m" 
                                        >
                                            {{(item['investimento_valor_'+m]*1).toLocaleString('pt-br', {style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2})+'%'}}
                                        </div>
                                    </template>
                                    <template v-for="m in 12" v-slot:[`item.disponivel_${m}`]="{ item }">
                                        <div 
                                            :key="m" 
                                            :class="item['erroValidaMensal_'+m] ? 'errorClass' : ''"
                                        >
                                            {{(item['disponivel_'+m]*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                        </div>
                                    </template>
                                    <template v-for="m in 12" v-slot:[`item.saldo_${m}`]="{ item }">
                                        <div 
                                            :key="m" 
                                            :class="item['erroValidaMensal_'+m] ? 'errorClass' : ''"
                                        >
                                            {{(item['saldo_'+m]*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                        </div>
                                    </template>
                                    <template v-slot:[`item.investimento`]="{ item }">
                                        {{(item.investimento*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                    </template>
                                    <template v-slot:[`item.disponivel`]="{ item }">
                                        {{(item.disponivel*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                    </template>
                                    <template v-slot:[`item.saldo`]="{ item }">
                                        {{(item.saldo*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                    </template>
                                </v-data-table>
                                <v-data-table
                                    v-if="p.tipo == 'investimento'"
                                    :headers="headersInvestimentoTotal"
                                    :items="investimentoTotal"
                                    :items-per-page="5"
                                    :footer-props="{
                                        'items-per-page-options': [5, 10, 15, 25, 50, 100]
                                    }"
                                    :options.sync="paginationInvestimentoTotal"
                                    multi-sort
                                    class="elevation-0"
                                >
                                    <template v-slot:[`header.campo`]="{ }">
                                        <v-menu offset-y v-if="modelo.tipo == 'Planejado'">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    color="white"
                                                    text
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    {{indicadores.find(e => e.value == indicador).text}}  
                                                    <v-icon>mdi-menu-down</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list dense nav>
                                                <v-list-item v-for="(item, index) in indicadores" :key="index" link @click="indicador=item.value">
                                                    <v-list-item-title v-text="item.text"></v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                        <div v-if="modelo.tipo != 'Planejado'">
                                            Total
                                        </div>
                                    </template>
                                    <template v-slot:[`item.faturado`]="{ item }">
                                        {{(item.faturado*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                    </template>
                                    <template v-for="m in 12" v-slot:[`item.investimento_${m}`]="{ item }">
                                        {{(item['investimento_'+m]*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                    </template>
                                    <template v-for="m in 12" v-slot:[`item.investimento_valor_${m}`]="{ item }">
                                        {{(item['investimento_valor_'+m]*1).toLocaleString('pt-br', {style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2})+'%'}}
                                    </template>
                                    <template v-slot:[`item.investimento`]="{ item }">
                                        {{(item.investimento*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                    </template>
                                </v-data-table>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>  
                </v-card-text>
                <v-divider></v-divider>
                <!-- Botões do formulario de acordo -->
                <v-card-actions>
                    <v-btn
                        color="primary"
                        width="130px"
                        :disabled="formulario.passo == 1"
                        @click="voltar"
                    >
                        <v-icon left>mdi-chevron-left</v-icon>
                        Voltar
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        width="130px"
                        class="mx-2"
                        @click="formulario.dialogo=false"
                    >
                        <v-icon left>mdi-close</v-icon> 
                        Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        width="130px"
                        @click="proximo"
                        v-show="formulario.passo < modelo.passos.length"
                    >
                        <span>Próximo</span>
                        <v-icon right>mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-btn
                        color="primary"
                        width="130px"
                        @click="proximo"
                        v-show="formulario.passo == modelo.passos.length"
                    >
                        <v-icon right>mdi-content-save</v-icon>
                        Salvar 
                    </v-btn>
                </v-card-actions> 
            </v-card>

            <!-- Selecionar Nota -->
            <v-dialog
                v-model="nota.dialogo"
                width="90%"
            >  
                <v-card>
                    <v-card-title class="subtitle-1 primary white--text">
                        Notas 
                    </v-card-title>
                    <v-data-table
                        :headers="headersNotas"
                        :items="nota.notas"
                        :items-per-page="5"
                        :footer-props="{
                            'items-per-page-options': [5, 10, 15, 25, 50, 100]
                        }"
                        multi-sort
                        class="elevation-1 data-table-2"
                    >
                        <template v-slot:[`header.fn_nota`]="{ }">
                            <div style="width: 24px; margin-left: auto; margin-right: auto; display: block;">
                                <v-checkbox 
                                    v-model="nota.fn_nota"
                                    @click="selecionarTudoNota"
                                    color="#fff"
                                    class="ma-0 pa-0"
                                    hide-details
                                ></v-checkbox >
                            </div>
                        </template>
                        <template v-slot:[`item.fn_nota`]="{ item }">
                            <v-simple-checkbox
                                :ripple="false" 
                                class="ml-2"
                                v-model="item.fn_nota"
                                @click="alteraNotas"
                            ></v-simple-checkbox>
                        </template>
                        <template v-slot:[`item.numero_produtos`]="{ item }"> 
                            <v-btn
                                text
                                color="primary"
                                @click="produtos(item)"
                            >
                                {{item.numero_produtos}}
                            </v-btn>
                        </template>
                        <template v-for="m in 12" v-slot:[`item.faturado`]="{ item }">
                            {{(item.faturado*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                        </template>
                        <template v-for="m in 12" v-slot:[`item.investimento`]="{ item }">
                            {{(item.investimento*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                        </template>
                        <template v-slot:[`item.selecionar`]="{ item }"> 
                            <v-icon
                                small
                                class="mr-2"
                                color="primary"
                                @click="selecionarNota(item)"
                                title="Selecionar Nota"
                            >
                                mdi-check-bold
                            </v-icon>
                        </template>
                    </v-data-table>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            class="pr-4"
                            @click="nota.dialogo=false"
                        >
                            <v-icon left>mdi-close</v-icon> Fechar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- Produtos Nota -->
            <v-dialog
                v-model="nota.produto.dialogo"
                width="90%"
            >  
                <v-card>
                    <v-card-title class="subtitle-1 primary white--text">
                        Produtos 
                    </v-card-title>
                    <v-data-table
                        :headers="nota.produto.headers"
                        :items="lista.produtos"
                        :items-per-page="5"
                        :footer-props="{
                            'items-per-page-options': [5, 10, 15, 25, 50, 100]
                        }"
                        multi-sort
                        class="elevation-1 data-table-2"
                    >
                        <template v-for="m in 12" v-slot:[`item.faturado`]="{ item }">
                            {{(item.faturado*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                        </template>
                    </v-data-table>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            class="pr-4"
                            @click="nota.produto.dialogo=false"
                        >
                            <v-icon left>mdi-close</v-icon> Fechar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- Selecionar Produtos -->
            <v-dialog
                v-model="produtoInvestimento.dialogo"
                width="90%"
            >  
                <v-card>
                    <v-card-title class="subtitle-1 primary white--text">
                        Produtos 
                    </v-card-title>
                    <v-data-table
                        :headers="produtoInvestimento.headers"
                        :items="produtoInvestimento.produtos"
                        :items-per-page="5"
                        :footer-props="{
                            'items-per-page-options': [5, 10, 15, 25, 50, 100]
                        }"
                        multi-sort
                        class="elevation-1 data-table-2"
                    >
                        <template v-slot:[`header.fn_produto`]="{ }">
                            <div style="width: 24px; margin-left: auto; margin-right: auto; display: block;">
                                <v-checkbox 
                                    v-model="produtoInvestimento.fn_produto"
                                    @click="selecionarTudoProduto"
                                    color="#fff"
                                    class="ma-0 pa-0"
                                    hide-details
                                ></v-checkbox >
                            </div>
                        </template>
                        <template v-slot:[`item.fn_produto`]="{ item }">
                            <v-simple-checkbox
                                :ripple="false" 
                                class="ml-2"
                                v-model="item.fn_produto"
                                @click="alteraInvestimento(produtoInvestimento.item)"
                            ></v-simple-checkbox>
                        </template>
                        <template v-for="m in 12" v-slot:[`item.faturado`]="{ item }">
                            {{(item.faturado*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                        </template>
                    </v-data-table>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            class="pr-4"
                            @click="produtoInvestimento.dialogo=false"
                        >
                            <v-icon left>mdi-close</v-icon> Fechar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- Importar Filiais -->
            <v-dialog
                v-model="importarFilial.dialogo"
                width="90%"
            >  
                <v-card>
                    <v-card-title class="subtitle-1 primary white--text">
                        Importar CSV
                    </v-card-title>
                    <v-card-text>
                        <v-row no-gutters class="mt-4">
                            <v-col cols="6">
                                <v-btn
                                    class="ml-2 my-1"
                                    color="primary"
                                    @click="exportarFiliais()"
                                >
                                    <v-icon left>mdi-microsoft-excel</v-icon> Modelo de Importação
                                </v-btn> 
                            </v-col> 
                        </v-row> 
                        <v-alert
                            class="mt-4"
                            text
                            type="info"
                            border="left"
                            style="font-size: 12px;"
                        >
                            <ul>
                                <li>
                                    Os nomes das colunas devem ser idênticos aos do modelo exportado (Modelo de Importação).
                                </li>
                                <li v-if="modelo.passoFilial.fnLoja">
                                    As colunas <b>ID Loja</b> ou <b>{{modelo.passoFilial.fnLoja.nome}}</b> podem ser utilizadas como <b>chave</b> para adicionar filiais (lojas) aos acordos.
                                </li>
                                <li v-else>
                                    A coluna <b>ID Loja</b> é a coluna <b>chave</b> para adicionar filiais (lojas) aos acordos.
                                </li>
                                <li v-if="modelo.passoFilial.fnLoja">
                                    A coluna <b>ID Loja</b> ou a coluna <b>{{modelo.passoFilial.fnLoja.nome}}</b> devem estar presentes no arquivo importado (e os nomes devem ser identicos aos da modelo exportado), as demais colunas podem ser excluidas.
                                </li>
                                <li v-else>
                                    A coluna <b>ID Loja</b> é obrigatória (e o nome deve ser identico ao da base exportado), as demais colunas podem ser excluidas.
                                </li>
                                <li v-if="modelo.passoFilial.fnLoja">
                                    Para adicionar uma loja deve-se manter a linha com a coluna <b>ID Loja</b> ou a coluna <b>{{modelo.passoFilial.fnLoja.nome}}</b> no arquivo importado.
                                </li>
                                <li v-else>
                                    Para adicionar uma loja deve-se manter a linha com a coluna <b>ID Loja</b> no arquivo importado.
                                </li>
                                <li v-if="modelo.passoFilial.fnLoja">
                                    Para remover uma loja deve-se apagar a linha com a coluna <b>ID Loja</b> ou a coluna <b>{{modelo.passoFilial.fnLoja.nome}}</b> no arquivo importado.
                                </li>
                                <li v-else>
                                    Para remover uma loja deve-se apagar a linha com a coluna <b>ID Loja</b> no arquivo importado.
                                </li>
                            </ul> 
                        </v-alert> 
                        <v-row no-gutters>  
                            <v-col cols="8" class="px-2">
                                <v-form ref="formImportarFilial" v-model="importarFilial.isCsv">
                                    <v-file-input 
                                        label="Arquivo CSV" 
                                        outlined 
                                        dense
                                        v-model="importarFilial.arquivoCSV"
                                        :rules="regra.csv"
                                        accept=".csv"
                                    ></v-file-input>
                                </v-form>
                            </v-col> 
                            <v-col cols="4" class="px-2">
                                <v-btn
                                    color="primary"
                                    :disabled="!importarFilial.isCsv"
                                    @click="importarFiliais()"
                                >
                                    <v-icon left>mdi-upload</v-icon> 
                                    Importar Arquivo
                                </v-btn>
                            </v-col> 
                        </v-row> 
                        <v-expand-transition>
                            <div v-show="importarFilial.erro">
                                <v-row no-gutters class="mb-0">  
                                    <v-col cols="6">
                                        <download-csv
                                            :data="importarFilial.erros"
                                            :labels="importarFilial.errosLabels"
                                            name= "erros.csv"
                                            delimiter=";"
                                        >
                                            <v-btn color="primary">
                                                <v-icon left>mdi-microsoft-excel</v-icon>
                                                Exportar Logs
                                            </v-btn>
                                        </download-csv>
                                    </v-col>
                                </v-row> 
                                <v-data-table
                                    :headers="importarFilial.headerErros"
                                    :items="importarFilial.erros"
                                    :items-per-page="5"
                                    :options.sync="importarFilial.pagination" 
                                    class="elevation-0 data-table-2"
                                ></v-data-table>
                            </div>
                        </v-expand-transition>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            class="pr-4"
                            @click="importarFilial.dialogo=false"
                        >
                            <v-icon left>mdi-close</v-icon> Fechar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- Filiaias Selecionadas -->
            <v-dialog
                v-model="importarFilial.dialogoSelecionadas"
                width="90%"
            >  
                <v-card>
                    <v-card-title class="subtitle-1 primary white--text">
                        Filiais selecionadas: {{lojasSelecionadas}} 
                    </v-card-title>
                    <v-row no-gutters class="mx-2">
                        <v-col cols="6">
                        </v-col>
                        <v-col cols="6" align="end"  class="py-2">
                            <download-csv
                                :data="lista.filiais.filter(e => e.fn_filial)"
                                :fields="headersFiliais.filter(e => e.value != 'fn_filial').map(e => e.value)"
                                :labels="headersFiliais.filter(e => e.value != 'fn_filial').reduce((a, v) => ({ ...a, [v.value]: v.text}), {})"
                                name= "filiais.csv"
                                delimiter=";"
                            >
                                <v-btn color="primary">
                                    <v-icon left>mdi-microsoft-excel</v-icon> Exportar CSV
                                </v-btn>
                            </download-csv>
                        </v-col>
                    </v-row>
                    <v-data-table
                        :headers="headersFiliaisResumo"
                        :items="lista.filiais.filter(e => e.fn_filial)"
                        :items-per-page="5"
                        :footer-props="{
                            'items-per-page-options': [5, 10, 15, 25, 50, 100]
                        }"
                        multi-sort
                        class="elevation-0"
                    >
                    </v-data-table>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            class="pr-4"
                            @click="importarFilial.dialogoSelecionadas=false"
                        >
                            <v-icon left>mdi-close</v-icon> Fechar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- Histórico de Investimento -->
            <v-dialog
                v-model="historicoInvestimento.dialogo"
                width="100%"
            >  
                <v-card>
                    <v-card-title class="subtitle-1 primary white--text">
                        Histórico de Investimento
                    </v-card-title>
                    <!-- Filtros -->
                    <v-row no-gutters class="mt-2">
                        <v-col v-for="(obj, index) in modelo.tabelas.filter(e => e.tipo == 'filtro')" :key="index" cols="4" class="px-2">
                            <v-autocomplete
                                v-if="obj.tipo == 'filtro'"
                                :label="obj.nome"
                                :items="lista[obj.tabela]"
                                v-model="historicoInvestimento.filtro[obj.var_id]"
                                multiple
                                clearable
                                outlined
                                dense 
                                hide-details="auto"
                            >
                                <template v-slot:prepend-item>
                                    <v-list-item
                                    ripple
                                    @mousedown.prevent
                                    @click="selecionarTudo(obj.var_id, obj.tabela, historicoInvestimento.filtro, lista)"
                                    >
                                    <v-list-item-action>
                                        <v-icon :color="historicoInvestimento.filtro[obj.var_id].length > 0 ? 'indigo darken-4' : ''">
                                        {{ iconeSelecionarTudo(obj.var_id, obj.tabela, historicoInvestimento.filtro, lista) }}
                                        </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                        Selecionar Tudo
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>
                                <template v-slot:selection="{item, index}">
                                    <div 
                                        v-if="historicoInvestimento.filtro['id_'+obj.tabela].length === 1 && index === 0"
                                        style="width: 70% !important; white-space: nowrap !important; text-overflow: ellipsis !important; overflow: hidden;"
                                    >{{ item.text }}</div>
                                    <span
                                        v-if="historicoInvestimento.filtro['id_'+obj.tabela].length > 1 && index === 0"
                                        class="grey--text caption mr-1"
                                    >{{ historicoInvestimento.filtro['id_'+obj.tabela].length }} selecionados</span>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" class="mt-2 px-2 ml-auto" align="end">
                            <v-btn
                                class="ml-2 mb-1"
                                color="primary"
                                @click="limparHistoricoInvestimento()"
                            >
                                <v-icon left>mdi-filter-off</v-icon> Limpar
                            </v-btn> 
                            <v-btn
                                class="ml-2 mb-1"
                                color="primary"
                                @click="buscaHistoricoInvestimento()"
                            >
                                <v-icon left>mdi-filter</v-icon> Filtrar
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mx-2 mb-2">
                        <v-col cols="12">
                            <!-- Indicadores -->
                            <div
                                v-for="indicador in historicoInvestimento.indicadoresTotal"
                                :key="indicador.nome" class="mt-2"
                            >
                                <v-tooltip top :color="indicador.cor" v-if="indicador.filtro">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-card
                                            :class="'elevation-1 mt-3 indicadoresCartao' + (indicador.ml ? indicador.ml : '') + (indicador.mr ? indicador.mr : '')"
                                            :color="indicador.cor"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="filtroIndicadore(indicador.filtro)"
                                        >
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <div 
                                                        class="indicadoresTitulo"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ><v-icon dark small>mdi-filter</v-icon> {{indicador.nome}}</div>
                                                </template>
                                                <span>{{indicador.desc}}</span>
                                            </v-tooltip>
                                            <div 
                                                class="indicadoresValor"
                                            >{{(indicador.valor*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</div>
                                            <v-avatar 
                                                class="indicadoresIcone"
                                                size="46"
                                                :color="indicador.cor2 ? indicador.cor2 : indicador.cor + ' darken-1'"
                                            >
                                                <v-icon dark>{{indicador.icone}}</v-icon>
                                            </v-avatar>
                                            <v-progress-linear
                                                v-show="indicador.percSufixo"
                                                :value="indicador.perc"
                                                color="#fff"
                                                height="12"
                                                class="indicadoresPercemtualBarra"
                                            ></v-progress-linear>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <div 
                                                        v-show="indicador.percSufixo"
                                                        class="indicadoresPercemtualValor"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >{{indicador.percNome}} ({{indicador.perc}}%)</div>
                                                </template>
                                                <span>{{indicador.percDesc}}</span>
                                            </v-tooltip>
                                        </v-card>
                                    </template>
                                    <span>{{indicador.filtroTitulo}}</span>
                                </v-tooltip>
                                <v-card
                                    :class="'elevation-1 mt-3 indicadoresCartao' + (indicador.ml ? indicador.ml : '') + (indicador.mr ? indicador.mr : '')"
                                    :color="indicador.cor"
                                    v-else
                                >
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <div 
                                                class="indicadoresTitulo"
                                                v-bind="attrs"
                                                v-on="on"
                                            >{{indicador.nome}}</div>
                                        </template>
                                        <span>{{indicador.desc}}</span>
                                    </v-tooltip>
                                    <div 
                                        class="indicadoresValor"
                                    >{{(indicador.valor*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</div>
                                    <v-avatar 
                                        class="indicadoresIcone"
                                        size="46"
                                        :color="indicador.cor2 ? indicador.cor2 : indicador.cor + ' darken-1'"
                                    >
                                        <v-icon dark>{{indicador.icone}}</v-icon>
                                    </v-avatar>
                                    <v-progress-linear
                                        v-show="indicador.percSufixo"
                                        :value="indicador.perc"
                                        color="#fff"
                                        height="12"
                                        class="indicadoresPercemtualBarra"
                                    ></v-progress-linear>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <div 
                                                v-show="indicador.percSufixo"
                                                class="indicadoresPercemtualValor"
                                                v-bind="attrs"
                                                v-on="on"
                                            >{{indicador.percNome}} ({{indicador.perc}}%)</div>
                                        </template>
                                        <span>{{indicador.percDesc}}</span>
                                    </v-tooltip>
                                </v-card>
                            </div>
                        </v-col>
                        <v-col cols="7">
                            <!-- Grafico Mensal-->
                            <v-card class="mt-4 mr-2 px-2 pt-2" elevation="1">
                                <v-select
                                    style="position: absolute; top: 0px; left: 10px; z-index: 2; width: 200px;" 
                                    :items="historicoInvestimento.periodos"
                                    v-model="historicoInvestimento.periodo"
                                    v-on:change="setaMeses()"
                                    label="Periodo"
                                    flat solo
                                    hide-details
                                >
                                    <template v-slot:selection="{item}" class="highcharts-title">
                                    <span class="highcharts-title" :style="`font-size: 18px; color: ${$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e'};`"> {{ item.text }}</span>
                                    </template>
                                </v-select>
                                <highcharts :options="historicoInvestimento.graficoMeses" ref="grafico1"></highcharts>
                            </v-card>
                        </v-col>
                        <!-- Gafico Top Atributos -->
                        <v-col cols="5">
                            <v-card class="mt-4 px-2 pt-2" elevation="1">
                                <v-select
                                    style="position: absolute; top: 0px; left: 10px; z-index: 2; width: 52%;" 
                                    :items="modelo.tabelas.filter(e => e.tipo == 'filtro').map(e => ({value: e.tabela, text: e.plural}))"
                                    v-model="historicoInvestimento.topEixo"
                                    v-on:change="setaTop(true)"
                                    label="Eixo"
                                    flat solo
                                    hide-details
                                >
                                    <template v-slot:selection="{item}" class="highcharts-title">
                                    <span class="highcharts-title" :style="`font-size: 18px; color: ${$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e'};`">Top {{ item.text }}</span>
                                    </template>
                                </v-select>
                                <highcharts :options="historicoInvestimento.graficoTop" ref="grafico3"></highcharts>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="error"
                            class="pr-4"
                            @click="historicoInvestimento.dialogo=false"
                        >
                            <v-icon left>mdi-close</v-icon> Fechar
                        </v-btn>
                    </v-card-actions>
                </v-card>

                <!-- Detalhado -->
                <v-dialog
                    v-model="historicoInvestimento.dialogoDetalhado"
                    width="95%"
                >  
                    <v-card>
                        <v-card-title class="subtitle-1 primary white--text">
                            Detalhado
                        </v-card-title>
                        <v-row no-gutters class="mx-2">
                            <v-col cols="6">
                            </v-col>
                            <v-col cols="6" align="end"  class="py-2">  
                                <v-btn
                                    class="ml-2 my-1"
                                    color="primary" 
                                    @click="historicoInvestimentoExportar({fnDetalhado: true})"  
                                >
                                    <v-icon left>mdi-microsoft-excel</v-icon> Exportar CSV
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-data-table
                            :headers="historicoInvestimento.headers"
                            :items="historicoInvestimento.dadosDetalhado"
                            :server-items-length="historicoInvestimento.totalDetalhado" 
                            :items-per-page="5"
                            :footer-props="{
                                'items-per-page-options': [5, 10, 15, 25, 50, 100]
                            }"
                            :options.sync="paginationDetalhado"
                            multi-sort
                            class="elevation-0"
                        >
                            <template v-slot:[`item.investimento`]="{ item }"> 
                                {{ (item.investimento*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
                            </template>
                            <template v-slot:[`item.mes`]="{ item }"> 
                                {{item.mes_nome}}
                            </template>
                        </v-data-table> 
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                @click="historicoInvestimento.dialogoDetalhado = false"
                            >
                                <v-icon left>mdi-close</v-icon> Fechar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </v-dialog>

            <!-- Resumo do acordo -->
            <v-dialog
                v-model="resumo.dialogo"
                width="90%"
            >  
                <v-card>
                    <v-card-title class="subtitle-1 primary white--text">
                        Resumo do Acordo
                    </v-card-title>
                    <v-row no-gutters class="ma-1">
                        <v-col cols="5" class="ma-1 body-2">
                            {{resumo.periodo}}
                        </v-col>
                        <v-col v-for="m in (modelo.passos[0] && modelo.passos[0].data ? getMeses : [])" :key="'mes_resumo_'+m" cols="5" class="ma-1 body-2">
                            {{modelo.passos[0].data_nome}} - {{ meses[ m - 1 ] }}: {{ acordo.modelo_data[m] ? $moment(acordo.modelo_data[m]).format('DD/MM/YYYY') : '' }}
                        </v-col>
                        <v-col cols="5" v-if="modelo.passos[0] && modelo.passos[0].obs" class="ma-1 body-2">
                            {{modelo.passos[0].obs_nome}}: {{acordo.obs[modelo.passos[0].id_passo]}}
                        </v-col>
                        <v-col cols="5" v-for="(campo, index) in resumo.campos" :key="index" class="ma-1 body-2">
                            {{campo}}
                        </v-col>
                        <v-col cols="5" v-if="modelo.tipo != 'Planejado'" class="ma-1 body-2">
                            Nota Fiscal: {{acordo.nota_fiscal.join(', ')}}
                        </v-col>
                        <v-col cols="5" v-if="modelo.tipo != 'Planejado'" class="ma-1 body-2">
                            TT Faturado: {{acordo.faturado.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                        </v-col>
                        <v-col cols="10" v-if="modelo.passos[1] && (modelo.passos[1].email || modelo.passos[1].email_exibir)" class="ma-1 body-2">
                            E-mail(s) do(s) aprovador(es) do recibo: {{acordo.email.join(', ')}}
                        </v-col>
                        <v-col cols="5" v-if="modelo.passos[1] && modelo.passos[1].obs" class="ma-1 body-2">
                            {{modelo.passos[1].obs_nome}}: {{acordo.obs[modelo.passos[1].id_passo]}}
                        </v-col>
                        <v-col cols="5" v-if="modelo.passos[2] && modelo.passos[2].obs" class="ma-1 body-2">
                            {{modelo.passos[2].obs_nome}}: {{acordo.obs[modelo.passos[2].id_passo]}}
                        </v-col>
                        <v-col cols="5" v-if="modelo.passos[3] && modelo.passos[3].obs" class="ma-1 body-2">
                            {{modelo.passos[3].obs_nome}}: {{acordo.obs[modelo.passos[3].id_passo]}}
                        </v-col>
                    </v-row>
                    <v-data-table
                        v-if="modelo.passoFilial && modelo.passoFilial.tipo"
                        :headers="headersFiliaisResumo"
                        :items="lista.filiais.filter(e => e.fn_filial)"
                        :items-per-page="5"
                        :footer-props="{
                            'items-per-page-options': [5, 10, 15, 25, 50, 100]
                        }"
                        multi-sort
                        class="elevation-0"
                    >
                    </v-data-table>
                    <v-data-table
                        :headers="headersInvestimentoResumo"
                        :items="investimentoResumo"
                        :items-per-page="5"
                        :footer-props="{
                            'items-per-page-options': [5, 10, 15, 25, 50, 100]
                        }"
                        multi-sort
                        class="elevation-0"
                    >
                        <template v-for="m in 12" v-slot:[`item.investimento_${m}`]="{ item }">
                            {{ item['fn_investimento_'+m] ? (item['investimento_'+m]*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : ''}}
                        </template>
                        <template v-for="m in 12" v-slot:[`item.investimento_valor_${m}`]="{ item }">
                            {{ item['fn_investimento_'+m] ? (item['investimento_valor_'+m]*1).toLocaleString('pt-br', {style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2})+'%' : ''}}
                        </template>
                    </v-data-table>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="error"
                            class="pr-4"
                            @click="resumo.dialogo=false"
                        >
                            <v-icon left>mdi-close</v-icon> Cancelar
                        </v-btn>
                        <v-btn
                            color="primary"
                            class="pr-4"
                            @click="salvar"
                        >
                            <v-icon left>mdi-content-save</v-icon> Confirmar e Salvar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- Erros do acordo -->
            <v-dialog
                v-model="dialogoErro"
                width="90%"
            >  
                <v-card>
                    <v-card-title class="subtitle-1 error white--text">
                        Erro(s) ao Salvar Acordo(s)
                    </v-card-title>
                    <v-card-text class="mt-4">
                        <v-data-table
                            :headers="listaHeaderErro"
                            :items="listaErro"
                            :items-per-page="5"
                            :options.sync="paginationErro" 
                            class="elevation-1 data-table-2"
                        ></v-data-table>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <download-csv
                            :data="listaErro"
                            :labels="listaErroLabels"
                            name= "erros.csv"
                            delimiter=";"
                        >
                            <v-btn color="primary">
                                <v-icon left>mdi-microsoft-excel</v-icon>
                                Exportar
                            </v-btn>
                        </download-csv>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            @click="dialogoErro = false"
                        >
                            <v-icon left>mdi-close</v-icon>
                            Fechar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-dialog>

        <!-- Dialogo de Confirm -->
        <v-dialog
            v-model="confirm.dialogo"
            width="600"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{confirm.titulo}}
                </v-card-title>
                <v-card-text class="mt-4"> 
                    {{confirm.texto}}
                    <br>
                    <br>
                    <v-form ref="formConfirm">
                        <v-select
                            v-if="processo.justificativas"
                            label="Justificativa"
                            v-model="processo.justificativa"
                            :items="processo.justificativas"
                            :rules="regra.justificativa"
                            outlined
                            dense 
                        ></v-select>
                        <v-textarea
                            v-if="processo.justificativas"
                            label="Observação"
                            v-model="processo.observacao"
                            rows="2"
                            outlined
                            dense 
                            hide-details
                        ></v-textarea>
                        <b v-if="processo.upload">Selecione uma imagem ou PDF do arquivo.</b>
                        <br v-if="processo.upload">
                        <br v-if="processo.upload">
                        <v-file-input 
                            v-if="processo.upload"
                            :label="processo.upload_nome" 
                            v-model="processo.arquivo"
                            :rules="regra.upload"
                            outlined 
                            dense
                        ></v-file-input>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="confirm.dialogo=false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        :color="confirm.color"
                        class="pr-4"
                        @click="confirm.funcao"
                    >
                        <v-icon left>{{confirm.icon}}</v-icon> {{confirm.botao}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Comprovar acordo -->
        <v-dialog
            v-model="comprova.dialogo"
            width="90%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{comprova.titulo}}
                </v-card-title>
                <v-card-text class="mt-4"> 
                    <v-form ref="formComprova">
                        <v-row no-gutters>
                            <v-col cols="12" class="mb-2">
                                <b>O acordo foi executado?</b>
                            </v-col>
                            <v-col cols="12"> 
                                <v-select 
                                    label="Executado" 
                                    v-model="processo.executado"
                                    :items="[{value: 1, text: 'Sim'}, {value: 0, text: 'Não'}]"  
                                    v-on:change="carregando=true; carregando=false;"
                                    outlined 
                                    dense
                                ></v-select>
                            </v-col>
                            <v-col cols="12" class="mb-2" v-if="processo.executado==1">
                                <b>Selecione os arquivos das evidências.</b>
                            </v-col>
                            <v-col cols="12" v-if="processo.executado==1">
                                <v-file-input 
                                    label="Evidências" 
                                    v-model="processo.evidencia"
                                    :rules="regra.evidencia"
                                    outlined 
                                    dense
                                    small-chips
                                    counter
                                    multiple
                                    show-size
                                    clearable
                                    @change="changeFile"
                                    @click:clear="clearFile"
                                >
                                <template v-slot:selection="{ text, index }">
                                    <v-chip small close @click:close="removeFile(index)">
                                        {{ text }}
                                    </v-chip>
                                </template>
                                </v-file-input>
                            </v-col>
                            <v-col cols="12" class="mb-2" v-if="processo.executado==0">
                                <b>No caso da não execução informe uma justificativa.</b>
                            </v-col>
                            <v-col cols="12" v-if="processo.executado==0"> 
                                <v-select
                                    label="Justificativa"
                                    v-model="processo.justificativa"
                                    :items="processo.justificativas"
                                    :rules="regra.justificativa"
                                    outlined
                                    dense 
                                ></v-select>
                            </v-col>
                            <v-col cols="12"> 
                                <v-textarea
                                    label="Observação"
                                    v-model="processo.observacao"
                                    rows="2"
                                    outlined
                                    dense 
                                ></v-textarea> 
                            </v-col>
                        </v-row>
                        <v-data-table
                            :headers="comprova.headers"
                            :items="comprova.investimento"
                            :items-per-page="5"
                            :options.sync="comprova.pagination" 
                            class="elevation-1 data-table-2"
                        >
                            <template v-slot:[`item.investimento`]="{ item }">
                                <vuetify-money
                                    v-if="processo.editar_investimento"
                                    :ripple="false" 
                                    v-model="item.investimento"
                                    dense 
                                    hide-details
                                    backgroundColor=""
                                    class="input_tabela"
                                />
                                <div v-else>{{(item.investimento*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</div>
                            </template>
                        </v-data-table>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="comprova.dialogo=false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="comprovaAcordo"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Editar investimento -->
        <v-dialog
            v-model="edita.dialogo"
            width="90%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{edita.titulo}}
                </v-card-title>
                <v-card-text class="mt-4">
                    <v-data-table
                        :headers="edita.headers"
                        :items="edita.investimento"
                        :items-per-page="5"
                        :options.sync="edita.pagination" 
                        class="elevation-1 data-table-2"
                    >
                        <template v-slot:[`item.investimento`]="{ item }">
                            <vuetify-money 
                                :ripple="false" 
                                v-model="item.investimento"
                                dense 
                                hide-details
                                backgroundColor=""
                                class="input_tabela"
                            />
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="edita.dialogo=false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        :color="processo.color"
                        class="pr-4"
                        @click="editaAcordo"
                    >
                        <v-icon left>mdi-content-save</v-icon> {{processo.nome}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Lista de Evidencias -->
        <v-dialog
            v-model="evidencias.dialogo"
            width="70%"
            scrollable
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{evidencias.titulo}}
                </v-card-title>
                <v-card-text class="mt-4">
                    <v-list>
                        <v-list-item-group>
                            <v-list-item v-for="(item, i) in evidencias.lista" :key="i" class="pa-0" @click="mostraEvidenciaItem(item)">
                                <v-list-item-action class="ma-2">
                                    <v-card class="text-subtitle-2 d-flex align-center justify-center ma-0" width="80" height="40" rounded="1" elevation="0" color="secondary lighten-5">
                                        {{item.extensao}}
                                    </v-card>
                                </v-list-item-action>
                                <v-list-item-content class="text-caption ml-2">
                                    <v-list-item-title v-text="item.nome" :title="item.nome"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn
                        v-show="evidencias.historico"
                        color="primary"
                        @click="buscaArquivoHistorico"
                    >
                        histórico
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="evidencias.dialogo = false"
                    >
                        ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Exibir evidencia -->
        <v-dialog
            v-model="evidencia.dialogo"
            width="70%"
            scrollable
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{evidencia.titulo}}
                </v-card-title>
                <v-card-text class="mt-4" v-html="evidencia.html">
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="evidencia.dialogo = false"
                    >
                        ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Historico do acordo -->
        <v-dialog
            v-model="historico.dialogo"
            width="80%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{historico.titulo}}
                </v-card-title>
                <v-data-table
                    :headers="historico.headers"
                    :items="historico.dados"
                    item-key="id_historico"
                    :items-per-page="5"
                    class="elevation-1 data-table-2 quebra-linha"
                ></v-data-table>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="historico.dialogo = false"
                    >
                        ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> 

        <!-- Historico dos arquivos do acordo -->
        <v-dialog
            v-model="arquivoHistorico.dialogo"
            width="80%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{arquivoHistorico.titulo}}
                </v-card-title>
                <v-data-table
                    :headers="arquivoHistorico.headers"
                    :items="arquivoHistorico.dados"
                    item-key="id_historico"
                    :items-per-page="5"
                    class="elevation-1 data-table-2 quebra-linha"
                >
                    <template v-slot:[`item.modelo_arquivo`]="{ item }"> 
                        <div
                            v-if="item.modelo_arquivo"
                            @click="mostraArquivo(item, false)"
                            title="Arquivos"
                            style="cursor: pointer;"
                        >
                            <v-badge
                                bordered 
                                overlap
                                color="primary"
                                :content="item.modelo_arquivo.length"
                            >
                                <v-icon
                                    small
                                    class="mr-2"
                                    color="primary"
                                >
                                    mdi-tooltip-image
                                </v-icon>
                            </v-badge>
                        </div>
                    </template>
                </v-data-table>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="arquivoHistorico.dialogo = false"
                    >
                        ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> 

        <!-- Investimento / Faturado do acordo -->
        <v-dialog
            v-model="investimentoFaturamento.dialogo"
            width="80%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{investimentoFaturamento.titulo}}
                </v-card-title>
                <v-data-table
                    :headers="investimentoFaturamento.headers"
                    :items="investimentoFaturamento.dados"
                    item-key="id_loja"
                    :items-per-page="5"
                    class="elevation-1 data-table-2"
                >
                    <template v-slot:[`item.numero_produtos`]="{ item }"> 
                        <v-btn
                            text
                            color="primary"
                            @click="investimentoFaturamentoProdutos(item)"
                        >
                            {{item.numero_produtos}}
                        </v-btn>
                    </template>
                </v-data-table>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="investimentoFaturamento.dialogo = false"
                    >
                        ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> 

        <!-- Investimento / Faturado / Produtos do acordo -->
        <v-dialog
            v-model="investimentoFaturamento.produtos.dialogo"
            width="80%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{investimentoFaturamento.produtos.titulo}}
                </v-card-title>
                <v-data-table
                    :headers="investimentoFaturamento.produtos.headers"
                    :items="investimentoFaturamento.produtos.dados"
                    item-key="id_loja"
                    :items-per-page="5"
                    class="elevation-1 data-table-2"
                ></v-data-table>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="investimentoFaturamento.produtos.dialogo = false"
                    >
                        ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> 

        <!-- Filiais do acordo -->
        <v-dialog
            v-model="filiaisAcordo.dialogo"
            width="80%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{filiaisAcordo.titulo}}
                </v-card-title>
                <v-data-table
                    :headers="filiaisAcordo.headers"
                    :items="filiaisAcordo.dados"
                    item-key="id_loja"
                    :items-per-page="5"
                    class="elevation-1 data-table-2"
                ></v-data-table>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="filiaisAcordo.dialogo = false"
                    >
                        ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> 

        <!-- Auditorias do acordo -->
        <v-dialog
            v-model="auditorias.dialogo"
            width="80%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{auditorias.titulo}}
                </v-card-title>
                <v-data-table
                    :headers="auditorias.headers"
                    :items="auditorias.dados"
                    item-key="id_auditoria"
                    :items-per-page="5"
                    class="elevation-1 data-table-2"
                >
                    <template v-slot:[`item.historico`]="{ item }"> 
                        <v-icon
                            small
                            class="mr-2"
                            color="primary"
                            @click="buscaAuditoriasHistorico(item)"
                            title="Histórico"
                        >
                            mdi-file-document-multiple
                        </v-icon>
                    </template>
                    <template v-slot:[`item.evidencia`]="{ item }"> 
                        <div
                            v-if="item.evidencia"
                            @click="mostraEvidencia(item)"
                            title="Evidências"
                            style="cursor: pointer;"
                        >
                            <v-badge
                                bordered
                                overlap
                                color="primary"
                                :content="item.evidencia.length"
                            >
                                <v-icon
                                    small
                                    class="mr-2"
                                    color="primary"
                                >
                                    mdi-tooltip-image
                                </v-icon>
                            </v-badge>
                        </div>
                    </template>
                </v-data-table>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="auditorias.dialogo = false"
                    >
                        ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> 

        <!-- Histórico de auditorias -->
        <v-dialog
            v-model="auditoriasHistorico.dialogo"
            width="80%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{auditoriasHistorico.titulo}}
                </v-card-title>
                <v-data-table
                    :headers="auditoriasHistorico.headers"
                    :items="auditoriasHistorico.dados"
                    item-key="id_historico"
                    :items-per-page="5"
                    class="elevation-1 data-table-2"
                >
                    <template v-slot:[`item.evidencia`]="{ item }"> 
                        <div
                            v-if="item.evidencia"
                            @click="mostraEvidencia(item)"
                            title="Evidências"
                            style="cursor: pointer;"
                        >
                            <v-badge
                                bordered
                                overlap
                                color="primary"
                                :content="item.evidencia.length"
                            >
                                <v-icon
                                    small
                                    class="mr-2"
                                    color="primary"
                                >
                                    mdi-tooltip-image
                                </v-icon>
                            </v-badge>
                        </div>
                    </template>
                </v-data-table>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="auditoriasHistorico.dialogo = false"
                    >
                        ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> 

        <!-- Filtro de status -->
        <v-dialog
            v-model="status.dialogo"
            width="80%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{status.titulo}}
                </v-card-title>
                <v-data-table
                    :headers="status.headers"
                    :items="status.dados"
                    item-key="id_status"
                    class="data-table-2 mx-2"
                >
                    <template v-slot:[`item.total_p`]="{ item }">
                        <v-progress-linear
                            :value="item.total_p"
                            color="primary"
                            height="30"
                        ><span style="color: #fff">{{item.total_p}}%</span></v-progress-linear>
                    </template>
                    <template v-slot:[`item.investimento`]="{ item }">
                        {{item.investimento.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                    </template>
                    <template v-slot:[`item.investimento_p`]="{ item }">
                        <v-progress-linear
                            :value="item.investimento_p"
                            color="primary"
                            height="30"
                        ><span style="color: #fff">{{item.investimento_p}}%</span></v-progress-linear>
                    </template>
                    <template v-slot:[`item.botao`]="{ item }">
                        <v-icon
                            small
                            class="mx-2"
                            color="primary"   
                            @click="filtrarStatus(item.id_status)"
                            title="Filtrar"
                        >
                            mdi-filter
                        </v-icon>
                    </template>
                    <template v-slot:[`header.botao`]="{ }">
                        <v-icon
                            small
                            class="mx-2"
                            color="primary"   
                            @click="filtrarStatus(null)"
                            title="Limpar Filtro de Status"
                        >
                            mdi-filter-off
                        </v-icon>
                    </template>
                </v-data-table>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="status.dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> 

        
        <v-dialog
            v-model="importarLote.dialogo"
            width="90%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Importar Lote
                </v-card-title>
                <v-card-text>
                    <v-row no-gutters class="mt-4">  
                        <v-col cols="6">
                            <v-btn
                                class="ml-2 my-1"
                                color="primary"
                                @click="exportar('importarLote')"
                            >
                                <v-icon left>mdi-microsoft-excel</v-icon> Exportar Modelo
                            </v-btn>
                        </v-col> 
                    </v-row> 
                    <v-alert
                        class="mt-4"
                        text
                        type="info"
                        border="left"
                        style="font-size: 12px;"
                    >
                        <ul>
                            <li>
                                Os nomes das colunas devem ser <b>idênticos</b> aos da base exportada.
                            </li>
                            <li>
                                Coluna(s) obrigatória(s): 
                                <b>{{importarLote.obj.campos.map(e => e.campo).includes('mes') ? 'Mês, Ano, ' : ''}}</b>
                                <b>{{importarLote.obj.campos.filter(e => e.campo != 'mes').map(e => e.nome).join(', ')}}</b>
                                <b>{{!importarLote.obj.justificativas ? '' : ', Justificativa'}}</b>
                                {{ !importarLote.obj.justificativas ? '. As demais colunas serão ignoradas.' : '. As demais colunas serão ignoradas com exceção da coluna Observação da justificativa.' }}
                            </li>
                            <li>
                                <b>Importante: Todos os acordos importados terão os status alterados, não sendo possível o retroceder para o status atual.</b>
                            </li>
                        </ul> 
                    </v-alert> 
                    <v-row no-gutters>  
                        <v-col cols="8" class="px-2">
                            <v-form ref="formImportarLote" v-model="importarLote.isCsv">
                                <v-file-input 
                                    label="Arquivo CSV" 
                                    outlined 
                                    dense
                                    v-model="importarLote.arquivoCSV"
                                    :rules="regra.csv"
                                    accept=".csv"
                                ></v-file-input>
                            </v-form>
                        </v-col> 
                        <v-col cols="4" class="px-2">
                            <v-btn
                                color="primary"
                                :disabled="!importarLote.isCsv"
                                @click="enviarImportarLote()"
                            >
                                <v-icon left>mdi-upload</v-icon> 
                                Importar Arquivo
                            </v-btn>
                        </v-col> 
                    </v-row> 
                    <v-expand-transition>
                        <div v-show="importarLote.erro">
                            <v-row no-gutters class="mb-0">
                                <v-col cols="6">
                                    <download-csv
                                        :data="importarLote.erros"
                                        :labels="importarLote.errosLabels"
                                        name= "erros.csv"
                                        delimiter=";"
                                    >
                                        <v-btn color="primary">
                                            <v-icon left>mdi-microsoft-excel</v-icon>
                                            Exportar Logs
                                        </v-btn>
                                    </download-csv>
                                </v-col>
                            </v-row> 
                            <v-data-table
                                :headers="importarLote.headerErros"
                                :items="importarLote.erros"
                                :items-per-page="5"
                                :options.sync="importarLote.paginationErros" 
                                class="elevation-0 data-table-2"
                            ></v-data-table>
                        </div>
                    </v-expand-transition>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="importarLote.dialogo=false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn
                dark
                text
                class="float-right"
                @click="alerta=false"
            >
            Fechar
            </v-btn>
        </v-snackbar>

    </v-container>
</template>

<script>

export default {
    data() {
        return {
            meses: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            modelos: [],
            modelosLista: [],
            camposListagem: [],
            modelo: {
                passos: [],
                tabelas: [],
                mes_limite: 0,
                passoFilial: {
                    fnLoja: false,
                }
            },
            mostrar: {
                filtros: {flag: true, icone: 'mdi-chevron-up', nome: 'Filtros'},
                indicadores: {flag: true, icone: 'mdi-chevron-up', nome: 'Indicadores'},
                lista: {flag: true, icone: 'mdi-chevron-up', nome: 'Listagem'},
            },
            headers: [
                { text: 'Menu', value: 'menu', sortable: false, align: 'center'},
                { text: 'Tipo de Acordo', value: 'modelo', align: 'center'},
                { text: 'Contrato', value: 'id_contrato', align: 'center'},
                { text: 'Acordo', value: 'id_acordo', align: 'center'},
                { text: 'Mês', value: 'mes_nome', align: 'center'},
                { text: 'Parcela', value: 'parcela', align: 'center'},
                { text: 'Status', value: 'status', align: 'center'},
                { text: 'Investimento', value: 'investimento_moeda', align: 'center'},
            ],
            dados: [],
            main: {
                lista: {},
                filtro: {},
                regra: {
                    mesInicial: [
                        v => !!v || 'Campo obrigatório.',
                    ],
                    mesFinal: [
                        v => !!v || 'Campo obrigatório.',
                        v => (!!v && v >= this.main.filtro.mesInicial) || 'O período final deve ser igual ou posterior ao período inicial',
                    ],
                },
            },
            nota: {
                dialogo: false,
                produto: {
                    dialogo: false,
                    headers: [
                        { text: 'Código', value: 'codigo', sortable: false, align: 'center'},
                        { text: 'Produto', value: 'produto', sortable: false, align: 'center'},
                        { text: 'Valor', value: 'faturado', sortable: false, align: 'center'},
                    ],
                },
                notas: [],
                fn_nota: false,
            },
            produtoInvestimento: {
                dialogo: false,
                headers: [
                    { text: '', value: 'fn_produto', sortable: false, align: 'center'},
                    { text: 'Código', value: 'codigo', sortable: false, align: 'center'},
                    { text: 'Produto', value: 'produto', sortable: false, align: 'center'},
                    { text: 'Valor', value: 'faturado', sortable: false, align: 'center'},
                ],
                fn_produto: null,
                produtos: [],
                item: {},
            },
            fn_investimento: [],
            investimento: [],
            investimentoResumo: [],
            historicoInvestimento: {
                dialogo: false,
                filtro: {},
                filtroDetalhado: {},
                investimento: {},
                top: {},
                indicador: 'investimento',
                indicadores: [
                    {value: 'investimento', text: 'Investimento', cor: '#009688', tipo: 'moeda', indicador: 'investimento', fnDetalhado: true},
                ],
                indicadoresTotal: [
                    {
                        nome: 'INVESTIMENTO', 
                        valor: 0, 
                        sufixo: '', 
                        desc: 'Total da verba investida nos acordos comerciais.', 
                        icone: 'mdi-currency-usd', 
                        cor: 'teal',
                        mr: ' mr-4',
                    },
                ],
                periodos: [
                    {value: 1, text: 'Mensal'}, 
                    {value: 2, text: 'Bimestral'}, 
                    {value: 3, text: 'Trimestral'}, 
                    {value: 6, text: 'Semestral'}, 
                    {value: 12, text: 'Anual'},
                ],
                periodo: 1,
                graficoMeses: {
                    credits: {
                        enabled: false
                    },
                    tooltip: {
                        shared: true,
                        formatter: function () {
                            return this.points.reduce(function (s, point) {
                                return s + '<br/>' + point.series.name + ': ' + (point.series.type == 'line' ? point.y+'%' : point.y.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) )
                            }, '<b>' + this.x + '</b>')
                        },
                    },
                    title: {
                        text: "Investimento Mensal",
                        style: {
                            color: this.$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e',
                        }
                    },
                    chart: {
                        type: 'column',
                        height: 500,
                        backgroundColor: this.$vuetify.theme.dark ? '#1E1E1E' : '#fff',
                    },
                    yAxis: [
                        {
                            visible: false
                        },
                        {
                            visible: false
                        },
                    ],
                    plotOptions: {
                        column: {
                            dataLabels: {
                                enabled: true,
                                formatter: function () { return this.point.y.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) },
                                color: this.$vuetify.theme.dark ? '#fff' : null,
                            },
                        },
                        line: {
                            dataLabels: {
                                enabled: true,
                                formatter: function () { return this.point.y + '%' },
                                color: this.$vuetify.theme.dark ? '#fff' : null,
                            },
                        },
                    },
                    legend: {
                        itemStyle: {
                            color: this.$vuetify.theme.dark ? '#fff' : null,
                        },
                        itemHoverStyle: {
                            color: this.$vuetify.theme.dark ? '#9e9e9e' : null,
                        },
                    },
                    xAxis: {
                        categories: [],
                        crosshair: true,
                        labels: {
                            style: {
                                color: this.$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e',
                            }
                        },
                    },
                    series: [
                        {
                            type: 'column',
                            name: 'Investimento',
                            yAxis: 0,
                            data: [],
                            color: '#009688',
                            borderWidth: 0,
                            cursor: 'pointer',
                            events: {
                                click: ({point}) => {
                                    this.historicoInvestimento.filtroDetalhado = {
                                        indicador: 'investimento',
                                        mes: point.category
                                    }
                                    this.historicoInvestimentoDetalhado()
                                }
                            },
                        },
                    ]
                },
                topEixo: 'marca',
                graficoTop: {
                    credits: {
                        enabled: false
                    },
                    tooltip: {
                        enabled: false
                    },
                    title: {
                        text: ".",
                        style: {
                            color: this.$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e',
                        }
                    },
                    chart: {
                        type: 'bar',
                        height: 500,
                        backgroundColor: this.$vuetify.theme.dark ? '#1E1E1E' : '#fff',
                    },
                    yAxis: {
                        visible: false
                    },
                    legend: {
                        enabled: false
                    },
                    plotOptions: {
                        bar: {
                            dataLabels: {
                                enabled: true,
                                formatter: function () { return this.point.y.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) },
                                color: this.$vuetify.theme.dark ? '#fff' : null,
                            },
                        },
                    },
                    xAxis: {
                        categories: [],
                        labels: {
                            style: {
                                color: this.$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e',
                            }
                        },
                    },
                    series: [{
                        data: [],
                        color: this.$vuetify.theme.themes.light.success,
                        borderWidth: 0,
                        cursor: 'pointer',
                        events: {
                            click: ({point}) => {
                                this.historicoInvestimento.filtroDetalhado = {
                                    indicador: 'investimento',
                                    nome: point.category
                                }
                                this.historicoInvestimentoDetalhado()
                            }
                        }
                    }]
                }, 
                dialogoDetalhado: false,
                dadosDetalhado: [],
                totalDetalhado: 0,
                headers: [
                    { text: 'Tipo de Acordo', value: 'modelo', align: 'center'},
                    { text: 'Contrato', value: 'id_contrato', align: 'center'},
                    { text: 'Acordo', value: 'id_acordo', align: 'center'},
                    { text: 'Mês', value: 'mes', align: 'center'},
                ]
            },
            fn_filial: false,
            filiais: [],
            total: null,
            paginationLimpa: {
                groupBy: [],
                groupDesc: [],
                itemsPerPage: 5,
                multiSort: true,
                mustSort: false,
                page: 1,
                sortBy: [],
                sortDesc: [],
            },
            pagination: {},
            paginationFiliais: {},
            paginationInvestimento: {},
            paginationInvestimentoTotal: {},
            paginationDetalhado: {},
            indicador: 'Total',
            indicadores: [{text: 'Total', value: 'Total'}],
            carregando: false,
            formulario: {
                dialogo: false,
                passo: 1,
            },
            acordo: {
                mesInicial: null,
                mesFinal: null,
                faturado: 0,
                nota_fiscal: [],
            },
            lista: {
                notas: [],
                filiais: [],
                investimento: [],
            },
            filtro: {},
            filtroFilial: {},
            importarFilial: {
                dialogo: false,
                arquivoCSV: null,
                isCsv: false,
                headerErros: [
                    { text: 'Descrição', value: 'msg', align: 'left'},
                ],
                erros: [],
                pagination: {}, 
                errosLabels: {msg: 'Descrição'}, 
                erro: false,
                dialogoSelecionadas: false,
            },
            dialogoModelo: false,
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            paginationErro: {},
            dialogoErro: false,
            listaHeaderErro: [
                { text: 'Descrição do Erro', value: 'msg', align: 'left'},
            ],
            listaErro: [],
            listaErroLabels: {msg: 'Descrição do Erro'},
            regra: { 
                mesInicial: [
                    v => !!v || 'Campo obrigatório.',
                    v => ((!!v && v >= this.mesLimite) || this.acordo.fnLeitura) || 'O período inicial deve ser igual ou posterior a '+this.mesLimiteNome,
                ],
                mesFinal: [
                    v => !!v || 'Campo obrigatório.',
                    v => (!!v && v >= this.acordo.mesInicial) || 'O período final deve ser igual ou posterior ao período inicial',
                    v => (!!v && v.substr(0, 4) == this.acordo.mesInicial.substr(0, 4)) || 'O período final deve pertencer ao mesmo ano do período inicial',
                ],
                obrigatorio: [v => !!v || 'Campo obrigatório.'],
                nota_fiscal: [v => v.length > 0 || 'Campo obrigatório.'],
                justificativa: [v => !!v || 'Campo obrigatório.'],
                executado: [v => !!v || 'Campo obrigatório.'],
                evidencia: [
                    v => v.length > 0 || 'Selecione pelo menos um arquivo.',
                    v => v.length > 0 && v.reduce((a, v) => a + v.size, 0) < 25*1000*1000 || 'O tamanho total dos arquivos não pode supeara 25 MB.',
                ],
                arquivo_obrigatorio: [
                    v => v.length > 0 || 'Selecione pelo menos um arquivo.',
                    v => v.length > 0 && v.reduce((a, v) => a + v.size, 0) < 25*1000*1000 || 'O tamanho total dos arquivos não pode supeara 25 MB.',
                ],
                arquivo: [
                    v => v.reduce((a, v) => a + v.size, 0) < 25*1000*1000 || 'O tamanho total dos arquivos não pode supeara 25 MB.',
                ],
                upload: [v => !this.processo.upload_obrigatorio || !!v || 'Campo obrigatório.'],
                csv: [
                    v => !!v || 'Selecione um arquivo.',
                    v => (!!v && [
                        '.csv', 
                        '.CSV'
                    ].includes(v.name.substr(-4))) || 'O arquivo deve ser do tipo CSV.',
                ],
                email: [ v => !v || /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail invalido' ],
            },
            historico: {
                dialogo: false,
                titulo: null,
                headers: [
                    { text: 'Processo', value: 'processo', sortable: false, align: 'center'},
                    { text: 'Detalhes', value: 'detalhe', sortable: false, align: 'center'},
                    { text: 'Data', value: 'data', sortable: false, align: 'center'},
                    { text: 'Responsável', value: 'usuario', sortable: false, align: 'center'},
                ],
                dados: [],
            },
            filiaisAcordo: {
                dialogo: false,
                titulo: null,
                headers: [],
                dados: [],
            },
            investimentoFaturamento: {
                dialogo: false,
                titulo: null,
                headers: [],
                dados: [],
                produtos: {
                    dialogo: false,
                    titulo: null,
                    headers: [],
                    dados: [],
                }
            },
            auditorias: {
                dialogo: false,
                titulo: null,
                headers: [
                    { text: 'Histórico', value: 'historico', sortable: false, align: 'center'},
                    { text: 'Ação', value: 'acao', sortable: false, align: 'center'},
                    { text: 'CNPJ Filial', value: 'cnpj', sortable: false, align: 'center'},
                    { text: 'Razão Social', value: 'razao_social', sortable: false, align: 'center'},
                    { text: 'Data', value: 'data', sortable: false, align: 'center'},
                    { text: 'Responsável', value: 'usuario', sortable: false, align: 'center'},
                    { text: 'Auditoria', value: 'executado_nome', sortable: false, align: 'center'},
                    { text: 'Evidências', value: 'evidencia', sortable: false, align: 'center'},
                    { text: 'Justificativa', value: 'justificativa', sortable: false, align: 'center'},
                    { text: 'Observação', value: 'observacao', sortable: false, align: 'center'},
                ],
                dados: [],
            },  
            auditoriasHistorico: {
                dialogo: false,
                titulo: null,
                headers: [
                    { text: 'Ação', value: 'acao', sortable: false, align: 'center'},
                    { text: 'CNPJ Filial', value: 'cnpj', sortable: false, align: 'center'},
                    { text: 'Razão Social', value: 'razao_social', sortable: false, align: 'center'},
                    { text: 'Data', value: 'data', sortable: false, align: 'center'},
                    { text: 'Responsável', value: 'usuario', sortable: false, align: 'center'},
                    { text: 'Auditoria', value: 'executado_nome', sortable: false, align: 'center'},
                    { text: 'Evidências', value: 'evidencia', sortable: false, align: 'center'},
                    { text: 'Justificativa', value: 'justificativa', sortable: false, align: 'center'},
                    { text: 'Observação', value: 'observacao', sortable: false, align: 'center'},
                ],
                dados: [],
            },  
            processo: {},
            confirm: {
                dialogo: false,
                titulo: null,
                texto: null,
                funcao: null,
                color: null,
                botao: null,
                icon: null,
            },  
            comprova: {
                dialogo: false,
                titulo: null,
                headers: [
                    { text: 'Ação', value: 'acao', sortable: false, align: 'center'},
                    { text: 'Investimento', value: 'investimento', sortable: false, align: 'center', width: "160px", fixed: true},
                ],
                investimento: [],
                pagination: {}, 
            },  
            edita: {
                dialogo: false,
                titulo: null,
                headers: [],
                investimento: [],
                pagination: {}, 
            },
            evidencias: {
                dialogo: false,
                titulo: null,
                lista: [],
                historico: false,
                id_acordo: null,
            },
            evidencia: {
                dialogo: false,
                titulo: null,
                html: null,
            },
            arquivoHistorico: {
                dialogo: false,
                titulo: null,
                headers: [
                    { text: 'Arquivos', value: 'modelo_arquivo', sortable: false, align: 'center'},
                    { text: 'Data', value: 'data', sortable: false, align: 'center'},
                    { text: 'Responsável', value: 'usuario', sortable: false, align: 'center'},
                ],
                dados: [],
            },
            status: {
                dialogo: false,
                titulo: 'Quantidade / Investimento por Status',
                headers: [
                    { text: 'Status', value: 'status', align: 'left'},
                    { text: 'Quantidade', value: 'total', align: 'center'},
                    { text: '% Quant.', value: 'total_p', align: 'center'},
                    { text: 'Investimento', value: 'investimento', align: 'center'},
                    { text: '% Invest.', value: 'investimento_p', align: 'center'},
                    { text: '', value: 'botao', sortable: false, align: 'center'},
                ],
                dados: []
            },
            indicadoresTotal: [
                {
                    nome: 'ACORDOS', 
                    quant: 0, 
                    quantSufixo: 'total', 
                    valor: 0, 
                    valorSufixo: 'investimento', 
                    desc: 'Quantidade de acordos / Valor total de Investimento dos acordos.',
                    icone: 'mdi-file-document-edit',
                    cor: 'teal', 
                    acao: () => { this.status.dialogo = true },
                    acaoTitulo: 'Exibir acordos por status',
                },
            ],
            resumo: {
                dialogo: false,
                periodo: null,
                campos: [],
            },
            importarLote: {
                dialogo: false,
                obj: {
                    campos: [],
                },
                lista: [],
                isCsv: false,
                headerErros: [
                    { text: 'Descrição', value: 'msg', align: 'left'},
                ],
                erros: [],
                errosLabels: {msg: 'Descrição'}, 
                erro: false,
                paginationErro: {},
            },
        }
    },
    watch: {
        pagination: {
            handler () {
                if(this.modelosLista.length > 0) this.filtrar()
            },
            deep: true,
        },
        paginationDetalhado: {
            handler () {
                if(this.modelosLista.length > 0) this.historicoInvestimentoDetalhado(false)
            },
            deep: true,
        },
    },
    async created() {

        this.carregando = true

        this.main.filtro.mesInicial = this.mesAtual
        this.main.filtro.mesFinal = this.mesAtual
        
        var data = {
            id_cargo: this.$store.getters.getCargo.id_cargo
        }

        var retorno = await this.$http().post(`/acordo/listagem`, {data: data})

        this.modelos = retorno.data.modelos

        this.modelosLista = retorno.data.modelosLista

        this.camposListagem = retorno.data.camposListagem

        this.importarLote.lista = retorno.data.importarLote

        var marcaNome = null
        var produtoNome = null
        var fnFaturamento = false

        for (const m of this.modelosLista) {

            let tabelaMarca = m.tabelas.find(e => e.tabela == 'marca')
            if (tabelaMarca) marcaNome = tabelaMarca.nome

            let tabelaProduto = m.tabelas.find(e => e.tabela == 'produto')
            if (tabelaProduto) produtoNome = tabelaProduto.nome

            if (m.tipo != 'Planejado') fnFaturamento = true
        }
        
        if (produtoNome) this.comprova.headers.unshift({ text: produtoNome, value: 'produto', sortable: false, align: 'center'})
        if (marcaNome) this.comprova.headers.unshift({ text: marcaNome, value: 'marca', sortable: false, align: 'center'})

        this.edita.headers = [...this.comprova.headers]

        this.main.lista = retorno.data.lista

        for (const t of this.main.lista.filtro) {

            this.main.filtro[t.var_id] = []
        }

        for (const c of retorno.data.camposListagem) {

            if(c.campo == 'modelo_arquivo') continue

            let index = this.headers.findIndex(e => e.value == 'investimento')

            this.headers.splice(index, 0, { text: c.nome, value: c.tabela+'_'+c.campo, align: 'center'},)
        }

        if (fnFaturamento) {

            this.headers.push({ text: 'TT Faturado', value: 'faturado_moeda', align: 'center'})
            this.headers.push({ text: 'Invest. vs TT Faturado', value: 'investimento_faturado', align: 'center'})
        }

        if (retorno.data.acoesComPercentual) {

            this.headers.push({ text: '% Executado', value: 'percentual_executado', align: 'center'})
        }

        if (retorno.data.sla.length) {

            this.headers.push({ text: 'SLA', value: 'sla_minutos', align: 'center'})

            
            this.indicadoresTotal.push({
                nome: 'ACORDOS COM SLA VENCIDA', 
                quant: 0, 
                quantSufixo: 'total_sla_vencidas', 
                valor: 0, 
                valorSufixo: 'investimento_sla_vencidas', 
                desc: 'Quantidade de acordos com SLA vencida / Valor total de Investimento dos acordos com SLA vencida.',
                icone: 'mdi-clock-remove',
                cor: 'error', 
                acao: this.filtrarSlaVencida,
                acaoTitulo: 'Filtrar acordos com SLA vencida',
            })

            this.indicadoresTotal.push({
                nome: 'ACORDOS SEM SLA VENCIDA', 
                quant: 0, 
                quantSufixo: 'total_sla_nao_vencidas', 
                valor: 0, 
                valorSufixo: 'investimento_sla_nao_vencidas', 
                desc: 'Quantidade de acordos sem SLA vencida / Valor total de Investimento dos acordos sem SLA vencida.',
                icone: 'mdi-clock-check',
                cor: 'success', 
                acao: this.filtrarSlaNaoVencida,
                acaoTitulo: 'Filtrar acordos sem SLA vencida',
            })
        }

        this.carregando = false

        this.filtrar()
    },
    computed: { 
        mesAtual() {
            
            return this.$moment().format('YYYY-MM')
        },
        mesLimite() {

            var formato = 'YYYY-MM'

            if(this.modelo.mes_limite > 0) {

                return this.$moment().add(Math.abs(this.modelo.mes_limite), 'months').format(formato)
            }
            else if(this.modelo.mes_limite < 0) {

                return this.$moment().subtract(Math.abs(this.modelo.mes_limite), 'months').format(formato)
            }
            else {

                return this.$moment().format(formato)
            }
        },
        mesLimiteNome() {

            var formato = 'MMMM[ de ]YYYY'

            if(this.modelo.mes_limite > 0) {

                return this.$moment().add(Math.abs(this.modelo.mes_limite), 'months').format(formato)
            }
            else if(this.modelo.mes_limite < 0) {

                return this.$moment().subtract(Math.abs(this.modelo.mes_limite), 'months').format(formato)
            }
            else {

                return this.$moment().format(formato)
            }
        }, 
        getMeses() {

            var meses = []

            if(this.acordo.mesInicial && this.acordo.mesFinal) {

                for (let m = this.acordo.mesInicial.substr(5, 2)*1; m <= this.acordo.mesFinal.substr(5, 2)*1; m++) {

                    meses.push(m)
                }
            }

            return meses
        },
        headersFiliais() {

            var headers = []

            headers.push({ text: 'ID Loja', value: 'id_loja', align: 'center'})

            for (const t of this.modelo.tabelas.filter(e => e.tipo == 'filial')) {

                headers.push({ 
                    text: t.nome, 
                    value: t.var_id.substr(0, 3) == 'id_' ? t.var_id.substr(3) : t.var_id, 
                    align: 'center'
                })
            }

            headers.push({ text: '', value: 'fn_filial', align: 'center', sortable: false})

            return headers
        },
        headersNotas() {

            var headers = [
                { text: 'Nota Fiscal', value: 'nota_fiscal', align: 'center'},
                { text: 'Data', value: 'data', align: 'center'},
                { text: 'CNPJ', value: 'cnpj', align: 'center'},
                { text: 'Valor', value: 'faturado', align: 'center'},
                { text: 'Produtos', value: 'numero_produtos', align: 'center'},
                { text: 'Investido', value: 'investimento', align: 'center'},
            ]

            if (this.modelo.tipo == 'Faturamento') {

                headers.unshift({ text: 'Selecionar Nota', value: 'selecionar', sortable: false, align: 'center'})
            }
            else {

                headers.unshift({ text: '', value: 'fn_nota', sortable: false, align: 'center'})
            }

            return headers
        },
        headersFiliaisResumo() {

            var headers = []

            headers.push({ text: 'ID Loja', value: 'id_loja', align: 'center'})

            for (const t of this.modelo.tabelas.filter(e => e.tipo == 'filial')) {

                headers.push({ 
                    text: t.nome, 
                    value: t.var_id.substr(0, 3) == 'id_' ? t.var_id.substr(3) : t.var_id, 
                    align: 'center'
                })
            }

            return headers
        },
        headersInvestimentoGroup() {

            var headersGroup = []

            headersGroup.push({ text: null, colspan: this.modelo.tabelas.filter(e => e.tipo == 'filtro').length, class: ''})

            var classe = 'primary darken-1'

            for (const m of this.getMeses) {

                if (this.modelo.tipo == 'Faturamento') {

                    headersGroup.push({ text: this.meses[m - 1], colspan: 6, class: 'v-data-table-header text-center '+classe})
                }
                else if (this.modelo.tipo == 'Faturamento_produto') {

                    headersGroup.push({ text: this.meses[m - 1], colspan: 5, class: 'v-data-table-header text-center '+classe})
                }
                else if (this.modelo.tipo == 'Faturamento_total') {

                    headersGroup.push({ text: this.meses[m - 1], colspan: 3, class: 'v-data-table-header text-center '+classe})
                }
                else {

                    headersGroup.push({ text: this.meses[m - 1], colspan: this.modelo.validacao_investimento == 'Mensal'  && !this.modelo.ocultar_disponivel ? 3 : 2, class: 'v-data-table-header text-center '+classe})
                }

                classe = classe == 'primary darken-1' ? '' : 'primary darken-1'
            }

            if (this.modelo.tipo == 'Planejado') {

                if (this.modelo.validacao_investimento == 'Mensal') {

                    headersGroup.push({ text: 'Total', colspan: 1, class: 'v-data-table-header text-center '+classe})
                }
                else {

                    headersGroup.push({ text: 'Total Ano', colspan: !this.modelo.ocultar_disponivel ? 3 : 1, class: 'v-data-table-header text-center '+classe})
                }
            }

            return headersGroup
        },
        headersInvestimento() {

            var headers = []

            for (const t of this.modelo.tabelas.filter(e => e.tipo == 'filtro')) {

                headers.push({ text: t.nome, value: t.tabela, align: 'center'})
            }

            var classe = 'primary darken-1'

            for (const m of this.getMeses) {

                if (this.modelo.tipo == 'Faturamento') {

                    headers.push({ text: '', value: 'fn_investimento_'+m, align: 'center', class: classe, sortable: false})
                    headers.push({ text: 'Produtos', value: 'produtos_'+m, align: 'center', class: classe, sortable: false})
                    headers.push({ text: 'Valor Selecionado', value: 'valor_'+m, align: 'center', class: classe, sortable: false})
                    headers.push({ text: '% Invest.', value: 'percentual_'+m, align: 'center', class: classe, sortable: false})
                    headers.push({ text: 'Invest.', value: 'investimento_'+m, align: 'center', class: classe, sortable: false})
                    headers.push({ text: 'Invest. vs TT Faturado', value: 'investimento_valor_'+m, align: 'center', class: classe, sortable: false})
                }
                else if (this.modelo.tipo == 'Faturamento_produto') {

                    headers.push({ text: '', value: 'fn_investimento_'+m, align: 'center', class: classe, sortable: false})
                    headers.push({ text: 'Faturado', value: 'valor_'+m, align: 'center', class: classe, sortable: false})
                    headers.push({ text: '% Invest.', value: 'percentual_'+m, align: 'center', class: classe, sortable: false})
                    headers.push({ text: 'Invest.', value: 'investimento_'+m, align: 'center', class: classe, sortable: false})
                    headers.push({ text: 'Invest. vs TT Faturado', value: 'investimento_valor_'+m, align: 'center', class: classe, sortable: false})
                }
                else if (this.modelo.tipo == 'Faturamento_total') {

                    headers.push({ text: '', value: 'fn_investimento_'+m, align: 'center', class: classe, sortable: false})
                    headers.push({ text: '% Invest.', value: 'percentual_'+m, align: 'center', class: classe, sortable: false})
                    headers.push({ text: 'Invest.', value: 'investimento_'+m, align: 'center', class: classe, sortable: false})
                }
                else {

                    headers.push({ text: '', value: 'fn_investimento_'+m, align: 'center', class: classe, sortable: false})
                    headers.push({ text: 'Investimento', value: 'investimento_'+m, align: 'center', class: classe, sortable: false})

                    if (this.modelo.validacao_investimento == 'Mensal' && !this.modelo.ocultar_disponivel) {

                        headers.push({ text: 'Disponível', value: 'saldo_'+m, align: 'center', class: classe})
                    }
                }

                classe = classe == 'primary darken-1' ? '' : 'primary darken-1'
            }

            if (this.modelo.tipo == 'Planejado') {

                if (this.modelo.validacao_investimento == 'Mensal') {

                    headers.push({ text: 'Investimento', value: 'investimento', align: 'center', class: classe})
                }
                else {

                    headers.push({ text: 'Investimento', value: 'investimento', align: 'center', class: classe})

                    if (!this.modelo.ocultar_disponivel) {

                        headers.push({ text: 'Disponível', value: 'disponivel', align: 'center', class: classe})
                        headers.push({ text: 'A Investir', value: 'saldo', align: 'center', class: classe})
                    }
                }
            }

            return headers
        },
        headersInvestimentoTotal() {

            var headers = []

            headers.push({ text: '', value: 'campo', align: 'center', sortable: false})

            if (['Faturamento', 'Faturamento_produto', 'Faturamento_total'].includes(this.modelo.tipo)) {

                headers.push({ text: 'TT Faturado', value: 'faturado', align: 'center'})
            }

            for (const m of this.getMeses) {

                if (['Faturamento', 'Faturamento_produto', 'Faturamento_total'].includes(this.modelo.tipo)) {

                    headers.push({ text: 'Invest. ' + this.meses[m - 1], value: 'investimento_'+m, align: 'center'})
                    headers.push({ text: 'Invest. vs TT Faturado ' + this.meses[m - 1], value: 'investimento_valor_'+m, align: 'center'})
                }
                else {

                    headers.push({ text: this.meses[m - 1], value: 'investimento_'+m, align: 'center'})
                }
            }

            if (['Faturamento', 'Faturamento_produto', 'Faturamento_total'].includes(this.modelo.tipo)) {

                //sem total ano
            }
            else {

                headers.push({ text: 'Total Ano', value: 'investimento', align: 'center'})
                
            }

            return headers
        },
        investimentoTotal() {

            var dados = []

            var indicadores = this.indicador == 'Total' ? ['Total'] : this.investimento.filter((v) => v.investimento > 0).map(e => e[this.indicador]).filter((v, i, a) => a.indexOf(v) === i)

            let investimentoLista = this.lista.investimento.map(e => (e))

            for (const e of indicadores) {

                let total = {}

                let investimento = investimentoLista.filter((v) => (v[this.indicador] == e || this.indicador == 'Total') && v.investimento > 0)

                total.campo = e

                if (this.modelo.tipo != 'Planejado') {

                    total['faturado'] = this.acordo.faturado
                }

                for (const m of this.getMeses) {

                    total['investimento_'+m] = investimento.reduce((a, i) => a + (i['fn_investimento_'+m] ? i['investimento_'+m]*1 : 0), 0)

                    if (this.modelo.tipo != 'Planejado') {

                        total['investimento_valor_'+m] = total['investimento_'+m] * 100 / this.acordo.faturado
                    }
                }

                total.investimento = investimento.reduce((a, i) => a + i.investimento*1, 0)

                dados.push(total)
            }

            return dados
        },
        headersInvestimentoResumo() {

            var headers = []

            for (const t of this.modelo.tabelas.filter(e => e.tipo == 'filtro')) {

                headers.push({ text: t.nome, value: t.tabela, align: 'center'})
            }

            for (const m of this.getMeses) {

                if (this.modelo.tipo != 'Planejado') {

                    headers.push({ text: 'Invest. ' + this.meses[m - 1], value: 'investimento_'+m, align: 'center'})
                    headers.push({ text: 'Invest. vs TT Faturado ' + this.meses[m - 1], value: 'investimento_valor_'+m, align: 'center'})
                }
                else {

                    headers.push({ text: this.meses[m - 1], value: 'investimento_'+m, align: 'center'})
                }
            }

            return headers
        },
        lojasSelecionadas() {

            return this.lista.filiais.filter(e => e.fn_filial).length
        },
    },
    methods: {
        novo() {

            if(this.modelos.length == 0) return

            if(this.modelos.length == 1) {

                this.setModelo(this.modelos[0])
            }

            if(this.modelos.length > 1) {

                this.dialogoModelo = true
            }
        },
        mostrarOcultar(tipo) {

            this.mostrar[tipo].flag = !this.mostrar[tipo].flag
            this.mostrar[tipo].icone = this.mostrar[tipo].flag ? 'mdi-chevron-up' : 'mdi-chevron-down'
        },
        async setModelo(modelo, acordo = null) {

            this.carregando = true

            this.modelo = modelo

            this.modelo.passoFilial = this.modelo.passos.find(e => e.nome == 'Selecionar Filiais') ? this.modelo.passos.find(e => e.nome == 'Selecionar Filiais') : {}

            this.modelo.passoFilial.fnLoja = this.modelo.passoFilial.tabelas ? this.modelo.passoFilial.tabelas.find(e => e.var_id == 'nome') : false

            this.paginationInvestimento = {...this.paginationLimpa}

            this.formulario.passo = 1

            if (!acordo) {

                this.acordo = {
                    mesInicial: null,
                    mesFinal: null,
                    modelo_data: [],
                    modelo_arquivo: [],
                    modelo_arquivoTemp: [],
                    modelo_acordo_1: [],
                    modelo_acordo_2: [],
                    modelo_acordo_3: [],
                    email: [],
                    faturado: 0,
                }

                this.acordo.nota_fiscal = []

                this.acordo.obs = []

                for (const p of this.modelo.passos) {

                    if (p.obs) {

                        this.acordo.obs[p.id_passo] = null
                    }
                }

                for (const t of this.modelo.tabelas) {

                    if(t.tipo == 'campo') {

                        this.acordo[t.var_id] = null
                    }
                }

            }
            else {

                this.acordo = {
                    fnEdicao: true,
                    id_acordo: acordo.id_acordo,
                    mesInicial: acordo.mes.substr(0, 7),
                    mesFinal: acordo.mes.substr(0, 7),
                    mesIndex: acordo.mes.substr(5, 2)*1,
                    modelo_data: [],
                    modelo_arquivo: [],
                    modelo_arquivoTemp: [],
                    modelo_acordo_1: [],
                    modelo_acordo_2: [],
                    modelo_acordo_3: [],
                    email:  acordo.email,
                    faturado: 0,
                }

                this.acordo.modelo_acordo_1[ this.modelo.passos[0].acordo_cliente_1_periodo ? this.acordo.m : 0 ] = acordo.modelo_acordo_1 ? acordo.modelo_acordo_1 : null
                this.acordo.modelo_acordo_2[ this.modelo.passos[0].acordo_cliente_2_periodo ? this.acordo.m : 0 ] = acordo.modelo_acordo_2 ? acordo.modelo_acordo_2 : null
                this.acordo.modelo_acordo_3[ this.modelo.passos[0].acordo_cliente_3_periodo ? this.acordo.m : 0 ] = acordo.modelo_acordo_3 ? acordo.modelo_acordo_3 : null

                this.acordo.modelo_data[ this.acordo.mesIndex ] = acordo.modelo_data ? acordo.modelo_data.substr(0, 10) : null

                this.acordo.obs = []

                for (const p of this.modelo.passos) {

                    if (p.obs) {

                        this.acordo.obs[p.id_passo] = acordo.obs.find(e => e.id_passo == p.id_passo) ? acordo.obs.find(e => e.id_passo == p.id_passo).obs : null
                    }
                }

                for (const t of this.modelo.tabelas) {

                    if(['campo', 'constante', 'origem', 'automatico'].includes(t.tipo)) {

                        this.acordo[t.var_id] = acordo[t.var_id]
                        this.acordo[t.tabela] = acordo[t.tabela]
                    }
                }

                this.acordo.filiais = acordo.filiais

                this.acordo.investimento = acordo.investimento

                this.acordo.nota_fiscal = acordo.nota_fiscal

                if (this.acordo.nota_fiscal.length) {

                    let data = {
                        acordo: this.acordo,
                        tipo: this.modelo.tipo,
                    }

                    let retorno = await this.$http().post(`/acordo/notas`, {data: data})

                    this.lista.notas = retorno.data
                    this.nota.notas = retorno.data.map(e => (e))
                    this.nota.fn_nota = false

                    for (const n of this.nota.notas) {

                        n.fn_nota = this.acordo.nota_fiscal.includes(n.nota_fiscal) ? true : false
                        this.acordo.faturado += this.acordo.nota_fiscal.includes(n.nota_fiscal) ? n.faturado*1 : 0
                        if (this.acordo.nota_fiscal.includes(n.nota_fiscal)) this.acordo.produtos = n.produtos
                    }
                }
            }

            this.filtro = {}

            this.fn_investimento = []
            this.fn_filial = false

            this.filiais = []

            for (const t of this.modelo.tabelas) {

                if(t.tipo == 'filial') {

                    this.filtroFilial[t.var_id] = []
                }

                if(t.tipo == 'filtro') {

                    this.filtro[t.var_id] = []
                }
            }

            this.dialogoModelo = false

            this.formulario.dialogo = true

            setTimeout(() => {
                this.carregando = false
            }, 500)
        },
        proximo() {

            this.alerta = false

            if (!this.$refs.form[this.formulario.passo-1].validate()) return

            if (this.modelo.passos[this.formulario.passo] && this.modelo.passos[this.formulario.passo].tipo == 'cadastro') {

                if (!this.acordo.fnEdicao) this.buscaCampo(this.formulario.passo)

                if (this.acordo.fnEdicao) this.buscaCampoEdicao()
            }

            if (this.modelo.passos[this.formulario.passo] && this.modelo.passos[this.formulario.passo].tipo == 'filiais') {

                this.buscaFiliais(this.formulario.passo)
            }

            if (this.modelo.passos[this.formulario.passo] && this.modelo.passos[this.formulario.passo].tipo == 'investimento') {

                if (this.modelo.passoFilial.ativo && this.lojasSelecionadas == 0) {

                    this.alerta = true
                    this.alertaColor = 'error'
                    this.alertaTimeout = -1
                    this.alertaTexto = 'Selecione pelo menos uma filial.'
                    return
                }

                this.buscaInvestimento(this.formulario.passo)
            }

            if (this.modelo.passos[this.formulario.passo-1] && this.modelo.passos[this.formulario.passo-1].tipo == 'investimento') {

                if (!this.validaInvestimento()) return
            }

            if (this.formulario.passo < this.modelo.passos.length) {

                this.formulario.passo++
            }
            else {

                this.mostraResumo()
            }
        },
        voltar() {

            this.formulario.passo--
        },
        async buscaCampo(passo) {

            this.carregando = true

            var tabelas = this.modelo.passos[passo].tabelas

            for (const t of tabelas.filter(e => e.tipo == 'campo')) {

                if(t.tabela == 'loja') {

                    this.acordo.email = []
                }

                delete this.acordo[t.var_id]
                this.lista[t.tabela] = []
            } 

            for (const t of tabelas.filter(e => e.tipo == 'constante')) {

                this.acordo[t.var_id] = t.valor
            } 

            var data = {id_cargo: this.$store.getters.getCargo.id_cargo}

            data.modelo = this.modelo
            data.tabela = tabelas.filter(e => e.tipo == 'campo')[0]
            data.acordo = this.acordo

            if (data.tabela.origem) {

                data.origem = tabelas.find(e => e.tabela == data.tabela.origem)
            }

            var retorno = await this.$http().post(`/acordo/campo`, {data: data}) 

            this.lista[data.tabela.tabela] = retorno.data

            this.carregando = false
        },
        async buscaCampoProximo(tabelaAtual) {

            let passo = this.modelo.passos[this.formulario.passo-1]
            
            if((passo.email || passo.email_exibir) && tabelaAtual.tabela == 'loja') {

                let loja = this.lista.loja.find(e => e.value == this.acordo.id_loja)

                //forma um array com todos os e-mails
                var emails = [ ...(loja.ad_2 ? loja.ad_2.split(',') : []) , ...(loja.ad_3 ? loja.ad_3.split(',') : []), ...(loja.ad_4 ? loja.ad_4.split(',') : [])]

                //limpa espaços em bramco
                emails = emails.map(e => e.trim())

                //remove e-mail duplicados
                emails = emails.filter((v, i, a) => a.indexOf(v) === i)

                //remove e-mail em branco
                emails = emails.filter(v => !!v)

                this.acordo.email = emails

                if(this.acordo.origem && this.acordo.origem[this.acordo.origem.var_id].length > 1) {

                    this.acordo[this.acordo.origem.var_id] = loja[this.acordo.origem.var_id]
                }
            }

            if(!this.acordo[tabelaAtual.var_id]) return

            this.carregando = true

            var tabelas = this.modelo.passos[this.formulario.passo-1].tabelas

            let arrayProximo = tabelaAtual.proximo ? tabelaAtual.proximo : []

            var tabelaAtualCampo = false

            if (tabelaAtual.origem) {

                let origemAtual = tabelas.find(e => e.tabela == tabelaAtual.origem)

                this.acordo[origemAtual.var_id] =  this.lista[tabelaAtual.tabela].find(e => e.value == this.acordo[tabelaAtual.var_id])[origemAtual.var_id]

                this.acordo.origem = origemAtual

                this.acordo.origem[origemAtual.var_id] =  this.lista[tabelaAtual.tabela].filter(e => e.value == this.acordo[tabelaAtual.var_id]).map(e => e[origemAtual.var_id])

                tabelaAtualCampo = tabelaAtual

                tabelaAtual = origemAtual
            }

            for (const proximo of arrayProximo) {

                let tabelaPoximo = tabelas.find(e => e.tabela == proximo)

                if(!tabelaPoximo) continue
                if(tabelaPoximo.tipo != 'campo') continue

                delete this.acordo[tabelaPoximo.var_id]
                this.lista[tabelaPoximo.tabela] = []

                if(passo.email && tabelaPoximo.tabela == 'loja') {

                    this.acordo.email = []
                }

                var data = {id_cargo: this.$store.getters.getCargo.id_cargo}

                data.modelo = this.modelo
                data.tabela = tabelaPoximo
                data.acordo = this.acordo
                data.tabelaAnterior = tabelaAtual
                data.tabelaAnteriorCampo = tabelaAtualCampo

                if (data.tabela.origem) {

                    data.origem = tabelas.find(e => e.tabela == data.tabela.origem)
                }
            
                var retorno = await this.$http().post(`/acordo/campo`, {data: data}) 

                this.lista[tabelaPoximo.tabela] = retorno.data
            }

            this.carregando = false
        },
        async buscaCampoEdicao() {

            this.carregando = true

            for (const t of this.modelo.tabelas) {

                if(t.tipo != 'campo') continue
                if(t.tabela == 'loja') continue
                if(t.busca_inventario == 1) continue
                if(t.valida_inventario == 1) continue
                if(t.origem) continue

                this.lista[t.tabela] = []

                var data = {id_cargo: this.$store.getters.getCargo.id_cargo}

                data.modelo = this.modelo
                data.tabela = t
                data.acordo = this.acordo

                var retorno = await this.$http().post(`/acordo/campo`, {data: data}) 

                this.lista[t.tabela] = retorno.data
            }

            this.carregando = false
        },
        async buscaNotas() {

            this.carregando = true

            if (!this.acordo.id_loja) {

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = 'Selecione uma loja para poder selecionar a nota.'
                this.carregando = false
                return
            }

            let data = {
                acordo: this.acordo,
                tipo: this.modelo.tipo,
            }

            var retorno = await this.$http().post(`/acordo/notas`, {data: data})

            this.lista.notas = retorno.data
            this.nota.notas = retorno.data.map(e => (e))
            this.nota.fn_nota = false

            for (const i of this.nota.notas) {

                i.fn_nota = this.acordo.nota_fiscal.includes(i.nota_fiscal) ? true : false
            }

            this.nota.dialogo = true

            this.carregando = false
        },
        alteraNotas() {

            this.acordo.nota_fiscal = []
            this.acordo.faturado = 0

            for (const i of this.nota.notas) {

                if (i.fn_nota) {
                    
                    this.acordo.nota_fiscal.push(i.nota_fiscal)
                    this.acordo.faturado += i.produtos.reduce( (a, b) => a + b.faturado , 0)
                }
            }
        },
        selecionarTudoNota() {

            for (const i of this.nota.notas) {

                i.fn_nota = this.nota.fn_nota
            }

            this.alteraNotas()
        },
        async produtos(item) {

            this.lista.produtos = item.produtos
            this.nota.produto.dialogo = true
        },
        async selecionarNota(item) {

            this.acordo.nota_fiscal = [item.nota_fiscal]
            this.acordo.faturado = item.produtos.reduce( (a, b) => a + b.faturado , 0)
            this.acordo.produtos = item.produtos
            this.nota.dialogo = false
        },
        async buscaFiliais(passo) {

            this.carregando = true
            
            this.paginationFiliais = {...this.paginationLimpa}

            var data = {}

            data.modelo = this.modelo
            data.passo = this.modelo.passos[passo]
            data.acordo = this.acordo

            var retorno = await this.$http().post(`/acordo/filiais`, {data: data})

            this.lista.filiais = retorno.data
            this.filiais = retorno.data.map(e => (e))

            for (const t of data.passo.tabelas) {

                this.filtroFilial[t.var_id] = []

                if (t.var_id == 'cnpj') {

                    this.lista[t.var_id] = this.lista.filiais.map(e => ({value: e[t.var_id], text: e.id_loja + ' - ' + e[t.var_id]}))
                }
                else if (t.var_id.substr(0, 3) == 'id_') {

                    this.lista[t.var_id] = this.lista.filiais.map(e => ({value: e[t.var_id], text: e[t.var_id] + ' - ' + e[t.var_id.substr(3)]}))
                }
                else {

                    this.lista[t.var_id] = this.lista.filiais.map(e => ({value: e[t.var_id], text: e[t.var_id]}))
                }

                this.lista[t.var_id] = this.lista[t.var_id].filter((v, i, a) => a.findIndex(e => e.value == v.value) === i)

                this.lista[t.var_id] = this.lista[t.var_id].sort((a,b) => a.value - b.value )
            }

            if (this.acordo.fnEdicao) {

                for (const f of this.filiais) {

                    f.fn_filial = this.acordo.filiais.includes(f.id_loja)
                }
            }

            this.carregando = false
        },
        async exportarFiliais() {

            this.carregando = true
           
            var data = {}

            data.modelo = this.modelo
            data.passo = this.modelo.passos[this.formulario.passo - 1]
            data.acordo = this.acordo

            var retorno = await this.$http().post(`/acordo/exportarFiliais`, {data: data})

            window.location = this.$http('baseURL')+retorno.data.url

            this.carregando = false
        },
        filtrarFilial () {

            this.paginationFiliais.page = 1

            var filiais = this.lista.filiais.map(e => (e))

            for (const t of this.modelo.tabelas.filter(e => e.tipo == 'filial')) {

                if(this.filtroFilial[t.var_id].length) {

                    filiais = filiais.filter( e => this.filtroFilial[t.var_id].includes( e[t.var_id] ) )
                }
            }

            this.fn_filial = false
            this.filiais = filiais
        },
        selecionarTudoFilial() {

            for (const i of this.filiais) {

                i.fn_filial = this.fn_filial
            }
        },
        abrirImportarFiliais() {

            this.importarFilial.arquivoCSV = null
            this.importarFilial.erro = false
            this.importarFilial.pagination.page = 1
            this.importarFilial.erros = []
            this.alerta = false

            try {
                this.$refs.formImportarFilial.resetValidation()
            } catch (error) {
                //console.log(error)
            }  

            this.importarFilial.dialogo = true 
        },
        async importarFiliais() {

            this.carregando = true

            for (const i of this.filiais) {

                i.fn_filial = false
            }

            for (const t of this.modelo.tabelas.filter(e => e.tipo == 'filial')) {

                this.filtroFilial[t.var_id] = []
            }

            this.alerta = false
            this.importarFilial.erros = []
            this.filiais = this.lista.filiais.map(e => (e))
            
            const parseCsv = async (csv) => {
                return new Promise(resolve => {
                    this.$papa.parse(csv, {
                        delimiter: ";",
                        header: true,
                        dynamicTyping: true,
                        skipEmptyLines: true,
                        complete: function(results) {
                            resolve(results)
                        },
                    })
                })
            }

            var csv = await parseCsv(this.importarFilial.arquivoCSV)

            if(csv.errors.length){

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = 'Erro ao ler o arquivo CSV. Exporte novamente o modelo e importe o modelo editado.'
                this.carregando = false
                return
            }

            var fnId = Object.keys(csv.data[0]).includes('ID Loja')

           if(!fnId){

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = `Verifique se a coluna "ID Loja" está escrita corretamente no arquivo CSV.`
                this.carregando = false
                return
            }

            var linha = 2
            var erroLinha = null

            for (const l of csv.data) {

                erroLinha = ' - Erro linha '+linha
                linha++

                let index = -1

                if (fnId) {

                    index = this.lista.filiais.findIndex(e => e.id_loja == l['ID Loja'])
                }

                if(index >= 0){
                       
                    this.filiais[index].fn_filial = true
                }
                else{

                    this.importarFilial.erros.push({
                        msg: `Loja não foi encontrada.`+erroLinha
                    })
                }
            }

            if(this.importarFilial.erros.length > 0){

                this.importarFilial.pagination.page = 1
                this.importarFilial.erro = true
            }
            else {

                this.importarFilial.dialogo = false
            }

            this.carregando = false
        },
        async buscaInvestimento(passo) {

            this.carregando = true

            this.paginationInvestimento = {...this.paginationLimpa}
            this.paginationInvestimentoTotal = {...this.paginationLimpa}

            this.indicador = 'Total'
            this.indicadores = [{text: 'Total', value: 'Total'}]

            for (const t of this.modelo.passos[passo].tabelas) {

               this.indicadores.push({text: t.nome, value: t.tabela}) 
            }

            var data = {}

            data.modelo = this.modelo
            data.passo = this.modelo.passos[passo]
            data.acordo = this.acordo
            
            var retorno = await this.$http().post(`/acordo/investimento`, {data: data}) 

            this.lista.investimento = retorno.data.investimento
            this.investimento = retorno.data.investimento.map(e => (e))

            this.filtro.investimento = []

            for (const t of this.modelo.tabelas.filter(e => e.tipo == 'filtro')) {

                this.filtro[t.var_id] = []

                this.lista[t.tabela] = this.lista.investimento.map(e => ({value: e[t.var_id], text: e[t.var_id] + ' - ' + e[t.tabela]}))

                this.lista[t.tabela] = this.lista[t.tabela].filter((v, i, a) => a.findIndex(e => e.value == v.value) === i)

                this.lista[t.tabela] = this.lista[t.tabela].sort((a,b) => a.value - b.value )
            }

            if (this.acordo.fnEdicao) {

                for (const i of this.acordo.investimento) {

                    let item = this.investimento.find(e => (
                        (i.id_marca == e.id_marca || i.id_marca == 0) && 
                        (i.id_produto == e.id_produto || i.id_produto == 0) && 
                        (i.id_acao == e.id_acao || i.id_acao == 0)
                    ))

                    if (item) {

                        item['fn_investimento_' + this.acordo.mesIndex] = true

                        if (this.modelo.tipo != 'Planejado') {

                            item['percentual_' + this.acordo.mesIndex] = i.percentual*1

                            if (this.modelo.tipo == 'Faturamento') {

                                item['produtos_' + this.acordo.mesIndex] = this.acordo.produtos.map(e => ( {...e, fn_produto: i.produtos.includes(e.id_produto) ? true : null } ))
                            }
                        }
                        else {

                            item['investimento_' + this.acordo.mesIndex] = i.investimento*1
                        }

                        this.alteraInvestimento(item)
                    }
                }
            }

            this.carregando = false
        },
        abreProdutoInvestimento(item, m) {

            if (item['produtos_'+m].length == 0) {

                item['produtos_'+m] = this.acordo.produtos.map(e => ( {...e, fn_produto: null} ))
            }

            this.produtoInvestimento.item = item
            this.produtoInvestimento.fn_produto = null
            this.produtoInvestimento.produtos = item['produtos_'+m]
            this.produtoInvestimento.dialogo = true
        },
        selecionarTudoProduto() {

            for (const i of this.produtoInvestimento.produtos) {

                i.fn_produto = this.produtoInvestimento.fn_produto
            }

            this.alteraInvestimento(this.produtoInvestimento.item)
        },
        filtrarInvestimento(tabela) {

            if (this.filtro['id_' + tabela].length) {

                if ( !this.filtro.investimento.includes(tabela) ) {

                    this.filtro.investimento.push(tabela)
                }
            }
            else {

                this.filtro.investimento.splice(this.filtro.investimento.indexOf(tabela), 1)
            }

            this.paginationInvestimento.page = 1

            var investimento = this.lista.investimento.map(e => (e))

            for (const t of this.filtro.investimento.map(e => ({tabela: e, var_id: 'id_' + e}))) {

                this.lista[t.tabela] = investimento.map(e => ({value: e[t.var_id], text: e[t.var_id] + ' - ' + e[t.tabela]}))

                this.lista[t.tabela] = this.lista[t.tabela].filter((v, i, a) => a.findIndex(e => e.value == v.value) === i)

                this.lista[t.tabela] = this.lista[t.tabela].sort((a,b) => a.value - b.value )

                if(this.filtro[t.var_id].length) {

                    investimento = investimento.filter(e => this.filtro[t.var_id].includes(e[t.var_id]) )
                }
            }

            for (const t of this.modelo.tabelas.filter(e => e.tipo == 'filtro' && !this.filtro.investimento.includes(e.tabela))) {

                this.lista[t.tabela] = investimento.map(e => ({value: e[t.var_id], text: e[t.var_id] + ' - ' + e[t.tabela]}))

                this.lista[t.tabela] = this.lista[t.tabela].filter((v, i, a) => a.findIndex(e => e.value == v.value) === i)

                this.lista[t.tabela] = this.lista[t.tabela].sort((a,b) => a.value - b.value )

                if(this.filtro[t.var_id].length) {

                    investimento = investimento.filter(e => this.filtro[t.var_id].includes(e[t.var_id]) )
                }
            }

            this.fn_investimento = []
            this.investimento = investimento
        },
        alteraInvestimento(item) {

            if (this.modelo.tipo != 'Planejado') {

                item.investimento = 0

                for (const m of this.getMeses) {

                    if (this.modelo.tipo == 'Faturamento') {

                        item['valor_'+m] = item['produtos_'+m].filter(e => e.fn_produto).reduce( (a, b) => a + b.faturado , 0)
                    }

                    item['investimento_'+m] = item['valor_'+m] * ( item['percentual_'+m] / 100 )

                    item.investimento += item['fn_investimento_'+m] ? item['investimento_'+m]*1 : 0

                    item['investimento_valor_'+m] = item['investimento_'+m] * 100 / this.acordo.faturado*1
                }
            }
            else {

                if (this.modelo.validacao_investimento == 'Mensal') {

                    for (const m of this.getMeses) {

                        item['erroValidaMensal_'+m] = false

                        if (item['fn_investimento_'+m] && item['investimento_'+m]*1 > item['disponivel_'+m]*1) {

                            item['erroValidaMensal_'+m] = true
                        }
                    }
                }

                let passo = this.modelo.passos[this.formulario.passo - 1]

                item.investimento = 0

                for (const m of this.getMeses) {

                    item.investimento += item['fn_investimento_'+m] ? item['investimento_'+m]*1 : 0
                }

                let investimentoLista = this.lista.investimento.map(e => (e))

                // saldo é calculado pelos filtros que são validados no planejamento
                // se algum filtro não for validado ==> o saldo é a somatória do agrupamentos dos filtros que são validados
                if (passo.tabelas.find(e => e.valida_inventario == 0)) {

                    let tabelasAgrupadas = passo.tabelas.filter(e => e.valida_inventario == 1)

                    let investimento = []

                    investimento['total'] = 0

                    for (const m of this.getMeses) {

                        investimento[m] = 0
                    }

                    for (const i of investimentoLista) {

                        i.fn_agrupa = 1

                        for (const t of tabelasAgrupadas) {

                            if (i[t.var_id] != item[t.var_id]) {

                                i.fn_agrupa = 0  
                            }
                        }

                        investimento['total'] += i.fn_agrupa ? i.investimento*1 : 0

                        
                        for (const m of this.getMeses) {

                            investimento[m] += i.fn_agrupa && i['fn_investimento_'+m] ? i['investimento_'+m]*1 : 0
                        }
                    }

                    for (const i of investimentoLista) {

                        if (i.fn_agrupa) {

                            i.saldo = i.disponivel*1 - investimento['total']*1

                            for (const m of this.getMeses) {

                                i['saldo_'+m] = i['disponivel_'+m]*1 - investimento[m]*1
                            }
                        }
                    }

                } else { //se todos os filtros forem validados ==> o calculo do saldo é por linha

                    item.saldo = item.disponivel*1 - item.investimento

                    for (const m of this.getMeses) {

                        item['saldo_'+m] = item['disponivel_'+m]*1 - ( item['fn_investimento_'+m] ? item['investimento_'+m]*1 : 0 )
                    }
                }

                if (this.modelo.validacao_investimento == 'Mensal') {

                    for (const i of investimentoLista) {

                        for (const m of this.getMeses) {

                            i['erroValidaMensal_'+m] = false

                            if (i['saldo_'+m]*1 < 0) {

                                i['erroValidaMensal_'+m] = true
                            }
                        }
                    }
                }
            }
        },
        investimentoClass(item) {

             return item.saldo < 0 ? 'errorClass' : ''
        },
        selecionarTudoInvestimento(m) {

            for (const i of this.investimento) {

                i['fn_investimento_'+m] = this.fn_investimento[m]

                this.alteraInvestimento(i)
            }
        },
        validaInvestimento() {

            let investimento = this.lista.investimento.map(e => (e))

            //#region valida ações unicas
            let acoes = []
            let acao_unica = false
            let acao_unica_nome = ''
            
            for (const i of investimento) {

                for (const m of this.getMeses) {

                    if (i['fn_investimento_'+m] && i.id_acao) {

                        if (!acoes.includes(i.id_acao)) acoes.push(i.id_acao)

                        if(i.acao_unica) {

                            acao_unica = true
                            acao_unica_nome = i.acao
                        }
                    }
                }
            }

            if (acao_unica && acoes.length > 1) {

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = 3000
                this.alertaTexto = `Quando a ação ${acao_unica_nome} é selecionada o acordo não pode ter outro tipo de ação.`
                return false
            }
            //#endregion

            if (this.modelo.validacao_investimento == 'Mensal') {

                for (const i of investimento) {

                    for (const m of this.getMeses) {

                        if (i['fn_investimento_'+m] && i['investimento_'+m]*1 > i['disponivel_'+m]*1) {

                            let desc = ``
                            desc += i.marca ? (desc != `` ? ` / ` : ``) + i.marca : ``
                            desc += i.produto ? (desc != `` ? ` / ` : ``) + i.produto : ``
                            desc += i.acao ? (desc != `` ? ` / ` : ``) + i.acao : ``

                            this.alerta = true
                            this.alertaColor = 'error'
                            this.alertaTimeout = 3000
                            this.alertaTexto = ``
                            this.alertaTexto += `O investimento do mês de ${this.meses[m-1]} de ${(i['investimento_'+m]*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} `
                            this.alertaTexto += `para ${desc} `
                            this.alertaTexto += `é maior que o disponivel de ${(i['disponivel_'+m]*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}.`
                            return false
                        }

                        if (i['fn_investimento_'+m] && i['saldo_'+m]*1 < 0) {

                            let desc = ``
                            desc += i.marca ? (desc != `` ? ` / ` : ``) + i.marca : ``
                            desc += i.produto ? (desc != `` ? ` / ` : ``) + i.produto : ``
                            desc += i.acao ? (desc != `` ? ` / ` : ``) + i.acao : ``

                            this.alerta = true
                            this.alertaColor = 'error'
                            this.alertaTimeout = 3000
                            this.alertaTexto = ``
                            this.alertaTexto += `O disponivel para o mês de ${this.meses[m-1]} esta negativo para ${desc}.`
                            return false
                        }
                    }
                }
            }

            if (investimento.find(e => e.saldo < 0)) {

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = 3000
                this.alertaTexto = 'Não pode haver saldos menores que zero.'

                return false
            }

            for (const m of this.getMeses) {

                if (!investimento.find(e => e['fn_investimento_'+m])) {

                    this.alerta = true
                    this.alertaColor = 'error'
                    this.alertaTimeout = 3000
                    this.alertaTexto = `Não é possível cadastrar o acordo, pois não existe nenhum investimento selecionado para ${this.meses[m-1]}.`

                    return false
                }

                let investimentoZero = investimento.find(e => e['fn_investimento_'+m] && e['investimento_'+m]*1 <= 0)

                if (!this.modelo.investimento_zero && investimentoZero) {

                    let desc = ``
                    desc += investimentoZero.marca ? (desc != `` ? ` / ` : ``) + investimentoZero.marca : ``
                    desc += investimentoZero.produto ? (desc != `` ? ` / ` : ``) + investimentoZero.produto : ``
                    desc += investimentoZero.acao ? (desc != `` ? ` / ` : ``) + investimentoZero.acao : ``

                    this.alerta = true
                    this.alertaColor = 'error'
                    this.alertaTimeout = 3000
                    this.alertaTexto = `Não é permitido cadastrar investimento igual a ZERO. Existe investimento igual a ZERO no mês de ${this.meses[m-1]} para ${desc}.`

                    return false
                }
            }

            return true
        },
        async abrirHistoricoInvestimento() {

            for (const t of this.modelo.tabelas.filter(e => e.tipo == 'filtro')) {

                this.historicoInvestimento.filtro[t.var_id] = []
            }

            this.historicoInvestimento.topEixo = this.modelo.tabelas.filter(e => e.tipo == 'filtro')[0].tabela

            for (const t of this.modelo.tabelas.filter(e => e.tipo == 'filtro')) {

                this.historicoInvestimento.headers.push({ text: t.nome, value: t.tabela, align: 'center'})
            }

            this.historicoInvestimento.headers.push({ text: 'Investimento', value: 'investimento', align: 'center'})

            this.historicoInvestimento.dialogo = true

            this.buscaHistoricoInvestimento()
        },
        async buscaHistoricoInvestimento() {

            this.carregando = true

            let data = {}

            data.modelo = this.modelo
            data.acordo = this.acordo
            data.filtro = {...this.historicoInvestimento.filtro}

            data.fnGrafico = true
            data.fnTop = true

            data.topEixo = this.modelo.tabelas.find(e => e.tabela == this.historicoInvestimento.topEixo)

            var response = await this.$http().post(`/acordo/historicoInvestimento`, {data: data}) 

            this.historicoInvestimento.investimento = response.data.investimento[0]

            this.historicoInvestimento.indicadoresTotal[0].valor = this.historicoInvestimento.investimento.investimento

            this.setaMeses()

            this.historicoInvestimento.top = response.data.top

            this.setaTop()

            this.carregando = false
        },
        async limparHistoricoInvestimento() {

            for (const t of this.modelo.tabelas.filter(e => e.tipo == 'filtro')) {

                this.historicoInvestimento.filtro[t.var_id] = []
            }

            this.buscaHistoricoInvestimento()
        },
        setaMeses() {

            this.historicoInvestimento.graficoMeses.title.text = 'Investimento ' + this.historicoInvestimento.periodos.find(e => e.value == this.historicoInvestimento.periodo).text

            this.historicoInvestimento.graficoMeses.xAxis.categories = []
            this.historicoInvestimento.graficoMeses.series[0].data = []

            var investimento = 0
            var contaPeriodo = 1

            for (let m = 1; m <= 12; m++) {

                investimento += this.historicoInvestimento.investimento['investimento_'+m]*1

                if(this.historicoInvestimento.periodo == 1) {

                    this.historicoInvestimento.graficoMeses.xAxis.categories.push(this.meses[m-1])
                    this.historicoInvestimento.graficoMeses.series[0].data.push(investimento)

                    investimento = 0
                }

                if(this.historicoInvestimento.periodo > 1 && m % this.historicoInvestimento.periodo == 0) {

                    let categoria = this.historicoInvestimento.periodo == 12 ? this.acordo.mesFinal.substr(0, 4) : contaPeriodo + 'º ' + this.historicoInvestimento.periodos.find(e => e.value == this.historicoInvestimento.periodo).text.slice(0, -2) + 'e'

                    this.historicoInvestimento.graficoMeses.xAxis.categories.push(categoria)
                    this.historicoInvestimento.graficoMeses.series[0].data.push(investimento)

                    investimento = 0
                    contaPeriodo++
                }
            }
        },
        async setaTop(fnTop=false) {

            if(fnTop) {

                this.carregando = true

                let data = {}

                data.modelo = this.modelo
                data.acordo = this.acordo
                data.filtro = this.historicoInvestimento.filtro

                data.fnTop = true

                data.topEixo = this.modelo.tabelas.find(e => e.tabela == this.historicoInvestimento.topEixo)

                var response = await this.$http().post(`/acordo/historicoInvestimento`, {data: data}) 

                this.historicoInvestimento.top = response.data.top

                this.carregando = false
            }

            let nomes = [ ...new Set( [ 
                ...this.historicoInvestimento.top.investimento.map(e => e.nome),
            ] ) ]

            let top = []

            for (const n of nomes) {

                let valor = {}

                valor.investimento = this.historicoInvestimento.top.investimento.find(e => e.nome == n) ? this.historicoInvestimento.top.investimento.find(e => e.nome == n).investimento*1 : 0

                top.push({nome: n, valor: valor[this.historicoInvestimento.indicador]})
            }

            //this.historicoInvestimento.graficoTop.title.text = `${this.historicoInvestimento.indicadores.find(e => e.value == this.historicoInvestimento.indicador).text} Top  ${this.modelo.tabelas.find(e => e.tabela == this.historicoInvestimento.topEixo).plural}`
            this.historicoInvestimento.graficoTop.series[0].color = this.historicoInvestimento.indicadores.find(e => e.value == this.historicoInvestimento.indicador).cor

            if(this.historicoInvestimento.indicadores.find(e => e.value == this.historicoInvestimento.indicador).tipo == 'moeda') {

                this.historicoInvestimento.graficoTop.plotOptions.bar.dataLabels.formatter = function () { return this.point.y.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }
            }
            if(this.historicoInvestimento.indicadores.find(e => e.value == this.historicoInvestimento.indicador).tipo == 'percentual') {

                this.historicoInvestimento.graficoTop.plotOptions.bar.dataLabels.formatter = function () { return this.point.y + '%' }
            }

            this.historicoInvestimento.graficoTop.series[0].cursor = this.historicoInvestimento.indicadores.find(e => e.value == this.historicoInvestimento.indicador).fnDetalhado ? 'pointer' : 'default'

            top = top.sort((a,b) => b.valor - a.valor)

            this.historicoInvestimento.graficoTop.series[0].data = []
            this.historicoInvestimento.graficoTop.xAxis.categories = []

            let topNumero = 10

            let elementos = top.length >= topNumero ? topNumero : top.length 

            for (let i = 0; i < elementos; i++) {

                this.historicoInvestimento.graficoTop.xAxis.categories.push(top[i].nome)
                this.historicoInvestimento.graficoTop.series[0].data.push(top[i].valor)
            }
        },        
        async historicoInvestimentoDetalhado(fnPrimeiraBusca=true) {

            if (fnPrimeiraBusca) {
                
                this.paginationDetalhado = {...this.paginationLimpa}
                return
            }

            this.historicoInvestimento.dialogoDetalhado = true

            this.carregando = true

            let data = {}

            data.modelo = this.modelo
            data.acordo = this.acordo
            data.filtro = {...this.historicoInvestimento.filtro}
            data.pagination = this.paginationDetalhado

            data.topEixo = this.modelo.tabelas.find(e => e.tabela == this.historicoInvestimento.topEixo)

            data.filtro.nome = this.historicoInvestimento.filtroDetalhado.nome 

            if (this.historicoInvestimento.filtroDetalhado.mes && this.historicoInvestimento.periodo != 12) {

                let mes = 0 

                if (this.historicoInvestimento.periodo == 1) {

                    mes = this.meses.findIndex(e => e == this.historicoInvestimento.filtroDetalhado.mes) + 1
                }
                else {

                    mes = this.historicoInvestimento.filtroDetalhado.mes.slice(0, 1)*1

                    mes = (mes - 1) * this.historicoInvestimento.periodo + 1
                }

                data.filtro.mesInicial = this.acordo.mesFinal.substr(0, 4) + '-' + ("0" + mes).slice(-2)
                data.filtro.mesFinal = this.acordo.mesFinal.substr(0, 4) + '-' + ("0" + (mes - 1 + this.historicoInvestimento.periodo)).slice(-2)
            }

            var response = {}

            response = await this.$http().post('/acordo/historicoInvestimentoDetalhado', {data: data})

            this.historicoInvestimento.dadosDetalhado = response.data.lista

            this.historicoInvestimento.totalDetalhado = response.data.lista[0] ? response.data.lista[0].total : 0

            if(this.historicoInvestimento.totalDetalhado == 0 && this.paginationDetalhado.page > 1) {

                this.paginationDetalhado.page = 1
                this.detalhado()
            }

            this.carregando = false
        },       
        async historicoInvestimentoExportar() {

            this.carregando = true

            let data = {}

            data.modelo = this.modelo
            data.acordo = this.acordo
            data.filtro = {...this.historicoInvestimento.filtro}

            data.topEixo = this.modelo.tabelas.find(e => e.tabela == this.historicoInvestimento.topEixo)

            data.filtro.nome = this.historicoInvestimento.filtroDetalhado.nome 

            if (this.historicoInvestimento.filtroDetalhado.mes && this.historicoInvestimento.periodo != 12) {

                let mes = 0 

                if (this.historicoInvestimento.periodo == 1) {

                    mes = this.meses.findIndex(e => e == this.historicoInvestimento.filtroDetalhado.mes) + 1
                }
                else {

                    mes = this.historicoInvestimento.filtroDetalhado.mes.slice(0, 1)*1

                    mes = (mes - 1) * this.historicoInvestimento.periodo + 1
                }

                data.filtro.mesInicial = this.acordo.mesFinal.substr(0, 4) + '-' + ("0" + mes).slice(-2)
                data.filtro.mesFinal = this.acordo.mesFinal.substr(0, 4) + '-' + ("0" + (mes - 1 + this.historicoInvestimento.periodo)).slice(-2)
            }

            var response = {}

            response = await this.$http().post('/acordo/historicoInvestimentoExportar', {data: data})

            window.location = this.$http('baseURL')+response.data.url

            this.carregando = false
        },
        mostraResumo() {

            let ano = this.acordo.mesInicial.split('-')[0]
            let mesInicial = this.acordo.mesInicial.split('-')[1] - 1
            let mesFinal = this.acordo.mesFinal.split('-')[1] - 1

            this.resumo.periodo = `Período de ${this.meses[mesInicial]} a ${this.meses[mesFinal]} de ${ano}`

            this.resumo.campos = []

            for (const t of this.modelo.passos[1].tabelas) {

                if (t.tipo == 'campo') {

                    if (this.acordo.fnEdicao && (t.origem || t.busca_inventario != 0 || t.valida_inventario != 0 || t.tabela == 'loja')) {

                        this.resumo.campos.push(t.nome + ': ' + this.acordo[t.tabela])
                    }
                    else {

                        let obj = this.lista[t.tabela].find(e => e.value == this.acordo[t.var_id])

                        this.resumo.campos.push(t.nome + ': ' + obj.text)
                    }
                }
            }

            let investimento = this.lista.investimento.map(e => (e))

            this.investimentoResumo = investimento.filter(e => {

                let fn_investimento = false

                for (const m of this.getMeses) {

                    if(e['fn_investimento_'+m]) fn_investimento = true
                }

                return fn_investimento
            })

            this.resumo.dialogo = true
        },
        async salvar() {

            this.carregando = true

            var data = {id_cargo: this.$store.getters.getCargo.id_cargo}

            data.modelo = this.modelo
            data.acordo = this.acordo

            let investimento = this.lista.investimento.map(e => (e))

            data.investimento = investimento.filter(e => {

                let fn_investimento = false

                for (const m of this.getMeses) {

                    if(e['fn_investimento_'+m]) fn_investimento = true
                }

                return fn_investimento
            })

            let filiais = this.lista.filiais.map(e => (e))

            data.filiais = filiais.filter(e => e.fn_filial)

            var formData = new FormData()

            for (const file of this.acordo.modelo_arquivo) {

                formData.append("files", file)
            }

            var response = {}

            if (this.acordo.fnEdicao) {

                data.processo = this.processo

                formData.append("data", JSON.stringify(data))

                response = await this.$http().post(`/acordo/editaAcordoCompleto`, formData)
            }
            else {

                formData.append("data", JSON.stringify(data))

                response = await this.$http().post(`/acordo/salvar`, formData)
            }

            this.filtrar()

            if(response.data.sucesso){

                this.alerta = true
                this.alertaColor = 'success'
                this.alertaTimeout = 3000
                this.alertaTexto = response.data.msg

                setTimeout(() => { 

                    this.alerta = false
                    this.carregando = false
                    this.formulario.dialogo = false
                }, 1000);
            }
            else{

                if(response.data.msg) {

                    this.alerta = true
                    this.alertaColor = 'error'
                    this.alertaTimeout = -1
                    this.alertaTexto = response.data.msg
                    this.carregando = false
                }
                else {

                    if (response.data.atualizarInvestimento) {

                        this.buscaInvestimento(this.formulario.passo - 1)

                        this.resumo.dialogo = false
                    }

                    this.dialogoErro = true 
                    this.listaErro = response.data.erros
                    this.paginationErro.page = 1
                }
            }
        },
        filtrarStatus(id_status) {

            this.main.filtro.id_status = id_status ? [id_status] : []

            this.status.dialogo = false

            this.filtrar()
        },
        async filtrarSlaVencida() {

            this.main.filtro.sla = 'vencidas'

            await this.filtrar()
        },
        async filtrarSlaNaoVencida() {

            this.main.filtro.sla = 'nao_vencidas'

            await this.filtrar()
        },
        async filtrar() {

            if (this.modelosLista.length == 0) return

            if (!this.$refs.formFiltro.validate()) return

            this.carregando = true

            var data = {id_cargo: this.$store.getters.getCargo.id_cargo}

            data.pagination = this.pagination

            data.filtro = {...this.main.filtro}
            data.filtro.id_acordo = this.main.filtro.id_acordo ? [this.main.filtro.id_acordo] : []
            data.filtro.id_contrato = this.main.filtro.id_contrato ? [this.main.filtro.id_contrato] : []

            data.modelos = this.modelosLista

            data.tipo = 'padrao'
            data.importarLote = this.importarLote.obj

            var response = await this.$http().post(`/acordo/lista`, {data: data})
            
            this.dados = response.data.lista
            this.total = response.data.lista.length ? response.data.indicadores[0].total*1 : 0

            let indicadores = response.data.indicadores[0]

            for (const indicador of this.indicadoresTotal) {

                let propriedade = indicador.quantSufixo
                let quant = indicadores[propriedade]

                indicador.quant = quant ? quant*1 : 0

                propriedade = indicador.valorSufixo
                let valor = indicadores[propriedade]

                indicador.valor = valor ? valor*1 : 0
            }

            let statusDados = this.main.lista['acordo.status'].map(e => ({id_status: e.value, status: e.text}))

            for (const status of statusDados) {
                
                status.total = indicadores['total_'+status.id_status]*1
                status.total_p = indicadores.total*1 ? Math.round(100*indicadores['total_'+status.id_status]/indicadores.total) : 0
                status.investimento = indicadores['investimento_'+status.id_status]*1
                status.investimento_p = indicadores.investimento*1 ? Math.round(100*indicadores['investimento_'+status.id_status]/indicadores.investimento) : 0
            }

            statusDados = this.main.filtro.id_status.length ? statusDados.filter(e => this.main.filtro.id_status.includes(e.id_status)) : statusDados

            statusDados = statusDados.sort((a,b) => b.investimento - a.investimento)

            this.status.dados = [...statusDados]

            if(this.total == 0 && this.pagination.page > 1) {

                this.pagination.page = 1
                this.filtrar()
            }

            this.carregando = false
        },
        limpar() {

            this.pagination = {...this.paginationLimpa}

            this.main.filtro = {
                mesInicial: this.mesAtual,
                mesFinal: this.mesAtual,
                acordo: null,
                contrato: null,
            }  
            
            for (const t of this.main.lista.filtro) {
                
                this.main.filtro[t.var_id] = []
            }
        },
        async exportar(tipo='padrao') {

            if (this.modelosLista.length == 0) return
           
            this.carregando = true
            
            var data = {id_cargo: this.$store.getters.getCargo.id_cargo}

            data.filtro = {}

            data.filtro = {...this.main.filtro}
            data.filtro.id_acordo = this.main.filtro.id_acordo ? [this.main.filtro.id_acordo] : []
            data.filtro.id_contrato = this.main.filtro.id_contrato ? [this.main.filtro.id_contrato] : []

            data.modelos = this.modelosLista

            data.tipo = tipo
            data.importarLote = this.importarLote.obj

            var response = await this.$http().post(`/acordo/exportar`, {data: data})   

            window.location = this.$http('baseURL')+response.data.url
            
            this.carregando = false
        }, 
        async detalhado() {
           
            this.carregando = true
            
            var data = {id_cargo: this.$store.getters.getCargo.id_cargo}

            data.filtro = {}

            data.filtro = {...this.main.filtro}
            data.filtro.id_acordo = this.main.filtro.id_acordo ? [this.main.filtro.id_acordo] : []
            data.filtro.id_contrato = this.main.filtro.id_contrato ? [this.main.filtro.id_contrato] : []

            data.modelos = this.modelosLista

            var response = await this.$http().post(`/acordo/detalhado`, {data: data})   

            window.location = this.$http('baseURL')+response.data.url
            
            this.carregando = false
        }, 
        async buscaHistorico(item) {

            this.carregando = true
            
            this.historico.dados = []
            
            this.historico.titulo = 'Histórico (Acordo: '+item.id_acordo+')'

            var response = await this.$http().post('/acordo/historico', {data: {id_acordo: item.id_acordo}})

            this.historico.dados = response.data

            this.historico.dialogo = true
            this.carregando = false
        },
        async buscaAcordoInvestimentoFaturamento(item) {

            this.carregando = true

            this.investimentoFaturamento.headers = [
                { text: 'Ação', value: 'acao', sortable: false, align: 'center'},
                { text: 'Produtos', value: 'numero_produtos', sortable: false, align: 'center'},
                { text: 'Valor Selecionado', value: 'valor', sortable: false, align: 'center'},
                { text: '% Invest.', value: 'percentual', sortable: false, align: 'center'},
                { text: 'Invest.', value: 'investimento', sortable: false, align: 'center'},
                { text: 'Invest. vs TT Faturado', value: 'investimento_faturado', sortable: false, align: 'center'},
            ]

            this.investimentoFaturamento.dados = []

            this.investimentoFaturamento.titulo = 'Detalado de investimento / faturado (Acordo: '+item.id_acordo+')'

            var response = await this.$http().post('/acordo/acordoInvestimentoFaturamento', {data: {id_acordo: item.id_acordo}})

            this.investimentoFaturamento.dados = response.data

            this.investimentoFaturamento.dialogo = true
            this.carregando = false
        },
        investimentoFaturamentoProdutos(item) {

            this.carregando = true

            this.investimentoFaturamento.produtos.headers = [
                { text: 'Código', value: 'codigo', sortable: false, align: 'center'},
                { text: 'Produto', value: 'produto', sortable: false, align: 'center'},
                { text: 'Valor', value: 'faturado', sortable: false, align: 'center'},
            ]

            this.investimentoFaturamento.produtos.dados = []

            this.investimentoFaturamento.produtos.titulo = 'Produtos Selecionados'

            this.investimentoFaturamento.produtos.dados = item.produtos

            this.investimentoFaturamento.produtos.dialogo = true
            this.carregando = false
        },
        async buscaAcordoFiliais(item) {

            this.carregando = true

            this.filiaisAcordo.headers = [
                { text: 'ID Loja', value: 'id_loja', sortable: false, align: 'center'},
            ]

            let tabelasFiliais = this.modelosLista.find(e => e.id_modelo == item.id_modelo).passos.find(e => e.tipo == 'filiais').tabelas

            for (const t of tabelasFiliais) {

                this.filiaisAcordo.headers.push({ text: t.nome, value: t.var_id, sortable: false, align: 'center'})
            }
            
            this.filiaisAcordo.dados = []
            
            this.filiaisAcordo.titulo = 'Filiais (Acordo: '+item.id_acordo+')'

            var response = await this.$http().post('/acordo/acordoFiliais', {data: {id_acordo: item.id_acordo}})

            this.filiaisAcordo.dados = response.data

            this.filiaisAcordo.dialogo = true
            this.carregando = false
        },
        async buscaAuditorias(item) {

            this.carregando = true
            
            this.auditorias.dados = []
            
            this.auditorias.titulo = 'Auditorias (Acordo: '+item.id_acordo+')'

            var response = await this.$http().post('/acordo/auditorias', {data: {id_acordo: item.id_acordo}})

            this.auditorias.dados = response.data

            this.auditorias.dialogo = true
            this.carregando = false
        },
        async buscaAuditoriasHistorico(item) {

            this.carregando = true
            
            this.auditoriasHistorico.dados = []
            
            this.auditoriasHistorico.titulo = 'Auditorias Histórico (Acordo: '+item.id_acordo+' / Auditoria: '+item.id_auditoria+')'

            var response = await this.$http().post('/acordo/auditoriasHistorico', {data: {id_auditoria: item.id_auditoria}})

            this.auditoriasHistorico.dados = response.data

            this.auditoriasHistorico.dialogo = true
            this.carregando = false
        },
        async processar(item, processo) {

            this.processo = processo
            this.processo.acordos = [item.id_acordo]
            this.processo.id_acordo = item.id_acordo
            this.processo.id_status = item.id_status
            this.processo.justificativa = null
            this.processo.observacao = null
            this.processo.executado = 1
            this.processo.evidencia = []
            this.processo.evidenciaTemp = []
            this.processo.arquivo = null

            if (processo.confirm) {

                this.confirm.titulo = `${processo.nome} - (Acordo: ${item.id_acordo})`
                this.confirm.texto = processo.confirm_msg
                this.confirm.color = processo.color
                this.confirm.icon = processo.icon
                this.confirm.botao = processo.nome
                this.confirm.justificativas = processo.justificativas
                this.confirm.funcao = this[processo.function]
                this.confirm.dialogo = true
            }
            else if (processo.function == 'comprovaAcordo') {

                let data = this.processo
                data.modelo = this.modelosLista.find(e => e.id_modelo == item.id_modelo)

                this.comprova.investimento = ( await this.$http().post('/acordo/acordoInvestimento', {data: data}) ).data
                
                this.comprova.titulo = `${processo.nome} - (Acordo: ${item.id_acordo})`
                this.comprova.dialogo = true
            }
            else if (processo.function == 'editaAcordo') {

                let data = this.processo
                data.modelo = this.modelos.find(e => e.id_modelo == item.id_modelo)

                this.edita.investimento = ( await this.$http().post('/acordo/acordoInvestimento', {data: data}) ).data

                this.edita.titulo = `${processo.nome} - (Acordo: ${item.id_acordo})`
                this.edita.dialogo = true
            }
            else if (processo.function == 'editaAcordoCompleto') {

                let data = this.processo
                data.modelo = this.modelos.find(e => e.id_modelo == item.id_modelo)

                this.carregando = true

                let acordo = ( await this.$http().post('/acordo/acordoDados', {data: data}) ).data

                this.setModelo(data.modelo, acordo)
            }
            else {

                this[processo.function]()
            }
        },
        reprovaAuditorias() {

            this.alteraStatus()
        },
        async alteraStatus() {

            if (this.processo.confirm && !this.$refs.formConfirm.validate()) return

            this.carregando = true

            var data = this.processo

            var formData = new FormData()

            formData.append("files", data.arquivo)
            formData.append("data", JSON.stringify(data))

            var response = await this.$http().post('/acordo/alteraStatus', formData) 
            
            this.confirm.dialogo = false
            this.carregando = false
            
            if(response.data.sucesso) {

               this.filtrar()
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }  
        },
        async comprovaAcordo() {
            
            if (!this.$refs.formComprova.validate()) return
           
            this.carregando = true
            
            var data = this.processo
            
            data.investimento = this.comprova.investimento

            var formData = new FormData()

            for (const file of data.evidencia) {

                formData.append("files", file)
            }

            formData.append("data", JSON.stringify(data))

            var response = await this.$http().post('/acordo/comprovaAcordo', formData) 
            
            this.carregando = false
            
            if(response.data.sucesso) {
                
                this.comprova.dialogo = false

                this.filtrar()
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }
        },
        mostraArquivo(item, fnHistorico=true) {

            this.evidencias.titulo = `${this.camposListagem.find(e => e.campo == 'modelo_arquivo').nome} (Acordo: ${item.id_acordo})`
            this.evidencias.lista = item.modelo_arquivo
            this.evidencias.dialogo = true
            this.evidencias.historico = fnHistorico
            this.evidencias.id_acordo = item.id_acordo
        },
        mostraEvidencia(item) {

            this.evidencias.titulo = `Evidências (Acordo: ${item.id_acordo}${item.id_acao ? ' / Ação: '+item.acao : ''}${item.cnpj ? ' / Loja: '+item.cnpj : ''})`
            this.evidencias.lista = item.evidencia
            this.evidencias.dialogo = true
            this.evidencias.historico = false
            this.evidencias.id_acordo = item.id_acordo
        },
        mostraEvidenciaItem(item) {

            this.evidencia.titulo = item.nome

            if (item.tipo == 'application/pdf')  {

                this.evidencia.html = `<embed src="${item.evidencia}" type="application/pdf" width="100%" height="450px">`
                this.evidencia.dialogo = true
            }
            else if (item.tipo == 'text/plain')  {

                this.evidencia.html = `<embed src="${item.evidencia}" type="text/plain" width="100%" height="450px">`
                this.evidencia.dialogo = true
            }
            else if (item.tipo == 'application/json')  {

                this.evidencia.html = `<embed src="${item.evidencia}" type="application/json" width="100%" height="450px">`
                this.evidencia.dialogo = true
            }
            else if (item.tipo.split('/')[0] == 'image') {

                this.evidencia.html = `<img src="${item.evidencia}" width="100%"></img>`
                this.evidencia.dialogo = true
            }
            else {

                window.location = item.evidencia
            }
        },
        async buscaArquivoHistorico() {

            this.evidencias.dialogo = false

            this.carregando = true
            
            this.arquivoHistorico.dados = []
            
            this.arquivoHistorico.titulo = 'Histórico (Acordo: '+this.evidencias.id_acordo+')'

            var response = await this.$http().post('/acordo/arquivoHistorico', {data: {id_acordo: this.evidencias.id_acordo}})

            this.arquivoHistorico.dados = response.data

            this.arquivoHistorico.dialogo = true

            this.carregando = false
        },
        mostraUpload(item, upload) {

            this.evidencia.dialogo = true
            this.evidencia.titulo = `${upload.nome} (Acordo: ${item.id_acordo}${item.id_acao ? ' / Ação: '+item.acao : ''}${item.cnpj ? ' / Loja: '+item.cnpj : ''})`

            if (upload.arquivo.tipo == 'pdf')  {

                this.evidencia.html = `<embed src="${upload.arquivo.url}" type="application/pdf" width="100%" height="450px">`
            }
            else {

                this.evidencia.html = `<img src="${upload.arquivo.url}" width="100%"></img>` 
            }
            
        },
        async editaAcordo () {
           
            this.carregando = true
            
            var data = this.processo
            
            data.investimento = this.edita.investimento

            var response = await this.$http().post('/acordo/editaAcordo', {data: data}) 
            
            this.carregando = false
            
            if(response.data.sucesso) {
                
                this.edita.dialogo = false

                this.filtrar()
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }
        },
        async enviaRecibo () {

            this.carregando = true
            
            var data = this.processo

            var response = await this.$http().post('/acordo/enviaRecibo', {data: data}) 
            
            this.confirm.dialogo = false
            this.carregando = false
            
            if(response.data.sucesso) {

                this.alerta = true
                this.alertaColor = 'success'
                this.alertaTimeout = 3000
                this.alertaTexto = response.data.msg
               
                this.filtrar()
            }
            else {

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }  
        },
        async buscaRecibo(item) {

            this.carregando = true
            
            var response = await this.$http().post('/acordo/recibo', {data: item})
            
            this.evidencia.dialogo = true
            this.evidencia.titulo = 'Recibo (Acordo: '+item.id_acordo+')'

            this.evidencia.html = `<embed src="${this.$http('baseURL')+response.data.url}?x=${Date.now()}" type="application/pdf" width="100%" height="450px">`

            this.carregando = false
        },
        iconeSelecionarTudo(var_id, tabela, filtro, lista) {

            if(!filtro[var_id] || !lista[tabela]) return

            if(filtro[var_id].length === lista[tabela].length) return 'mdi-close-box'
            if (filtro[var_id].length > 0 && !(filtro[var_id].length === lista[tabela].length)) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        selecionarTudo (var_id, tabela, filtro, lista) {

            if(!filtro[var_id] || !lista[tabela]) return

            this.carregando = true

            setTimeout(() => {

                if(filtro[var_id].length === lista[tabela].length) {

                    filtro[var_id] = []
                }
                else {

                    filtro[var_id] = lista[tabela].map(e => e.value)
                }
            }, 100)

            setTimeout(() => { this.carregando = false }, (lista[tabela].length > 100 ? lista[tabela].length : 110))
        },
        changeFile (tipo='evidencia') {

            if (tipo == 'evidencia') {

                for (const t of this.processo.evidenciaTemp) {

                    if ( !this.processo.evidencia.map(e => e.name).includes(t.name) ) {

                        this.processo.evidencia.push(t)
                    }
                }

                this.processo.evidenciaTemp = this.processo.evidencia
            }

            if (tipo == 'arquivo') {

                for (const t of this.acordo.modelo_arquivoTemp) {

                    if ( !this.acordo.modelo_arquivo.map(e => e.name).includes(t.name) ) {

                        this.acordo.modelo_arquivo.push(t)
                    }
                }

                this.acordo.modelo_arquivoTemp = this.acordo.modelo_arquivo
            }
        },
        clearFile (tipo='evidencia') {

            if (tipo == 'evidencia') {

                this.processo.evidencia = []
                this.processo.evidenciaTemp = []
            }

            if (tipo == 'arquivo') {

                this.acordo.modelo_arquivo = []
                this.acordo.modelo_arquivoTemp = []
            }
        },
        removeFile (index, tipo='evidencia') {

            if (tipo == 'evidencia') {

                this.processo.evidencia.splice(index, 1)
            }

            if (tipo == 'arquivo') {

                this.acordo.modelo_arquivo.splice(index, 1)
            }
        },
        abrirImportarLote(item) {

            this.importarLote.arquivoCSV = null
            this.importarLote.erro = false
            this.importarLote.obj = {...item}

            try {
                this.$refs.formImportarLote.resetValidation()
            } catch (error) {
                //console.log(error)
            }

            this.importarLote.dialogo = true 
        },
        async enviarImportarLote() {

            this.carregando = true

            var formData = new FormData()

            let data = this.importarLote.obj

            data.modelos = this.modelosLista
            data.id_cargo = this.$store.getters.getCargo.id_cargo

            formData.append("files", this.importarLote.arquivoCSV)
            formData.append("importarLote", JSON.stringify(data))

            this.importarLote.arquivoCSV = null

            try {
                this.$refs.formImportarLote.resetValidation()
            } catch (error) {
                //console.log(error)
            }

            var response = await this.$http().post(`/acordo/importarLote`, formData)  

            this.carregando = false

            if(response.data.sucesso){

                this.filtrar()
                this.importarLote.dialogo = false
            }
            else{

                this.filtrar()
                this.importarLote.erros = response.data.erros
                this.importarLote.paginationErro.page = 1
                this.importarLote.erro = true
            }
        },
    },
}
</script>

<style>
.style-investimento-negativo {
    color: var(--v-error-base) !important;
}
.sla{
  width:100px;
}
.sla span{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>