<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-toolbar-title class="ml-2 mt-2">Clientes</v-toolbar-title>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">   
                <v-btn
                    class="ml-2 my-1"
                    color="primary"
                    @click="novo()"
                >
                    <v-icon left>mdi-plus</v-icon> Criar
                </v-btn>
            </v-col>
        </v-row>
        <v-card class="mt-2 py-4 px-2" elevation="1">
            <v-row no-gutters class="mb-4">
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                        label="Ativos"
                        :items="[{text:'Sim', value: 1},{text:'Não', value: 0},{text:'Todos', value: null}]"
                        v-model="ativo"
                        dense 
                        outlined
                        hide-details
                    >
                    </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4" align="end">
                    <v-text-field
                        v-model="busca"
                        append-icon="mdi-magnify"
                        label="Pesquisar"
                        dense 
                        outlined
                        hide-details
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-data-table
                :headers="headers"
                :items="dados"
                :server-items-length="total" 
                :items-per-page="5"
                :footer-props="{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100]
                }"
                :options.sync="pagination"
                multi-sort
                class="elevation-0"
            >
                <template v-slot:[`item.editar`]="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        color="primary"
                        @click="editar(item)"
                        title="Editar"
                    >
                        mdi-pencil
                    </v-icon>
                </template>
                <template v-slot:[`item.login`]="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        color="primary"
                        @click="login(item)"
                        title="Acessar Login"
                    >
                        mdi-login
                    </v-icon>
                </template>
                <template v-slot:[`item.limparPlanejamento`]="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        color="error"
                        @click="abrirConfirm(item, 'limparPlanejamentos')"
                        title="Limpar Planejamentos"
                    >
                        mdi-cash-remove
                    </v-icon>
                </template>
                <template v-slot:[`item.limparAcordos`]="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        color="error"
                        @click="abrirConfirm(item, 'limparAcordos')"
                        title="Limpar Acordos"
                    >
                        mdi-file-remove
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog
            v-model="dialogo"
            width="90%"
        >
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Cliente
                </v-card-title>
                <v-card-text class="mt-2"> 
                    <v-form ref="form"> 
                        <v-tabs v-model="tab" class="mb-4">
                            <v-tab>Dados</v-tab>
                            <v-tab>Logos</v-tab>
                            <v-tab>SSO</v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab">
                            <v-tab-item key="Dados">
                                 <v-row no-gutters class="mt-4">
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            disabled
                                            label="ID Cliente"
                                            v-model="obj.id_cliente"
                                        ></v-text-field> 
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="Cliente"
                                            v-model="obj.nome"
                                            :rules="regra.nome"
                                            :disabled="!!obj.id_cliente"
                                        ></v-text-field> 
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="Titulo"
                                            v-model="obj.titulo"
                                            :rules="regra.obrigatorio"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="ID Job - IPDV 360"
                                            v-model="obj.id_job"
                                            type="number"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2">
                                        <v-text-field v-if="!!obj.id_cliente"
                                            outlined
                                            dense 
                                            disabled
                                            label="Servidor"
                                            v-model="obj.servidor"
                                            :rules="regra.obrigatorio"
                                        ></v-text-field> 
                                        <v-select v-else
                                            label="Servidor"
                                            v-model="obj.servidor"
                                            :rules="regra.obrigatorio"
                                            :items="lista.servidor"
                                            outlined
                                            dense 
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2">
                                        <v-select
                                            label="Ativo"
                                            :items="[{text:'Sim', value: 1},{text:'Não', value: 0}]"
                                            v-model="obj.ativo"
                                            outlined
                                            dense 
                                        >
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" class="px-2"> 
                                        <v-checkbox
                                            label="Enviar todos os e-mail do sistema para os e-mails cadastrados abaixo"
                                            v-model="obj.email_enviar_unico"
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" class="px-2"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="E-mails"
                                            v-model="obj.email_unico"
                                        ></v-text-field> 
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item key="Logos">
                                 <v-row no-gutters class="mt-4">
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2" @click="tmpCor=obj.cor; dialogoCor=true;">
                                        Cor Padrão
                                        <v-text-field
                                            outlined
                                            dense 
                                            disabled
                                            class="input-texto-branco"
                                            v-model="obj.cor"
                                            :backgroundColor="obj.cor"
                                        ></v-text-field>
                                        <v-dialog
                                            v-model="dialogoCor"
                                            width="300"
                                        >
                                            <v-card>
                                                <v-color-picker
                                                    v-model="obj.cor"
                                                    :mode.sync="mode"
                                                ></v-color-picker>
                                                <v-divider></v-divider>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn
                                                        color="primary"
                                                        class="pr-4"
                                                        @click="obj.cor=tmpCor; dialogoCor=false;"
                                                    >
                                                        <v-icon left>mdi-close</v-icon> Cancelar
                                                    </v-btn>
                                                    <v-btn
                                                        color="primary"
                                                        class="pr-4"
                                                        @click="dialogoCor=false"
                                                    >
                                                        <v-icon left>mdi-check</v-icon> OK
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2" @click="tmpCorFundo=obj.cor_fundo; dialogoCorFundo=true;">
                                        Cor de Fundo
                                        <v-text-field
                                            outlined
                                            dense 
                                            disabled
                                            v-model="obj.cor_fundo"
                                            :backgroundColor="obj.cor_fundo"
                                        ></v-text-field>
                                        <v-dialog
                                            v-model="dialogoCorFundo"
                                            width="300"
                                        >
                                            <v-card>
                                                <v-color-picker
                                                    v-model="obj.cor_fundo"
                                                    :mode.sync="mode"
                                                ></v-color-picker>
                                                <v-divider></v-divider>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn
                                                        color="primary"
                                                        class="pr-4"
                                                        @click="obj.cor_fundo=tmpCorFundo; dialogoCorFundo=false;"
                                                    >
                                                        <v-icon left>mdi-close</v-icon> Cancelar
                                                    </v-btn>
                                                    <v-btn
                                                        color="primary"
                                                        class="pr-4"
                                                        @click="dialogoCorFundo=false"
                                                    >
                                                        <v-icon left>mdi-check</v-icon> OK
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2 pb-4">
                                        Logo Login
                                        <br><br>
                                        <img :src="obj.logo_login_assinado" @click="abrirImagem('Logo Login', 'logo_login', 400, 150)">
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2 pb-4">
                                        Logo Interno
                                        <br><br>
                                        <img :src="obj.logo_interno_assinado" @click="abrirImagem('Logo Interno', 'logo_interno', 300, 110)">
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2 pb-4">
                                        Logo Icone
                                        <br><br>
                                        <img :src="obj.logo_icone_assinado" @click="abrirImagem('Logo Icone', 'logo_icone', 100, 100)">
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item key="SSO">
                                 <v-row no-gutters class="mt-4">
                                    <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="px-2"> 
                                        <v-checkbox
                                            label="Ocultar Login"
                                            v-model="obj.oculta_login"
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="px-2"> 
                                        <v-checkbox
                                            label="Exibir Botão SSO"
                                            v-model="obj.exibe_sso"
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="px-2"> 
                                        <v-btn
                                            icon
                                            color="primary"
                                            title="Imagem de Referência"
                                            @click="ajudaSSO=true"
                                        >
                                            <v-icon>mdi-comment-question</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="Texto Botão"
                                            v-model="obj.sso_botao"
                                        ></v-text-field> 
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="Identificador (ID da Entidade)"
                                            v-model="obj.sso_issuer"
                                        ></v-text-field> 
                                    </v-col>
                                    <v-col cols="12" class="px-2"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="URL de Resposta (URL do Serviço do Consumidor de Declaração)"
                                            :value="`${$http('baseURL')}api/${obj.nome}/sso`"
                                            disabled
                                        ></v-text-field> 
                                    </v-col>
                                    <v-col cols="12" class="px-2"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="URL de Logon (SingleSignOnService)"
                                            v-model="obj.sso_entry_point"
                                        ></v-text-field> 
                                    </v-col>
                                    <v-col cols="12" class="px-2"> 
                                        <v-textarea
                                            outlined
                                            dense 
                                            label="Certificado (KeyDescriptor use=signing)"
                                            v-model="obj.sso_cert"
                                        ></v-textarea> 
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2">
                                        <v-select
                                            label="Algorithm"
                                            :items="['sha1', 'sha256', 'sha512']"
                                            v-model="obj.sso_signature_algorithm"
                                            outlined
                                            dense 
                                        >
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="px-2"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="Atributo E-mail (claims/emailaddress)"
                                            v-model="obj.sso_atributo_email"
                                        ></v-text-field> 
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                        </v-tabs-items>
                        <v-dialog
                            v-model="imagem.dialogo"
                            width="700"
                        >
                            <v-card>
                                <v-card-title class="subtitle-1 primary white--text">
                                    {{imagem.titulo}}
                                </v-card-title>
                                <v-card-text class="pt-4 pl-4">
                                    <v-image-input
                                        v-model="imagem.base64"
                                        clearable
                                        image-format="png"
                                        :imageWidth="imagem.imageWidth"
                                        :imageHeight="imagem.imageHeight"
                                        imageMinScaling="contain"
                                    />
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="primary"
                                        class="pr-4"
                                        @click="imagem.dialogo=false"
                                    >
                                        <v-icon left>mdi-close</v-icon> Cancelar
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        class="pr-4"
                                        @click="salvarImagem()"
                                    >
                                        <v-icon left>mdi-check</v-icon> OK
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="salvar()"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="ajudaSSO"
            width="98%"
        >  
            <v-card>
                <v-card-text>
                    <v-img src="@/assets/images/sso.jpg"></v-img>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="ajudaSSO=false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="confirm.dialogo"
            width="600"
        >  
            <v-card>
                <v-card-title class="subtitle-1 error white--text">
                    {{confirm.titulo}}
                </v-card-title>
                <v-card-text class="mt-4"> 
                    <b>{{confirm.texto}}</b>
                    <br>
                    <br>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="confirm.dialogo=false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="error"
                        class="pr-4"
                        @click="confirm.funcao"
                    >
                        <v-icon left>mdi-delete</v-icon> {{confirm.botao}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        
        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn
                dark
                text
                class="float-right"
                @click="alerta=false"
            >
            Fechar
            </v-btn>
        </v-snackbar>

    </v-container>
</template>

<script>

export default {
    data() {
        return {
            tab: null,
            headers: [
                { text: 'ID Cliente', value: 'id_cliente', align: 'center'},
                { text: 'Cliente', value: 'nome', align: 'center'},
                { text: 'Titulo', value: 'titulo', align: 'center'},
                { text: 'Servidor Desc.', value: 'servidor_nome', align: 'center'},
                { text: 'Servidor', value: 'servidor', align: 'center'},
                { text: 'ID Job', value: 'id_job', align: 'center'},
                { text: 'Ativo', value: 'ativo_nome', align: 'center'},
                { text: '', value: 'editar', align: 'center', sortable: false },
                { text: '', value: 'login', align: 'center', sortable: false },
                { text: '', value: 'limparPlanejamento', align: 'center', sortable: false },
                { text: '', value: 'limparAcordos', align: 'center', sortable: false },
            ],
            dados: [], 
            lista: {},
            objNovo: {
                id_cliente: null,
                nome: null,
                titulo: null,
                servidor: null,
                cor: '#053594',
                cor_fundo: '#f2f6fc',
                logo_login: 'https://ifinance.s3.us-east-1.amazonaws.com/adm/production/logo_login/ipdv.png',
                logo_interno: 'https://ifinance.s3.us-east-1.amazonaws.com/adm/production/logo_interno/ipdv.png',
                logo_icone: 'https://ifinance.s3.us-east-1.amazonaws.com/adm/production/logo_icone/ipdv.png',
                ativo: 1,
                oculta_login: false,
                exibe_sso: false,
                sso_botao: null,
                sso_issuer: null,
                sso_entry_point: null,
                sso_cert: null,
                sso_signature_algorithm: 'sha256',
                sso_atributo_email: null,
                id_job: null,
                email_enviar_unico: false,
                email_unico: null,
            },
            obj: {},
            pagination: {},
            total: null,
            carregando: false, 
            ativo: 1,
            busca: null, 
            dialogo: false,
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            regra: { 
                obrigatorio: [v => !!v || 'Campo obrigatório.'],
                nome: [
                    v => !!v || 'Campo obrigatório.',
                    v => !v || /^[a-z0-9]+$/.test(v) || 'O campo cliente so pode ter letras minusculas e números.',
                    v => !v || /^[a-z]/.test(v) || 'O campo cliente deve começar com uma letras minuscula.',
                ],
            },
            mode: 'hexa',
            dialogoCor: false,
            tmpCor: null,
            dialogoCorFundo: false,
            tmpCorFundo: null,
            imagem: {
                titulo: null,
                base64: null,
                dialogo: false,
                imageWidth: null,
                imageHeight: null,
                obj: null,
            },
            confirm: {
                dialogo: false,
                titulo: null,
                texto: null,
                funcao: null,
                botao: null,
            },
            ajudaSSO: false,
        }
    },
    watch: {
        pagination: {
            handler () {
                this.filtrar()
            },
            deep: true,
        },
        busca(v) {

            setTimeout(() => {

                if(v == this.busca){
                    
                    this.filtrar()
                }

            }, 1000)
        },
        ativo() {

            this.filtrar()
        },
    },
    async created() {

        this.lista.servidor = ( await this.$http().post(`/servidor/filtro`, {data: {}}) ).data
    },
    methods: {
        async filtrar() {

            this.carregando = true

            var data = {}

            data.pagination = this.pagination
            data.busca = this.busca

            data.filtro = {}
            data.filtro.ativo = this.ativo == null ? [] : [this.ativo]

            var response = await this.$http().post(`/cliente/lista`, {data: data})            
            
            this.dados = response.data
            this.total = response.data.length ? response.data[0].total : 0

            if(this.total == 0 && this.pagination.page > 1) {

                this.pagination.page = 1
                this.filtrar(false)
            }

            this.carregando = false
        },
        novo() {
           
            this.obj = {...this.objNovo}

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }

            this.dialogo = true
        },
        editar(item) {
           
            this.obj = {...item}

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }
            
            this.dialogo = true
        }, 
        async salvar() {

            for (const key in this.obj) {

                if (typeof this.obj[key] == 'string') {

                    this.obj[key] = this.obj[key].trim()
                }
            }
            
            if(!this.$refs.form.validate()) return
            
            this.carregando = true
            
            var data = this.obj

            delete data.total

            var response = await this.$http().post(`/cliente/salvar`, {data: data})

            this.carregando = false

            if(response.data.sucesso){

                this.filtrar()
                this.dialogo = false
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }
        },
        abrirImagem(titulo, obj, imageWidth, imageHeight) {

            this.imagem.titulo = titulo
            this.imagem.base64 = null
            this.imagem.imageWidth = imageWidth
            this.imagem.imageHeight = imageHeight
            this.imagem.obj = obj
            this.imagem.dialogo = true
        },
        async salvarImagem() {

            if(this.imagem.base64 == null) {

                this.imagem.dialogo = false
                return
            }
           
            this.carregando = true
            
            var data = {
                base64: this.imagem.base64,
                obj: this.imagem.obj,
            }

            var response = await this.$http().post(`/cliente/salvarImagem`, {data: data})

            this.carregando = false

            if(response.data.sucesso) {

                this.obj[this.imagem.obj] = response.data.url
                this.obj[this.imagem.obj + '_assinado'] = response.data.url_assinado
                this.imagem.dialogo = false
            }
            else {

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }
        },
        login(item) {

            window.open(window.location.origin+'/'+item.nome)
        },
        abrirConfirm(item, processo) {

            this.obj = {...item}

            if (processo == 'limparAcordos') {

                this.confirm.titulo = 'LIMPAR ACORDOS'
                this.confirm.texto = 'DESEJA REALMENTE LIMPAR  TODOS OS ACORDOS E SEUS HISTORICOS?'
                this.confirm.botao = 'LIMPAR ACORDOS'
                this.confirm.justificativas = false
                this.confirm.funcao = this[processo]
                this.confirm.dialogo = true
            }

            if (processo == 'limparPlanejamentos') {

                this.confirm.titulo = 'LIMPAR PLANEJAMENTOS'
                this.confirm.texto = 'DESEJA REALMENTE LIMPAR  TODOS OS PLANEJAMENTOS E SEUS HISTORICOS?'
                this.confirm.botao = 'LIMPAR PLANEJAMENTOS'
                this.confirm.justificativas = false
                this.confirm.funcao = this[processo]
                this.confirm.dialogo = true
            }
        },
        async limparAcordos() {

            this.carregando = true
            
            var data = this.obj

            var response = await this.$http().post(`/cliente/limparAcordos`, {data: data})

            this.confirm.dialogo = false

            this.carregando = false

            if(response.data.sucesso){

                this.alerta = true
                this.alertaColor = 'success'
                this.alertaTimeout = 3000
                this.alertaTexto = response.data.msg
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }
        },
        async limparPlanejamentos() {

            this.carregando = true
            
            var data = this.obj

            var response = await this.$http().post(`/cliente/limparPlanejamentos`, {data: data})

            this.confirm.dialogo = false

            this.carregando = false

            if(response.data.sucesso){

                this.alerta = true
                this.alertaColor = 'success'
                this.alertaTimeout = 3000
                this.alertaTexto = response.data.msg
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }
        },
    },
}
</script>