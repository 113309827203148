<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-toolbar-title class="ml-2 mt-2">Acordos</v-toolbar-title>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">   
                <v-btn
                    class="ml-2 my-1"
                    color="primary"
                    @click="novo()"
                >
                    <v-icon left>mdi-plus</v-icon> Criar
                </v-btn>
            </v-col>
        </v-row>
        <v-card class="mt-2 py-4 px-2" elevation="1">
            <v-row no-gutters class="mb-4">
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                        label="Ativos"
                        :items="[{text:'Sim', value: 1},{text:'Não', value: 0},{text:'Todos', value: null}]"
                        v-model="ativo"
                        dense 
                        outlined
                        hide-details
                    >
                    </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4" align="end">
                </v-col>
            </v-row>
            <v-data-table
                :headers="headers"
                :items="dados"
                :server-items-length="total" 
                :items-per-page="5"
                :footer-props="{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100]
                }"
                :options.sync="pagination"
                multi-sort
                class="elevation-0"
            >
                <template v-slot:[`item.editar`]="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        color="primary"
                        @click="editar(item)"
                        title="Editar"
                    >
                        mdi-pencil
                    </v-icon>
                </template>
            </v-data-table>
            <v-alert
                class="mt-4"
                text
                type="info"
                border="left"
                style="font-size: 12px;"
            >
                <ul>
                    <li>
                        Todos os modelos devem ter o atributo <b>loja (Matriz)</b> (para identificarmos o CNPJ recebedor).
                    </li>
                    <li>
                        Todos os modelos devem ter o atributo <b>Forma de Pagamento</b> (para a listagem de acordos funcionar corretamente).
                    </li>
                    <li>
                        Todos os modelos devem ter um atributo <b>Setor ou Bandeira</b> vinculado ao planejamento (para buscarmos o planejado).
                    </li>
                </ul>
            </v-alert>
        </v-card>

        <v-dialog
            v-model="dialogo"
            width="90%"
        >
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Modelo de Acordos
                </v-card-title>
                <v-card-text class="mt-2"> 
                    <v-form ref="form"> 
                        <v-tabs v-model="tab" class="mb-4">
                            <v-tab>Dados do Modelo</v-tab>
                            <v-tab v-if="obj.id_modelo">Período do Acordo</v-tab>
                            <v-tab v-if="obj.id_modelo">Cadastro de Acordo</v-tab>
                            <v-tab v-if="obj.id_modelo">Selecionar Filiais</v-tab>
                            <v-tab v-if="obj.id_modelo">Investimento</v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab">
                            <v-tab-item key="Dados do Modelo">
                                 <v-row no-gutters class="mt-4">
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2">
                                        <v-text-field
                                            outlined
                                            dense 
                                            disabled
                                            label="ID Modelo"
                                            v-model="obj.id_modelo"
                                        ></v-text-field> 
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="Modelo"
                                            v-model="obj.nome"
                                            :rules="regra.obrigatorio"
                                        ></v-text-field> 
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2">
                                        <v-select
                                            label="Tipo"
                                            :items="lista.tipos"
                                            v-model="obj.tipo"
                                            :rules="regra.obrigatorio"
                                            :disabled="!!obj.id_modelo" 
                                            outlined
                                            dense 
                                        >
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2">
                                        <v-select
                                            label="Desdobramento"
                                            :items="lista.desdobramento"
                                            v-model="obj.id_desdobramento"
                                            :rules="regra.obrigatorio"
                                            :disabled="!!obj.id_modelo"
                                            outlined
                                            dense 
                                        >
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2">
                                        <v-select
                                            label="Limite inicial do período"
                                            :items="lista.mes_limite"
                                            v-model="obj.mes_limite"
                                            :rules="regra.obrigatorio"
                                            outlined
                                            dense 
                                        >
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="Ordem"
                                            v-model="obj.ordem"
                                            :rules="regra.obrigatorio"
                                        ></v-text-field> 
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2">
                                        <v-select
                                            label="Ativo"
                                            :items="[{text:'Sim', value: 1},{text:'Não', value: 0}]"
                                            v-model="obj.ativo"
                                            outlined
                                            dense 
                                        >
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="% de Execução para Auditoria"
                                            v-model="obj.percentual_executado"
                                            :rules="regra.percentual_executado"
                                            type="number"
                                        ></v-text-field> 
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                        <v-select
                                            label="Validação do Investimento"
                                            :items="['Anual', 'Mensal']"
                                            v-model="obj.validacao_investimento"
                                            outlined
                                            dense 
                                        >
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2">
                                        <v-switch
                                            label="Permitir Investimento igual a ZERO"
                                            v-model="obj.investimento_zero"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2">
                                        <v-switch
                                            label="Ocultar Disponivel / A investir"
                                            v-model="obj.ocultar_disponivel"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2">
                                        <v-switch
                                            label="Habilitar Histórico de Investimento no Cadastro do Acordo"
                                            v-model="obj.historico_investimento"
                                        ></v-switch>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item v-for="(passo, index) in passos" :key="passo">
                                <v-row no-gutters class="mt-4">
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2">
                                        <v-select
                                            label="Ativo"
                                            :items="[{text:'Sim', value: 1},{text:'Não', value: 0}]"
                                            v-model="obj.passo[index].ativo"
                                            outlined
                                            dense 
                                        >
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2">
                                        <v-switch
                                            label="Habilitar Observação"
                                            v-model="obj.passo[index].obs"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="Observação Nome"
                                            v-model="obj.passo[index].obs_nome"
                                        ></v-text-field> 
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="index == 0">
                                        <v-switch
                                            label="Habilitar Data"
                                            v-model="obj.passo[index].data"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="index == 0">
                                        <v-switch
                                            label="Data Obrigatória"
                                            v-model="obj.passo[index].data_obrigatorio"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2" v-if="index == 0"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="Data Nome"
                                            v-model="obj.passo[index].data_nome"
                                        ></v-text-field> 
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="index == 1">
                                        <v-switch
                                            label="Habilitar Upload de Arquivo"
                                            v-model="obj.passo[index].arquivo"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="index == 1">
                                        <v-switch
                                            label="Upload de Arquivo Obrigatório"
                                            v-model="obj.passo[index].arquivo_obrigatorio"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2" v-if="index == 1"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="Arquivo Nome"
                                            v-model="obj.passo[index].arquivo_nome"
                                        ></v-text-field> 
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="px-2" v-if="index == 1"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="Upload de Arquivo Texto"
                                            v-model="obj.passo[index].arquivo_texto"
                                        ></v-text-field> 
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="index == 1">
                                        <v-switch
                                            label="Habilitar Acordo Cliente 1"
                                            v-model="obj.passo[index].acordo_cliente_1"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="index == 1">
                                        <v-switch
                                            label="Acordo Cliente 1 Obrigatório"
                                            v-model="obj.passo[index].acordo_cliente_1_obrigatorio"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="index == 1">
                                        <v-switch
                                            label="Acordo Cliente 1 Por Parcela"
                                            v-model="obj.passo[index].acordo_cliente_1_periodo"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="index == 1"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="Acordo Cliente 1 Nome"
                                            v-model="obj.passo[index].acordo_cliente_1_nome"
                                        ></v-text-field> 
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="index == 1">
                                        <v-switch
                                            label="Habilitar Acordo Cliente 2"
                                            v-model="obj.passo[index].acordo_cliente_2"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="index == 1">
                                        <v-switch
                                            label="Acordo Cliente 2 Obrigatório"
                                            v-model="obj.passo[index].acordo_cliente_2_obrigatorio"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="index == 1">
                                        <v-switch
                                            label="Acordo Cliente 2 Por Parcela"
                                            v-model="obj.passo[index].acordo_cliente_2_periodo"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="index == 1"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="Acordo Cliente 2 Nome"
                                            v-model="obj.passo[index].acordo_cliente_2_nome"
                                        ></v-text-field> 
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="index == 1">
                                        <v-switch
                                            label="Habilitar Acordo Cliente 3"
                                            v-model="obj.passo[index].acordo_cliente_3"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="index == 1">
                                        <v-switch
                                            label="Acordo Cliente 3 Obrigatório"
                                            v-model="obj.passo[index].acordo_cliente_3_obrigatorio"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="index == 1">
                                        <v-switch
                                            label="Acordo Cliente 3 Por Parcela"
                                            v-model="obj.passo[index].acordo_cliente_3_periodo"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="3" xl="3" class="px-2" v-if="index == 1"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="Acordo Cliente 3 Nome"
                                            v-model="obj.passo[index].acordo_cliente_3_nome"
                                        ></v-text-field> 
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2" v-if="index == 1">
                                        <v-switch
                                            label="Habilitar Editar E-mail do Aprovador do Recibo"
                                            v-model="obj.passo[index].email"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2" v-if="index == 1">
                                        <v-switch
                                            label="Habilitar Visualização do E-mail do Aprovador do Recibo"
                                            v-model="obj.passo[index].email_exibir"
                                        ></v-switch>
                                    </v-col>
                                </v-row>
                                <v-row 
                                    v-if="index >= 1"
                                    no-gutters 
                                    class="mb-2"
                                >
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" align="end">
                                        <v-btn
                                            color="primary"
                                            class="pr-4"
                                            @click="novoAtributo(index)"
                                        >
                                            <v-icon left>mdi-plus</v-icon> Adicionar Atributo
                                        </v-btn> 
                                    </v-col>
                                </v-row>
                                <v-data-table
                                    v-if="index >= 1"
                                    :headers="atributo.headers[index]"
                                    :items="obj.passo[index].passo_tabela"
                                    :items-per-page="5"
                                    :footer-props="{
                                        'items-per-page-options': [5, 10, 15, 25, 50, 100]
                                    }"
                                    :options.sync="atributo.pagination"
                                    class="elevation-0"
                                >
                                    <template v-slot:[`item.editar`]="{ item }"> 
                                        <v-icon
                                            small
                                            class="mr-2"
                                            color="primary"
                                            @click="editarAtributo(item, index)"
                                            title="Editar Atributo"
                                        >
                                            mdi-pencil
                                        </v-icon>
                                    </template>
                                    <template v-slot:[`item.remover`]="{ item }">
                                        <v-icon
                                            small
                                            class="mr-2"
                                            color="primary"
                                            @click="removerAtributoConfirm(item)"
                                            title="Remover Atributo"
                                        >
                                            mdi-trash-can
                                        </v-icon>
                                    </template>
                                </v-data-table>
                                <v-alert
                                    v-if="index == 1"
                                    class="mt-4"
                                    text
                                    type="info"
                                    border="left"
                                    style="font-size: 12px;"
                                >
                                    <ul>
                                        <li>
                                            Depois de cadastrar todos as <b>Atributos</b> não esqueça de configurar a coluna <b>Proximos Filtros</b> dos <b>Atributos</b>.
                                        </li>
                                        <li>
                                            Se na <b>configuração de Lojas NÃO</b> estiver marcado o campo <b>cnpj_matriz</b> e o campo <b>matriz</b>, todas as <b>lojas buscadas</b> serão exibidas.
                                        </li>
                                        <li>
                                            Se na <b>configuração de Lojas</b> estiver marcado o campo <b>cnpj_matriz</b>, as <b>lojas</b> exibidas serão as <b>lojas</b> com <b>cnpj igual aos cnpj_matriz(s)</b> das <b>lojas buscadas</b>.
                                        </li>
                                        <li>
                                            Se na <b>configuração de Lojas</b> estiver marcado o campo <b>matriz</b>, as <b>lojas</b> exibidas serão as <b>lojas buscadas</b> com campo <b>matriz</b> igual a <b>sim</b>.
                                        </li>
                                    </ul>
                                </v-alert>
                                <v-alert
                                    v-if="index == 2"
                                    class="mt-4"
                                    text
                                    type="info"
                                    border="left"
                                    style="font-size: 12px;"
                                >
                                    <ul>
                                        <li>
                                            Se o passo <b>Selecionar Filiais</b> estiver ativo na <b>configuração de Lojas</b> deve estar selecionados o campo <b>cnpj_matriz</b> ou os campos <b>matriz e filial</b>.
                                        </li>
                                        <li>
                                            Se o passo <b>Selecionar Filiais</b> estiver ativo e na <b>configuração de Lojas</b> estiver marcado o campo <b>cnpj_matriz</b>, as filiais exibidas serão as <b>filiais</b> com <b>cnpj_matriz</b> da <b>loja (Matriz)</b> selecionada.
                                        </li>
                                        <li>
                                            Se o passo <b>Selecionar Filiais</b> estiver ativo e na <b>configuração de Lojas</b> estiver marcados os campos <b>matriz e filial</b>, as filiais exibidas serão as <b>filiais</b> da bandeira da <b>loja (Matriz)</b> selecionada.
                                        </li>
                                    </ul>
                                </v-alert>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="salvar()"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="atributo.dialogo"
            width="90%"
        >
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Adicionar Atributo
                </v-card-title>
                <v-card-text class="mt-2">
                    <v-form ref="formAtributo">
                        <v-row no-gutters class="mt-4">
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2">
                                <v-text-field
                                    outlined
                                    dense 
                                    disabled
                                    label="ID Passo"
                                    v-model="atributo.obj.id_passo"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2">
                                <v-select
                                    label="Atributo"
                                    :items="atributo.lista.tabela"
                                    v-model="atributo.obj.tabela"
                                    :rules="regra.obrigatorio"
                                    :disabled="atributo.obj.editar"
                                    v-on:change="changeAtributo"
                                    outlined
                                    dense 
                                >
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2"
                                v-show="atributo.headers[atributo.passo].map(e => e.value).includes('tipo')"
                            >
                                <v-select
                                    label="Tipo"
                                    :items="atributo.lista.tipo[atributo.passo].filter(e => 
                                        (!['setor','setor_2','setor_3','setor_4'].includes(atributo.obj.tabela) && e != 'automatico') || 
                                        ['setor','setor_2','setor_3','setor_4'].includes(atributo.obj.tabela)
                                    )"
                                    v-model="atributo.obj.tipo"
                                    :rules="regra.obrigatorio"
                                    outlined
                                    dense 
                                >
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2"
                                v-show="
                                    atributo.headers[atributo.passo].map(e => e.value).includes('origem') && 
                                    !['pagamento', 'tabela_1', 'tabela_2', 'tabela_3', 'tabela_4'].includes(atributo.obj.tabela) &&
                                    (
                                        (
                                            ['campo'].includes(atributo.obj.tipo) &&
                                            this.atributo.lista.origem.length > 0 && 
                                            !lista.tabelasDesdobramentoLoja.includes(atributo.obj.tabela)
                                        ) ||
                                        ['automatico'].includes(atributo.obj.tipo)
                                    )
                                "
                            >
                                <v-select
                                    label="Origem"
                                    :items="atributo.lista.origem"
                                    v-model="atributo.obj.origem"
                                    :rules="regra.origem"
                                    outlined
                                    dense 
                                >
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2"
                                v-show="atributo.headers[atributo.passo].map(e => e.value).includes('valor') && atributo.obj.tipo == 'constante'"
                            >
                                <v-text-field
                                    outlined
                                    dense 
                                    label="Valor"
                                    v-model="atributo.obj.valor"
                                    :rules="regra.valor"
                                ></v-text-field> 
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2"
                                v-show="atributo.headers[atributo.passo].map(e => e.value).includes('busca_inventario_nome')"
                            >
                                <v-select
                                    :items="[{text:'Sim', value: 1},{text:'Não', value: 0}]"
                                    label="Buscar Investimento"
                                    v-model="atributo.obj.busca_inventario"
                                    disabled
                                    outlined
                                    dense 
                                >
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2"
                                v-show="atributo.headers[atributo.passo].map(e => e.value).includes('valida_inventario_nome')"
                            >
                                <v-select
                                    :items="[{text:'Sim', value: 1},{text:'Não', value: 0}]"
                                    label="Validar Investimento"
                                    v-model="atributo.obj.valida_inventario"
                                    disabled
                                    outlined
                                    dense 
                                >
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2"
                                v-show="atributo.headers[atributo.passo].map(e => e.value).includes('valida_faturamento_nome')"
                            >
                                <v-select
                                    :items="[{text:'Sim', value: 1},{text:'Não', value: 0}]"
                                    label="Validar Faturamento"
                                    v-model="atributo.obj.valida_faturamento"
                                    :disabled="!(['Faturamento_produto', 'Faturamento_total'].includes(obj.tipo) && atributo.obj.tipo == 'filtro' && atributo.obj.tabela != 'acao')"
                                    outlined
                                    dense 
                                >
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2"
                                v-show="atributo.headers[atributo.passo].map(e => e.value).includes('proximo') && atributo.obj.fnProximo"
                            >
                                <v-select
                                    label="Proximos Filtros"
                                    :items="atributo.lista.proximo"
                                    v-model="atributo.obj.proximo"
                                    clearable
                                    multiple
                                    outlined
                                    dense 
                                >
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense
                                    label="Ordem"
                                    v-model="atributo.obj.ordem"
                                    :rules="regra.obrigatorio"
                                ></v-text-field> 
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2"
                                v-if="atributo.obj.tabela=='loja'"
                            >
                                <v-select
                                    :items="atributo.lista.exibicao"
                                    label="Tipos de Exibição"
                                    v-model="atributo.obj.exibicao"
                                    :rules="regra.obrigatorio"
                                    outlined
                                    dense 
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-alert
                            v-show="tab == 2"
                            class="mt-4"
                            text
                            type="info"
                            border="left"
                            style="font-size: 12px;"
                        >
                            <ul>
                                <li>
                                    O tipo automatico só busca por hierarquia.
                                </li>
                                <li>
                                    Quando o tipo for automatico o atributo deve ser um setor superior do setor de origem na hierarquia.
                                </li>
                            </ul>
                        </v-alert>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="atributo.dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="salvarAtributo()"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="remover.dialogo"
            width="600"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{remover.titulo}}
                </v-card-title>
                <v-card-text class="mt-4"> 
                    {{remover.texto}}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="remover.dialogo=false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="error"
                        class="pr-4"
                        @click="removerAtributo()"
                    >
                        <v-icon left>mdi-close</v-icon> Remover
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        
        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn
                dark
                text
                class="float-right"
                @click="alerta=false"
            >
            Fechar
            </v-btn>
        </v-snackbar>

    </v-container>
</template>

<script>

export default {
    data() {
        return {
            tab: null,
            headers: [
                { text: 'ID Modelo', value: 'id_modelo', align: 'center'},
                { text: 'Modelo', value: 'nome', align: 'center'},
                { text: 'Tipo', value: 'tipo', align: 'center', sortable: false },
                { text: 'ID Desdobramento', value: 'id_desdobramento', align: 'center'},
                { text: 'Desdobramento', value: 'desdobramento', align: 'center'},
                { text: 'Limite inicial do período', value: 'mes_limite_nome', align: 'center'},
                { text: 'Ordem', value: 'ordem', align: 'center'},
                { text: '% de Execução', value: 'percentual_executado', align: 'center'},
                { text: 'Validação do Investimento', value: 'validacao_investimento', align: 'center'},
                { text: 'Ativo', value: 'ativo_nome', align: 'center'},
                { text: '', value: 'editar', align: 'center', sortable: false },
            ],
            dados: [],
            lista: {
                mes_limite: [],
                tabelasDesdobramento: [],
                tipos: [
                    { text: 'Planejado', value: 'Planejado'},
                    { text: 'Faturamento com seleção de produtos', value: 'Faturamento'},
                    { text: 'Faturamento por produto', value: 'Faturamento_produto'},
                    { text: 'Faturamento total', value: 'Faturamento_total'},
                ],
            },
            passos: [
                'Período do Acordo',
                'Cadastro de Acordo',
                'Selecionar Filiais',
                'Investimento',
            ],
            objNovo: {
                id_modelo: null,
                nome: null,
                tipo: null,
                id_desdobramento: null,
                mes_limite: null,
                ativo: 1,
                percentual_executado: 100,
                validacao_investimento: 'Anual',
                investimento_zero: true,
                ocultar_disponivel: false,
                historico_investimento: false,
                passo: [
                    {obs: false, obs_nome: 'Observação', ativo: 1, passo_tabela: [], data: false, data_nome: 'Data'},
                    {obs: false, obs_nome: 'Observação', ativo: 1, passo_tabela: []},
                    {obs: false, obs_nome: 'Observação', ativo: 1, passo_tabela: []},
                    {obs: false, obs_nome: 'Observação', ativo: 1, passo_tabela: []},
                ]
            },
            obj: {},
            pagination: {},
            total: null,
            carregando: false, 
            ativo: 1,
            dialogo: false,
            atributo: {
                headers: [
                    [],
                    [
                        { text: 'ID Passo', value: 'id_passo', align: 'center'},
                        { text: 'Atributo', value: 'nome', align: 'center'},
                        { text: 'Cargo', value: 'cargo', align: 'center'},
                        { text: 'Tipo', value: 'tipo', align: 'center'},
                        { text: 'Origem', value: 'origem', align: 'center'},
                        { text: 'Valor', value: 'valor', align: 'center'},
                        { text: 'Buscar Investimento', value: 'busca_inventario_nome', align: 'center'},
                        { text: 'Validar Investimento', value: 'valida_inventario_nome', align: 'center'},
                        { text: 'Proximos Filtros', value: 'proximo', align: 'center'},
                        { text: 'Ordem', value: 'ordem', align: 'center'},
                        { text: '', value: 'editar', align: 'center', sortable: false },
                        { text: '', value: 'remover', align: 'center', sortable: false },
                    ],
                    [
                        { text: 'ID Passo', value: 'id_passo', align: 'center'},
                        { text: 'Atributo', value: 'nome', align: 'center'},
                        { text: 'Ordem', value: 'ordem', align: 'center'},
                        { text: '', value: 'editar', align: 'center', sortable: false },
                        { text: '', value: 'remover', align: 'center', sortable: false },
                    ],
                    [
                        { text: 'ID Passo', value: 'id_passo', align: 'center'},
                        { text: 'Atributo', value: 'nome', align: 'center'},
                        { text: 'Buscar Investimento', value: 'busca_inventario_nome', align: 'center'},
                        { text: 'Validar Investimento', value: 'valida_inventario_nome', align: 'center'},
                        { text: 'Validar Faturamento', value: 'valida_faturamento_nome', align: 'center'},
                        { text: 'Ordem', value: 'ordem', align: 'center'},
                        { text: '', value: 'editar', align: 'center', sortable: false },
                        { text: '', value: 'remover', align: 'center', sortable: false },
                    ],
                ],
                pagination: {},
                dialogo: false,
                objNovo: {
                    id_passo: null,
                    tabela: null,
                    nome: null,
                    tipo: null,
                    origem: null,
                    valor: null,
                    busca_inventario: 0,
                    valida_inventario: 0,
                    valida_faturamento: 0,
                    proximo: null,
                    ordem: null,
                    editar: false,
                    fnProximo: false,
                    exibicao: null,
                },
                obj: {},
                passo: 0,
                lista: {
                    tabela: [],
                    tipo: [
                        [],
                        ['campo', 'constante', 'origem', 'automatico'],
                        ['filial'],
                        ['filtro'],
                    ],
                    origem: [],
                    proximo: [],
                    exibicao: [
                        'ID Loja - CNPJ - Razão Social',
                        'CNPJ Matriz / CNPJ Filiais / Vendedor',
                    ],
                },
            },
            remover: {
                dialogo: false,
                titulo: null,
                texto: null
            },
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            regra: { 
                obrigatorio: [v => (!!v || v == 0) || 'Campo obrigatório.'],
                percentual_executado: [
                    v => !!v || 'Campo obrigatório.',
                    v => !v || v >= 0 && v <= 100 || 'O percentual deve ser um valor entre 0 e 100.',
                ],
                nome: [
                    v => !!v || 'Campo obrigatório.',
                    v => !v || /^[a-z0-9]+$/.test(v) || 'O campo cliente so pode ter letras minusculas e números.',
                    v => !v || /^[a-z]/.test(v) || 'O campo cliente deve começar com uma letras minuscula.',
                ],
                valor: [
                    v => this.atributo.obj.tipo != 'constante' || !!v || 'O valor é obrigatório para atributos constantes.',
                ],
                origem: [
                    v => ['pagamento', 'tabela_1', 'tabela_2', 'tabela_3', 'tabela_4'].includes(this.atributo.obj.tabela) || this.atributo.obj.tipo != 'campo' || this.lista.tabelasDesdobramentoLoja.includes(this.atributo.obj.tabela) || !!v || 'A origem é obrigatória para atributos fora do desdobramento e diferentes de loja ou pagamento.',
                ],
            },
        }
    },
    watch: {
        pagination: {
            handler () {
                this.filtrar()
            },
            deep: true,
        },
        ativo() {

            this.filtrar()
        },
    },
    async created() {

        this.obj = {...this.objNovo}

        this.lista.desdobramento = ( await this.$http().post(`/desdobramento/ultimoNivel`, {data: {}}) ).data

        for (const m of this.lista.desdobramento) {
            
            m.desdobramento = ( await this.$http().post(`/planejamento/desdobramento`, {data:  {id_desdobramento: m.id_desdobramento}}) ).data
        }

        for (let m = -12; m <= 12; m++) {

            let nome = ''

            if(m > 1) nome = m + ' meses'
            if(m == 1) nome = '1 mês'
            if(m == 0) nome = 'Mês atual'
            if(m == -1) nome = '1 mês retroativo'
            if(m < -1) nome = Math.abs(m) + ' meses retroativos'

            this.lista.mes_limite.push({value: m, text: nome})
        }

        this.lista.tabela = ( await this.$http().post('/configuracao/tabelas', {data: {}}) ).data

        this.lista.loja = ( await this.$http().post('/configuracao/lista', {data: {tabela: 'loja'}}) ).data.lista

        this.lista.produto = ( await this.$http().post('/configuracao/lista', {data: {tabela: 'produto'}}) ).data.lista

        this.lista.produto = this.lista.produto.filter(e => e.ativo && e.ad.substr(0, 3) == 'id_').map(e => e.ad.substr(3))
    },
    methods: {
        async filtrar() {

            this.carregando = true

            var data = {}

            data.pagination = this.pagination

            data.filtro = {}
            data.filtro.ativo = this.ativo == null ? [] : [this.ativo]

            var response = await this.$http().post(`/modelo/lista`, {data: data})
            
            this.dados = response.data
            this.total = response.data.length ? response.data[0].total : 0

            if(this.total == 0 && this.pagination.page > 1) {

                this.pagination.page = 1
                this.filtrar(false)
            }

            this.carregando = false
        },
        novo() {
           
            this.obj = {...this.objNovo}

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }

            this.dialogo = true
        },
        editar(item) {
           
            this.obj = {...item}

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }
            
            this.dialogo = true
        }, 
        async salvar() {

            for (const key in this.obj) {

                if (typeof this.obj[key] == 'string') {

                    this.obj[key] = this.obj[key].trim()
                }
            }
            
            if(!this.$refs.form.validate()) return
            
            this.carregando = true
            
            var data = this.obj

            var response = await this.$http().post(`/modelo/salvar`, {data: data})

            this.carregando = false

            if(response.data.sucesso){

                this.filtrar()
                this.dialogo = false
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }
        },
        passoAtributos(passo) {

            this.atributo.passo = passo

            this.atributo.lista.tabela = []

            this.atributo.lista.origem = []

            this.atributo.lista.proximo = []

            this.lista.tabelasDesdobramento = this.lista.desdobramento.find(e => e.id_desdobramento == this.obj.id_desdobramento).desdobramento.tabelas.filter(e => !e.fn_fake).map(e => e.tabela)

            this.lista.tabelasDesdobramentoLoja = this.lista.desdobramento.find(e => e.id_desdobramento == this.obj.id_desdobramento).desdobramento.tabelas.filter(e => !e.fn_fake).map(e => e.tabela)
            this.lista.tabelasDesdobramentoLoja.push('loja')

            let atributos = this.obj.passo[passo].passo_tabela.map(e => e.tabela)

            if (passo == 1) {

                this.atributo.lista.tabela = this.lista.tabela.filter(e => e.fn_atributo == 1 && !['marca', 'produto', 'acao'].includes(e.tabela))

                let origem = this.obj.passo[passo].passo_tabela.filter(e => e.tipo == 'origem').map(e => e.tabela)

                this.atributo.lista.origem = this.lista.tabela.filter(e => origem.includes(e.tabela) || atributos.filter(e => this.lista.tabelasDesdobramento.includes(e)).includes(e.tabela)).map(e => ({value: e.tabela, text: e.nome}))

                //se não houver origem não pode ter atributo fora do desdobramento
                if (this.atributo.lista.origem.length == 0) {

                    this.atributo.lista.tabela = this.atributo.lista.tabela.filter(e => this.lista.tabelasDesdobramento.includes(e.tabela)).map(e => ({value: e.tabela, text: e.nome}))
                }
                else {

                    this.atributo.lista.tabela = [
                        ...this.atributo.lista.tabela.filter(e => (e.public_tabela == 'setor' && e.fn_loja == '1') || e.public_tabela != 'setor').map(e => ({value: e.tabela, text: e.nome})),
                        ...this.atributo.lista.tabela.filter(e => this.lista.tabelasDesdobramento.includes(e.tabela)).map(e => ({value: e.tabela, text: e.nome}))
                    ]
                }

                this.atributo.lista.proximo = this.lista.tabela.filter(e => e.fn_atributo == 1 && !['marca', 'produto', 'acao'].includes(e.tabela)).map(e => ({value: e.tabela, text: e.nome}))

                this.atributo.lista.proximo = this.atributo.lista.proximo.filter(e => atributos.includes(e.value))

                this.atributo.lista.proximo = this.atributo.lista.proximo.filter(e => atributos.findIndex(a => a == e.value) > atributos.findIndex(a => a == this.atributo.obj.tabela) )
            }

            if (passo == 2) {

                this.atributo.lista.tabela = this.lista.loja.filter(e => e.ativo && !['cnpj_matriz', 'matriz', 'filial'].includes(e.ad)).map(e => ({value: e.ad, text: e.nome}))
            }

            if (passo == 3) {

                if (this.obj.tipo == 'Faturamento') {

                    this.atributo.lista.tabela = this.lista.tabela.filter(e => ['acao'].includes(e.tabela)).map(e => ({value: e.tabela, text: e.nome}))
                }
                else {

                    this.atributo.lista.tabela = this.lista.tabela.filter(e => [...['marca', 'produto', 'acao'], ...this.lista.produto].includes(e.tabela)).map(e => ({value: e.tabela, text: e.nome}))
                }
            }

            this.atributo.obj.fnProximo = false

            if (!this.atributo.obj.editar) {
                
                this.atributo.lista.tabela = this.atributo.lista.tabela.filter(e => !atributos.includes(e.value))
            }
            else {

                if (this.lista.tabelasDesdobramento.includes(this.atributo.obj.tabela) || this.atributo.obj.origem) {

                    this.atributo.obj.fnProximo = true
                }
            }
        },
        novoAtributo(passo) {

            this.atributo.obj = {...this.atributo.objNovo}

            this.passoAtributos(passo)

            this.atributo.obj.id_passo = this.obj.passo[passo].id_passo

            if (passo == 2) {

                this.atributo.obj.busca_inventario = 0
                this.atributo.obj.valida_inventario = 0
            }

            if (passo == 2) {

                this.atributo.obj.tipo = 'filial'
                this.atributo.obj.busca_inventario = 0
                this.atributo.obj.valida_inventario = 0
            }

            if (passo == 3) {

                this.atributo.obj.tipo = 'filtro'
                this.atributo.obj.busca_inventario = 1
                this.atributo.obj.valida_inventario = 0
            }

            try {
                this.$refs.formAtributo.resetValidation()
            } catch (error) {
                //console.log(error)
            }  

            this.atributo.dialogo = true
        },
        changeAtributo() {

            if (this.atributo.passo == 1 && this.lista.tabelasDesdobramento.includes(this.atributo.obj.tabela)) {

                this.atributo.obj.busca_inventario = 1
                this.atributo.obj.valida_inventario = 1
            }
            if (this.atributo.passo == 1 && !this.lista.tabelasDesdobramento.includes(this.atributo.obj.tabela)) {

                this.atributo.obj.busca_inventario = 0
                this.atributo.obj.valida_inventario = 0
            }

            if (this.atributo.passo == 1 && this.atributo.obj.tabela == 'loja') {

                this.atributo.obj.exibicao = 'ID Loja - CNPJ - Razão Social'
            }
            else {

                this.atributo.obj.exibicao = null
            }

            if (this.atributo.passo == 3 && this.lista.tabelasDesdobramento.includes(this.atributo.obj.tabela)) {

                this.atributo.obj.valida_inventario = 1
            }
            if (this.atributo.passo == 3 && !this.lista.tabelasDesdobramento.includes(this.atributo.obj.tabela)) {

                this.atributo.obj.valida_inventario = 0
            }
        },
        editarAtributo(atributo, passo) {

            this.atributo.obj = {...atributo}

            this.atributo.obj.editar = true

            this.passoAtributos(passo)

            try {
                this.$refs.formAtributo.resetValidation()
            } catch (error) {
                //console.log(error)
            }  

            this.atributo.dialogo = true
        },
        async salvarAtributo() {

            if(!this.$refs.formAtributo.validate()) return

            this.carregando = true
            
            var data = this.atributo.obj

            var response = await this.$http().post(`/modelo/salvarAtributo`, {data: data})

            this.filtrar()

            setInterval(() => {
                
                this.carregando = true

                this.obj = {...this.dados.find(e => e.id_modelo == this.obj.id_modelo)}

                this.carregando = false
            }, 100)

            if(response.data.sucesso){

                this.atributo.dialogo = false
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }
        },
        removerAtributoConfirm(atributo) {

            this.atributo.obj = {...atributo}

            this.remover.titulo = 'Remover Atributo'
            this.remover.texto = `Deseja realmente remover o atributo '${atributo.nome}'?`
            
            this.remover.dialogo = true
        }, 
        async removerAtributo() {

            this.carregando = true

            var data = this.atributo.obj

            var response = await this.$http().post(`/modelo/removerAtributo`, {data: data})
            
            this.filtrar()

            setInterval(() => {
                
                this.carregando = true

                this.obj = {...this.dados.find(e => e.id_modelo == this.obj.id_modelo)}

                this.carregando = false
            }, 100)
            
            this.carregando = false

            if(response.data.sucesso){

                this.remover.dialogo = false
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }
        }, 
    },
}
</script>