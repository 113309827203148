<template>
    <v-container fluid>

        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <div
                    class="botao_filtro rounded"
                    @click="miniFiltros"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon 
                        color="white"
                        dense
                    >mdi-filter</v-icon>
                </div>
            </template>
            <span>Exibir Filtros</span>
        </v-tooltip>

        <v-navigation-drawer
            v-model="menuFiltros"
            :permanent="menuFiltros"
            style="position: fixed; top: 65px;"
            right
            app
            width="350"
        >
            <v-list-item 
                @click.stop="miniFiltros"
                class="px-4"
            >
                <v-list-item-title>Filtros</v-list-item-title>
                <v-icon>mdi-chevron-right</v-icon>
            </v-list-item>
            <v-divider></v-divider>
            <v-col cols="12" class="px-2 ml-auto" align="end">
                <v-btn
                    class="ml-2 mb-1"
                    color="primary"
                    @click="limpar()"
                >
                    <v-icon left>mdi-filter-off</v-icon> Limpar
                </v-btn> 
                <v-btn
                    class="ml-2 mb-1"
                    color="primary"
                    @click="filtrar()"
                >
                    <v-icon left>mdi-filter</v-icon> Filtrar
                </v-btn>
            </v-col>
            <v-form ref="formFiltro">
                <v-row no-gutters class="mt-2">
                    <v-col cols="12" class="px-2">
                        <v-select
                            label="Ano"
                            :items="lista.anos"
                            v-model="filtro.ano"
                            dense 
                            outlined
                        >
                        </v-select>
                    </v-col>
                    <v-col v-for="(obj, index) in lista.filtro" :key="index" cols="12" class="px-2">
                        <v-autocomplete
                            :label="obj.nome"
                            :items="lista[obj.tabela]"
                            v-model="filtro[obj.var_id]"
                            outlined
                            dense
                            multiple
                            clearable
                            chips
                            small-chips
                            deletable-chips
                        >
                            <template v-slot:prepend-item>
                                <v-list-item
                                ripple
                                @mousedown.prevent
                                @click="selecionarTudo(obj.var_id, obj.tabela)"
                                >
                                <v-list-item-action>
                                    <v-icon :color="filtro[obj.var_id].length > 0 ? 'indigo darken-4' : ''">
                                    {{ iconeSelecionarTudo(obj.var_id, obj.tabela) }}
                                    </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                    Selecionar Tudo
                                    </v-list-item-title>
                                </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                            </template>
                            <template v-slot:selection="{item}">
                                <v-chip
                                    class="mt-1"
                                    pill
                                    small
                                    :title="item.text"
                                >
                                    <v-icon 
                                        left
                                        small
                                        @click="removeItemFiltro(item, filtro[obj.var_id])"
                                    >mdi-close-circle</v-icon>
                                    {{ item.text }}
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </v-form>
            <br>
            <br>
        </v-navigation-drawer>

        <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-toolbar-title class="ml-2 mt-2">Planejado / Investimento</v-toolbar-title>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">
                <v-btn
                    class="mt-2 ml-2 my-1"
                    color="primary" 
                    @click="filtroDetalhado.indicador='planejado_completo'; exportar();"  
                >
                    <v-icon left>mdi-microsoft-powerpoint</v-icon> Exporta Planejado
                </v-btn>
                <v-btn
                    class="mt-2 ml-2 my-1"
                    color="primary"
                    @click="filtroDetalhado.indicador='investimento'; exportar()"
                >
                    <v-icon left>mdi-microsoft-excel</v-icon> Exportar Investimento
                </v-btn>
                <v-btn
                    v-if="fnFaturamento"
                    class="mt-2 ml-2 my-1"
                    color="primary"
                    @click="filtroDetalhado.indicador='faturamento'; exportar()"
                >
                    <v-icon left>mdi-microsoft-excel</v-icon> Exportar Faturamento
                </v-btn>
            </v-col>
        </v-row>

        <v-row no-gutters>
            <div
                v-for="indicador in indicadoresTotal"
                :key="indicador.nome"
            >
                <v-tooltip top :color="indicador.cor" v-if="indicador.filtro">
                    <template v-slot:activator="{ on, attrs }">
                        <v-card
                            :class="'elevation-1 mt-3 indicadoresCartao' + (indicador.ml ? indicador.ml : '') + (indicador.mr ? indicador.mr : '')"
                            :color="indicador.cor"
                            v-bind="attrs"
                            v-on="on"
                            @click="filtroIndicadore(indicador.filtro)"
                        >
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <div 
                                        class="indicadoresTitulo"
                                        v-bind="attrs"
                                        v-on="on"
                                    ><v-icon dark small>mdi-filter</v-icon> {{indicador.nome}}</div>
                                </template>
                                <span>{{indicador.desc}}</span>
                            </v-tooltip>
                            <div 
                                class="indicadoresValor"
                            >{{(indicador.valor*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</div>
                            <v-avatar 
                                class="indicadoresIcone"
                                size="46"
                                :color="indicador.cor2 ? indicador.cor2 : indicador.cor + ' darken-1'"
                            >
                                <v-icon dark>{{indicador.icone}}</v-icon>
                            </v-avatar>
                            <v-progress-linear
                                v-show="indicador.percSufixo"
                                :value="indicador.perc"
                                color="#fff"
                                height="12"
                                class="indicadoresPercemtualBarra"
                            ></v-progress-linear>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <div 
                                        v-show="indicador.percSufixo"
                                        class="indicadoresPercemtualValor"
                                        v-bind="attrs"
                                        v-on="on"
                                    >{{indicador.percNome}} ({{indicador.perc}}%)</div>
                                </template>
                                <span>{{indicador.percDesc}}</span>
                            </v-tooltip>
                        </v-card>
                    </template>
                    <span>{{indicador.filtroTitulo}}</span>
                </v-tooltip>
                <v-card
                    :class="'elevation-1 mt-3 indicadoresCartao' + (indicador.ml ? indicador.ml : '') + (indicador.mr ? indicador.mr : '')"
                    :color="indicador.cor"
                    v-else
                >
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <div 
                                class="indicadoresTitulo"
                                v-bind="attrs"
                                v-on="on"
                            >{{indicador.nome}}</div>
                        </template>
                        <span>{{indicador.desc}}</span>
                    </v-tooltip>
                    <div 
                        class="indicadoresValor"
                    >{{(indicador.valor*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</div>
                    <v-avatar 
                        class="indicadoresIcone"
                        size="46"
                        :color="indicador.cor2 ? indicador.cor2 : indicador.cor + ' darken-1'"
                    >
                        <v-icon dark>{{indicador.icone}}</v-icon>
                    </v-avatar>
                    <v-progress-linear
                        v-show="indicador.percSufixo"
                        :value="indicador.perc"
                        color="#fff"
                        height="12"
                        class="indicadoresPercemtualBarra"
                    ></v-progress-linear>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <div 
                                v-show="indicador.percSufixo"
                                class="indicadoresPercemtualValor"
                                v-bind="attrs"
                                v-on="on"
                            >{{indicador.percNome}} ({{indicador.perc}}%)</div>
                        </template>
                        <span>{{indicador.percDesc}}</span>
                    </v-tooltip>
                </v-card>
            </div>
            <v-col cols="12">
                <v-card class="mt-4 px-2 pt-2" elevation="1">
                    <v-select
                        style="position: absolute; top: 10px; left: 10px; z-index: 2; width: 200px;" 
                        :items="periodos"
                        v-model="periodo"
                        v-on:change="setaMeses()"
                        label="Periodo"
                        flat solo
                        hide-details
                    >
                        <template v-slot:selection="{item}" class="highcharts-title">
                           <span class="highcharts-title" :style="`font-size: 18px; color: ${$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e'};`"> {{ item.text }}</span>
                        </template>
                    </v-select>
                    <highcharts :options="graficoMeses" ref="grafico1"></highcharts>
                </v-card>
            </v-col>
            <v-col cols="12" sm="12" :md="layoutCompacto?6:12" :lg="layoutCompacto?6:12" :xl="layoutCompacto?6:12">
                <v-card class="mt-4 px-2 pt-2" elevation="1"> 
                    <v-icon 
                        style="position: absolute; top: 10px; left: 10px; z-index: 2" 
                        left
                        :color="layoutCompacto?'primary':''"
                        @click="mudaLayout()"
                        title="Layout compacto"
                    >mdi-view-compact</v-icon>
                    <v-icon 
                        style="position: absolute; top: 10px; left: 50px; z-index: 2" 
                        left
                        :color="percentualEstados ? 'primary' : ''"
                        @click="percentualEstados = !percentualEstados; setaEstatos();"
                        title="Exibir percentual / quantidade"
                        v-if="indicadoresEstado.find(e => e.value == indicadorEstado).tipo=='moeda'"
                    >mdi-label-percent</v-icon>
                    <v-select
                        style="position: absolute; top: 0px; left: 50%;  margin-left: -110px; z-index: 2; width: 280px;" 
                        :items="indicadoresEstado"
                        v-model="indicadorEstado"
                        v-on:change="setaEstatos();"
                        label="Indicador"
                        flat solo 
                        v-if="fnFaturamento"
                    >
                        <template v-slot:selection="{item}" class="highcharts-title">
                            <span class="highcharts-title" :style="`font-size: 18px; color: ${$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e'};`">
                                {{ (percentualEstados && item.tipo == 'moeda' ? 'Peso ' : '') + item.text + (percentualEstados && item.tipo == 'moeda' ? ' (%)' : '') }}
                            </span>
                        </template>
                    </v-select>
                    <highcharts :constructorType="'mapChart'" class="hc" :options="graficoEstados" ref="grafico2"></highcharts>
                </v-card>
            </v-col>
            <v-col cols="12" sm="12" :md="layoutCompacto?6:12" :lg="layoutCompacto?6:12" :xl="layoutCompacto?6:12">
                <v-card class="mt-4 px-2 pt-2" :class="layoutCompacto?'ml-4':''" elevation="1"> 
                    <v-icon 
                        style="position: absolute; top: 10px; left: 10px; z-index: 2" 
                        left
                        :color="layoutCompacto?'primary':''"
                        @click="mudaLayout()"
                        title="Layout compacto"
                    >mdi-view-compact</v-icon>
                    <v-select
                        style="position: absolute; top: 10px; left: 0px; z-index: 2; width: 45%;" 
                        :items="indicadores"
                        v-model="indicador"
                        v-on:change="setaTop()"
                        label="Indicador"
                        flat solo
                        hide-details
                    >
                        <template v-slot:selection="{item}" class="highcharts-title">
                           <span class="highcharts-title" :style="`font-size: 18px; color: ${$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e'};`">{{ item.text }}</span>
                        </template>
                    </v-select>
                    <v-select
                        style="position: absolute; top: 10px; left: 45%; z-index: 2; width: 45%;" 
                        :items="lista.filtro ? lista.filtro.filter(e => e.tabela != 'meses').map(e => ({value: e.tabela, text: e.plural})) : []"
                        v-model="topEixo"
                        v-on:change="setaTop(true)"
                        label="Eixo"
                        flat solo
                        hide-details
                    >
                        <template v-slot:selection="{item}" class="highcharts-title">
                           <span class="highcharts-title" :style="`font-size: 18px; color: ${$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e'};`">Top {{ item.text }}</span>
                        </template>
                    </v-select>
                    <highcharts :options="graficoTop" ref="grafico3"></highcharts>
                </v-card>
            </v-col>
        </v-row>
       
        <v-card class="my-4 px-2 pt-2 pb-2" elevation="1">
            <v-row no-gutters>
                <v-col cols="4">
                    <v-select
                        :items="lista.filtro ? lista.filtro.filter(e => e.tabela != 'meses').map(e => ({value: e.tabela, text: e.plural})) : []"
                        v-model="grupo1"
                        flat solo
                        hide-details
                    >
                        <template v-slot:selection="{item}" class="highcharts-title">
                        <span class="highcharts-title" :style="`font-size: 18px; color: ${$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e'};`">{{ item.text }}</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="4">
                    <v-select
                        :items="lista.filtro ? lista.filtro.filter(e => e.tabela != 'meses').map(e => ({value: e.tabela, text: e.plural})) : []"
                        v-model="grupo2"
                        flat solo
                        hide-details
                    >
                        <template v-slot:selection="{item}" class="highcharts-title">
                        <span class="highcharts-title" :style="`font-size: 18px; color: ${$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e'};`">{{ item.text }}</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="2" align="end">
                    <v-btn
                        color="primary"
                        @click="setaGrupo(true)"
                    >
                        <v-icon left>mdi-refresh</v-icon> Atualizar
                    </v-btn>
                </v-col>
                <v-col cols="2" align="end">
                    <download-csv
                        :data="grupoCsv.dados"
                        :name="grupoCsv.arquivo"
                        :labels="grupoCsv.label"
                        :fields="grupoCsv.colunas"
                        delimiter=";"
                    >
                        <v-btn
                            class="ml-2"
                            color="primary"
                        >
                            <v-icon left>mdi-microsoft-excel</v-icon> Exportar
                        </v-btn>
                    </download-csv>
                </v-col>
            </v-row>
            <v-data-table
                :headers="headersGrupo"
                :items="dadosGrupo" 
                item-key="chave"
                group-by="grupoOrdem"
                class="elevation-0"
                hide-default-footer
                disable-pagination
                :sort-by.sync="sortByGrupo"
                :sort-desc.sync="sortDescGrupo"
            >
                <template v-slot:[`group.header`]="{items, isOpen, toggle}">
                    <td class="text-center grupos" :data-open="isOpen" @click="toggle" style="cursor: pointer">
                        {{ items[0].grupo }}
                        <v-icon>{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                    </td>
                    <td class="text-center" @click="toggle" style="cursor: pointer">
                    </td>
                    <td v-for="(indicador) in headersGrupo.filter(e => e.tipo)" :key="indicador.value" class="text-center" @click="toggle" style="cursor: pointer">
                        <div v-if="indicador.tipo == 'percentual'">{{ items[0][indicador.value+'_total'] }}%</div>
                        <div v-if="indicador.tipo == 'moeda'">{{ items[0][indicador.value+'_total'].toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}</div>
                    </td>
                </template>
                <template v-slot:[`item.grupo`]="{ }"> 
                    <v-icon
                        small
                        class="mr-2"
                        color="primary"
                    >
                        mdi-subdirectory-arrow-right
                    </v-icon>
                </template>
                <template v-for="(indicador) in headersGrupo.filter(e => e.tipo)" v-slot:[`item.${indicador.value}`]="{ item }" > 
                    <div :key="indicador.value" v-if="indicador.tipo == 'percentual'">
                        {{ item[indicador.value] }}%
                    </div>
                    <div :key="indicador.value" v-if="indicador.tipo == 'moeda'">
                        {{ item[indicador.value].toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
                    </div>
                </template>
            </v-data-table>
        </v-card>
        
        <v-dialog
            v-model="dialogoDetalhado"
            width="95%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Detalhado
                </v-card-title>
                <v-row no-gutters class="mx-2">
                    <v-col cols="6">
                    </v-col>
                    <v-col cols="6" align="end"  class="py-2">  
                        <v-btn
                            class="ml-2 my-1"
                            color="primary" 
                            @click="exportar({fnDetalhado: true})"  
                        >
                            <v-icon left>mdi-microsoft-excel</v-icon> Exportar CSV
                        </v-btn>
                    </v-col>
                </v-row>
                <v-data-table
                    :headers="headers"
                    :items="dadosDetalhado"
                    :server-items-length="totalDetalhado" 
                    :items-per-page="5"
                    :footer-props="{
                        'items-per-page-options': [5, 10, 15, 25, 50, 100]
                    }"
                    :options.sync="paginationDetalhado"
                    multi-sort
                    class="elevation-0"
                >
                    <template v-slot:[`item.acordo_modelo_data`]="{ item }">
                        {{item.acordo_modelo_data ? $moment(item.acordo_modelo_data).format('DD/MM/YYYY') : ''}}
                    </template>
                    <template v-slot:[`item.acordo_ad_6`]="{ item }">
                        {{item.acordo_ad_6 ? $moment(item.acordo_ad_6).format('DD/MM/YYYY') : ''}}
                    </template>
                    <template v-slot:[`item.acordo_ad_7`]="{ item }">
                        {{item.acordo_ad_7 ? $moment(item.acordo_ad_7).format('DD/MM/YYYY') : ''}}
                    </template>
                    <template v-slot:[`item.acordo_ad_8`]="{ item }">
                        {{item.acordo_ad_8 ? $moment(item.acordo_ad_8).format('DD/MM/YYYY') : ''}}
                    </template>
                    <template v-slot:[`item.acordo_ad_9`]="{ item }">
                        {{item.acordo_ad_9 ? $moment(item.acordo_ad_9).format('DD/MM/YYYY') : ''}}
                    </template>
                    <template v-slot:[`item.acordo_ad_10`]="{ item }">
                        {{item.acordo_ad_10 ? $moment(item.acordo_ad_10).format('DD/MM/YYYY') : ''}}
                    </template>
                    <template v-slot:[`item.investimento`]="{ item }"> 
                        {{ (item.investimento*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
                    </template>
                    <template v-for="index in 12" v-slot:[`item.mes_${index}`]="{ item }">
                        <div :key="index">
                            {{(item['mes_'+index]*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                        </div>
                    </template>
                    <template v-slot:[`item.total_ano`]="{ item }">
                        {{(item.total_ano*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                    </template>
                    <template v-for="index in 12" v-slot:[`item.mes_${index}_investimento_planejado`]="{ item }">
                        <div :key="index">
                            {{item['mes_'+index+'_investimento_planejado'] + '%'}}
                        </div>
                    </template>
                    <template v-slot:[`item.total_ano_investimento_planejado`]="{ item }">
                        {{item.total_ano_investimento_planejado + '%'}}
                    </template>
                    <template v-for="index in 12" v-slot:[`item.mes_${index}_ainvestir`]="{ item }">
                        <div :key="index">
                            {{(item['mes_'+index+'_ainvestir']*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                        </div>
                    </template>
                    <template v-slot:[`item.total_ano_ainvestir`]="{ item }">
                        {{(item.total_ano_ainvestir*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                    </template>
                    <template v-slot:[`item.valor`]="{ item }"> 
                        {{ (item.valor*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
                    </template>
                    <template v-slot:[`item.valor_calculado`]="{ item }"> 
                        {{ (item.valor_calculado*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
                    </template>
                    <template v-slot:[`item.data`]="{ item }"> 
                        {{item.data_nome}}
                    </template>
                </v-data-table> 
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="dialogoDetalhado = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn
                dark
                text
                class="float-right"
                @click="alerta=false"
            >
            Fechar
            </v-btn>
        </v-snackbar>


    </v-container>
</template>

<script>

import brasilMap from '@highcharts/map-collection/countries/br/br-all.geo.json'

export default {
    data() {
        return {
            meses: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            menuFiltros: null,
            lista: {},
            filtro: {
                ano: new Date().getFullYear(),
            },
            headersInvestimento: [
                { text: 'Tipo de Acordo', value: 'modelo', align: 'center'},
                { text: 'Contrato', value: 'id_contrato', align: 'center'},
                { text: 'Acordo', value: 'id_acordo', align: 'center'},
                { text: 'Mês', value: 'mes_nome', align: 'center'},
                { text: 'Parcela', value: 'parcela', align: 'center'},
                { text: 'Status', value: 'status', align: 'center'},
                { text: 'Setor', value: 'setor_loja', align: 'center'},
                { text: 'Usuário', value: 'usuario_loja', align: 'center'},
                { text: 'Investimento', value: 'investimento', align: 'center'},
            ],
            headersPlanejado: [
                { text: 'Ano', value: 'ano', align: 'center'},
                { text: 'Financeiro', value: 'desdobramento', align: 'center'},
            ],
            headersFaturamento: [
                { text: 'Nota Fiscal', value: 'nota_fiscal', align: 'center'},
                { text: 'Data', value: 'data', align: 'center'},
                { text: 'CNPJ', value: 'cnpj', align: 'center'},
            ],
            headers: [],
            dados: [],
            total: null,
            paginationLimpa: {
                groupBy: [],
                groupDesc: [],
                itemsPerPage: 5,
                multiSort: true,
                mustSort: false,
                page: 1,
                sortBy: [],
                sortDesc: [],
            },
            pagination: {},
            regra: {},
            modelosLista: [],
            carregando: false,
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            fnFaturamento: false,
            indicadoresTotal: [
                {
                    nome: 'PLANEJADO', 
                    valor: 0, 
                    sufixo: '', 
                    desc: 'Total da verba planejada.', 
                    icone: 'mdi-wallet', 
                    cor: 'primary',
                    mr: ' mr-4',
                },
                {
                    nome: 'INVESTIMENTO', 
                    valor: 0, 
                    sufixo: '', 
                    desc: 'Total da verba investida nos acordos comerciais.', 
                    icone: 'mdi-currency-usd', 
                    cor: 'teal', 
                    perc: 0, 
                    percSufixo: 'p', 
                    percNome: 'Investimento vs Planejado', 
                    percDesc: 'Percentual da verba investida nos acordos comerciais (investimento dividido pelo planejado)',
                    mr: ' mr-4',
                },
                {
                    nome: 'A INVESTIR', 
                    valor: 0, 
                    sufixo: '', 
                    desc: 'Total da verba restante para investimento (planejado menos investimento).', 
                    icone: 'mdi-scale-balance', 
                    cor: 'purple',
                    cor2: 'purple darken-2',
                    mr: ' mr-4',
                },
                {
                    nome: 'FATURAMENTO', 
                    valor: 0, 
                    sufixo: '', 
                    desc: 'Total faturado no ano corrente.', 
                    icone: 'mdi-cash-multiple', 
                    cor: 'pink',
                    cor2: 'pink darken-2',
                    mr: ' mr-4',
                },
                {
                    nome: 'REAL', 
                    valor: 0, 
                    sufixo: 'x', 
                    desc: 'Total faturado no ano corrente menos o total investido no ano corrente (faturamento menos investimento).', 
                    icone: 'mdi-cash', 
                    cor: 'green',
                    perc: 0, 
                    percSufixo: 'x_p', 
                    percNome: 'Investimento vs Real', 
                    percDesc: 'Percentual do faturamento real investido nos acordos comerciais (investimento dividido pelo faturamento real)',
                    mr: ' mr-4',
                },
                {
                    nome: 'REAL ANO ANTERIOR', 
                    valor: 0, 
                    sufixo: 'y', 
                    desc: 'Total faturado no ano anterio menos o total investido no ano anterio.', 
                    icone: 'mdi-cash-refund', 
                    cor: 'deep-purple', 
                    perc: 0, 
                    percSufixo: 'y_p', 
                    percNome: 'Crescimento Real', 
                    percDesc: 'Percentual de crescimento do faturamento real do ano corrente em relação oa faturamento real do ano anterio',
                    mr: ' mr-4',
                },
            ],
            layoutCompacto: true,
            planejado: [],
            investimento: [],
            investimentoAnterior: [],
            faturamento: [],
            faturamentoAnterior: [],
            periodos: [
                {value: 1, text: 'Mensal'}, 
                {value: 2, text: 'Bimestral'}, 
                {value: 3, text: 'Trimestral'}, 
                {value: 6, text: 'Semestral'}, 
                {value: 12, text: 'Anual'},
            ],
            periodo: 1,
            legendaVisivel: [
                true,
                true,
                true,
                false,
                false,
                false,
                false,
                false,
            ],
            graficoMeses: {
                credits: {
                    enabled: false
                },
                tooltip: {
                    shared: true,
                    formatter: function () {
                        return this.points.reduce(function (s, point) {
                            return s + '<br/>' + point.series.name + ': ' + (point.series.type == 'line' ? point.y+'%' : point.y.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) )
                        }, '<b>' + this.x + '</b>')
                    },
                },
                title: {
                    text: "Investimento vs Planejado Mensal",
                    style: {
                        color: this.$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e',
                    }
                },
                chart: {
                    type: 'column',
                    backgroundColor: this.$vuetify.theme.dark ? '#1E1E1E' : '#fff',
                },
                yAxis: [
                    {
                        visible: false
                    },
                    {
                        visible: false
                    },
                ],
                plotOptions: {
                    column: {
                        dataLabels: {
                            enabled: true,
                            formatter: function () { return this.point.y.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) },
                            color: this.$vuetify.theme.dark ? '#fff' : null,
                        },
                    },
                    line: {
                        dataLabels: {
                            enabled: true,
                            formatter: function () { return this.point.y + '%' },
                            color: this.$vuetify.theme.dark ? '#fff' : null,
                        },
                    },
                },
                legend: {
                    itemStyle: {
                        color: this.$vuetify.theme.dark ? '#fff' : null,
                    },
                    itemHoverStyle: {
                        color: this.$vuetify.theme.dark ? '#9e9e9e' : null,
                    },
                },
                xAxis: {
                    categories: [],
                    crosshair: true,
                    labels: {
                        style: {
                            color: this.$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e',
                        }
                    },
                },
                series: [
                    {
                        type: 'column',
                        name: 'Planejado',
                        yAxis: 0,
                        data: [],
                        color: this.$vuetify.theme.dark ? this.$vuetify.theme.themes.dark.primary : this.$vuetify.theme.themes.light.primary,
                        borderWidth: 0,
                        cursor: 'pointer',
                        events: {
                            click: ({point}) => {
                                this.filtroDetalhado = {
                                    indicador: 'planejado',
                                    mes: point.category
                                }
                                this.detalhado()
                            }
                        },
                    },
                    {
                        type: 'column',
                        name: 'Investimento',
                        yAxis: 0,
                        data: [],
                        color: '#009688',
                        borderWidth: 0,
                        cursor: 'pointer',
                        events: {
                            click: ({point}) => {
                                this.filtroDetalhado = {
                                    indicador: 'investimento',
                                    mes: point.category
                                }
                                this.detalhado()
                            }
                        },
                    },
                    {
                        type: 'line',
                        name: 'Investimento vs Planejado',
                        yAxis: 1,
                        data: [],
                        color: '#FF5722',
                    },
                    {
                        type: 'column',
                        name: 'Faturamento',
                        yAxis: 0,
                        data: [],
                        color: '#E91E63',
                        borderWidth: 0,
                        cursor: 'pointer',
                        events: {
                            click: ({point}) => {
                                this.filtroDetalhado = {
                                    indicador: 'faturamento',
                                    mes: point.category
                                }
                                this.detalhado()
                            },
                            legendItemClick: () => {

                                this.legendaVisivel[3] = !this.legendaVisivel[3]
                            }
                        },
                    },
                    {
                        type: 'column',
                        name: 'Real',
                        yAxis: 0,
                        data: [],
                        color: '#4CAF50',
                        borderWidth: 0,
                        events: {
                            legendItemClick: () => {

                                this.legendaVisivel[4] = !this.legendaVisivel[4]
                            }
                        },
                    },
                    {
                        type: 'line',
                        name: 'Investimento vs Real',
                        yAxis: 1,
                        data: [],
                        color: '#607D8B',
                        events: {
                            legendItemClick: () => {

                                this.legendaVisivel[5] = !this.legendaVisivel[5]
                            }
                        },
                    },
                    {
                        type: 'column',
                        name: 'Real Ano Anterior',
                        yAxis: 0,
                        data: [],
                        color: '#673AB7',
                        borderWidth: 0,
                        events: {
                            legendItemClick: () => {

                                this.legendaVisivel[6] = !this.legendaVisivel[6]
                            }
                        },
                    },
                    {
                        type: 'line',
                        name: 'Crescimento Real',
                        yAxis: 1,
                        data: [],
                        color: '#00BCD4',
                        events: {
                            legendItemClick: () => {

                                this.legendaVisivel[7] = !this.legendaVisivel[7]
                            }
                        },
                    },
                ]
            },
            dialogoDetalhado: false,
            dadosDetalhado: [], 
            paginationDetalhado: {}, 
            totalDetalhado: null,
            filtroDetalhado: {},
            indicadores: [
                {value: 'planejado', text: 'Planejado', cor: this.$vuetify.theme.dark ? this.$vuetify.theme.themes.dark.primary : this.$vuetify.theme.themes.light.primary, tipo: 'moeda', indicador: 'planejado', fnDetalhado: true},
                {value: 'planejado_p', text: 'Peso Planejado (%)', cor: this.$vuetify.theme.dark ? this.$vuetify.theme.themes.dark.primary : this.$vuetify.theme.themes.light.primary, tipo: 'percentual', indicador: 'planejado', fnDetalhado: true},
                {value: 'investimento', text: 'Investimento', cor: '#009688', tipo: 'moeda', indicador: 'investimento', fnDetalhado: true},
                {value: 'investimento_p', text: 'Peso Investimento (%)', cor: '#009688', tipo: 'percentual', indicador: 'investimento', fnDetalhado: true},
                {value: 'investimento_planejado', text: 'Investimento vs Planejado', cor: '#FF5722', tipo: 'percentual', indicador: 'investimento_planejado', fnDetalhado: true},
                {value: 'ainvestir', text: 'A Investir', cor: '#9C27B0', tipo: 'moeda', indicador: 'ainvestir', fnDetalhado: true},
                {value: 'faturamento', text: 'Faturamento', cor: '#E91E63', tipo: 'moeda', indicador: 'faturamento', fnDetalhado: true},
                {value: 'real', text: 'Real', cor: '#4CAF50', tipo: 'moeda', indicador: 'real', fnDetalhado: false},
                {value: 'investimento_real', text: 'Investimento vs Real', cor: '#607D8B', tipo: 'percentual', indicador: 'investimento_real', fnDetalhado: false},
                {value: 'realAnterior', text: 'Real Ano Anterior', cor: '#673AB7', tipo: 'moeda', indicador: 'realAnterior', fnDetalhado: false},
                {value: 'realCrescimento', text: 'Crescimento Real', cor: '#00BCD4', tipo: 'percentual', indicador: 'realCrescimento', fnDetalhado: false},
            ],
            indicadoresEstado: [
                {value: 'investimento', text: 'Investimento', cor: '#009688', corMi: '#E0F2F1', tipo: 'moeda', indicador: 'investimento', fnDetalhado: true},
                {value: 'faturamento', text: 'Faturamento', cor: '#E91E63', corMi: '#fac7d8', tipo: 'moeda', indicador: 'faturamento', fnDetalhado: true},
                {value: 'real', text: 'Real', cor: '#4CAF50', corMi: '#d2ebd3', tipo: 'moeda', indicador: 'real', fnDetalhado: false},
                {value: 'investimento_real', text: 'Investimento vs Real (%)', cor: '#607D8B', corMi: '#d7dfe2', tipo: 'percentual', indicador: 'investimento_real', fnDetalhado: false},
                {value: 'realAnterior', text: 'Real Ano Anterior', cor: '#673AB7', corMi: '#d9ceed', tipo: 'moeda', indicador: 'realAnterior', fnDetalhado: false},
                {value: 'realCrescimento', text: 'Crescimento Real (%)', cor: '#00BCD4', corMi: '#bfeef4', tipo: 'percentual', indicador: 'realCrescimento', fnDetalhado: false},
            ],
            indicador: 'planejado',
            indicadorEstado: 'investimento',
            estados: {},
            percentualEstados: true,
            graficoEstados: {
                chart: {
                    map: brasilMap,
                    height: 550,
                    backgroundColor: this.$vuetify.theme.dark ? '#1E1E1E' : '#fff',
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: "Peso Investimento (%)",
                    style: {
                        color: this.$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e',
                    }
                },
                tooltip: {
                    formatter: function () {
                        return '<b>' + this.series.name + '</b><br>' + 
                        this.point.name + ': ' + 
                        (this.series.name.substr(-3) == '(%)' ? this.point.value + '%' : this.point.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})) 
                    }
                },
                mapNavigation: {
                    enabled: true,
                },
                colorAxis: {
                    minColor: '#E0F2F1',
                    maxColor: '#009688',
                    labels: {
                        format: '{value}%',
                        style: {
                            color: this.$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e',
                        }
                    },
                },
                series: [{
                    name: 'Peso Investimento (%)',
                    states: {
                        hover: {
                            color: '#18FFFF'
                        }
                    },
                    allAreas: true,
                    data: [],
                    cursor: 'pointer',
                    events: {
                        click: ({point}) => {
                            this.filtroDetalhado = {
                                indicador: 'indicadorEstado',
                                estado: point["hc-key"]
                            }
                            this.detalhado()
                        }
                    }
                }]
            },
            topEixo: 'marca',
            top: {},
            graficoTop: {
                credits: {
                    enabled: false
                },
                tooltip: {
                    enabled: false
                },
                title: {
                    text: "Planejado Top Produtos",
                    style: {
                        color: this.$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e',
                    }
                },
                chart: {
                    type: 'bar',
                    height: 550,
                    backgroundColor: this.$vuetify.theme.dark ? '#1E1E1E' : '#fff',
                },
                yAxis: {
                    visible: false
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            enabled: true,
                            formatter: function () { return this.point.y.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) },
                            color: this.$vuetify.theme.dark ? '#fff' : null,
                        },
                    },
                },
                xAxis: {
                    categories: [],
                    labels: {
                        style: {
                            color: this.$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e',
                        }
                    },
                },
                series: [{
                    data: [],
                    color: this.$vuetify.theme.themes.light.success,
                    borderWidth: 0,
                    cursor: 'pointer',
                    events: {
                        click: ({point}) => {
                            this.filtroDetalhado = {
                                indicador: 'indicador',
                                nome: point.category
                            }
                            this.detalhado()
                        }
                    }
                }]
            }, 
            headersGrupo: [
                { text: null, value: 'grupo', align: 'center'},
                { text: null, value: 'nome', align: 'center', sortable: false},
                { text: 'Planejado', value: 'planejado', align: 'center',  tipo: 'moeda'},
                { text: 'Investimento', value: 'investimento', align: 'center',  tipo: 'moeda'},
                { text: 'Investimento vs Planejado', value: 'investimento_planejado', align: 'center', tipo: 'percentual'},
                { text: 'A Investir', value: 'ainvestir', align: 'center',  tipo: 'moeda'},
                { text: 'Faturamento', value: 'faturamento', align: 'center',  tipo: 'moeda'},
                { text: 'Real', value: 'real', align: 'center',  tipo: 'moeda'},
                { text: 'Investimento vs Real', value: 'investimento_real', align: 'center', tipo: 'percentual'},
                { text: 'Real Ano Anterior', value: 'realAnterior', align: 'center',  tipo: 'moeda'},
                { text: 'Crescimento Real', value: 'realCrescimento', align: 'center', tipo: 'percentual'},
            ],
            grupo: {},
            dadosGrupo: [],
            dadosGrupoTemp: [],
            sortByGrupo: ['planejado'],
            sortDescGrupo: [true],
            grupo1: 'marca',
            grupo2: 'acao',
            grupoCsv: {
                arquivo: '',
                label: {},
                colunas: [],
                dados: [],
            }
        }
    },
    watch: {
        pagination: {
            handler () {
                if(this.modelosLista.length > 0) this.filtrar(false)
            },
            deep: true,
        },
        paginationDetalhado: {
            handler () {
                if(this.modelosLista.length > 0) this.detalhado(false)
            },
            deep: true,
        },
        sortByGrupo: {
            handler () {

                this.ordenaGrupo()
            },
            deep: true,
        },
        sortDescGrupo: {
            handler () {

                this.ordenaGrupo()
            },
            deep: true,
        },
    },
    async created() { 

        this.carregando = true

        this.menuFiltros = localStorage.getItem('menuFiltros') == 'true' ? true : false

        var data = {id_cargo: this.$store.getters.getCargo.id_cargo}

        var retorno = await this.$http().post(`/gerencial/planejadoModelos`, {data: data})

        this.fnFaturamento = retorno.data.fnFaturamento

        if (!this.fnFaturamento) {

            this.indicadoresTotal.pop()
            this.indicadoresTotal.pop()
            this.indicadoresTotal.pop()

            this.graficoMeses.series.pop()
            this.graficoMeses.series.pop()
            this.graficoMeses.series.pop()
            this.graficoMeses.series.pop()
            this.graficoMeses.series.pop()

            this.indicadores.pop()
            this.indicadores.pop()
            this.indicadores.pop()
            this.indicadores.pop()
            this.indicadores.pop()

            this.headersGrupo.pop()
            this.headersGrupo.pop()
            this.headersGrupo.pop()
            this.headersGrupo.pop()
            this.headersGrupo.pop()
        }

        this.headersFaturamento.push({ text: retorno.data.faturamentoProdutoMarca[0].nome, value: retorno.data.faturamentoProdutoMarca[0].ad, align: 'center'})
        this.headersFaturamento.push({ text: 'Valor', value: 'valor', align: 'center'})
        this.headersFaturamento.push({ text: 'Valor Considerado', value: 'valor_calculado', align: 'center'})
        this.headersFaturamento.push({ text: 'Status', value: 'status', align: 'center'})

        this.modelosLista = retorno.data.modelosLista

        this.lista = retorno.data.lista

        if ( !this.lista.filtro.map(e => e.tabela).includes(this.topEixo) ) {

            this.topEixo = this.lista.filtro.find(e => e.fn_atributo == '1').tabela
        }

        if ( !this.lista.filtro.map(e => e.tabela).includes(this.grupo1) ) {

            this.grupo1 = this.lista.filtro.find(e => e.fn_atributo == '1' && e.tabela != this.grupo2).tabela
        }

        if ( !this.lista.filtro.map(e => e.tabela).includes(this.grupo2) ) {

            this.grupo2 = this.lista.filtro.find(e => e.fn_atributo == '1' && e.tabela != this.grupo1).tabela
        }

        var fn_tabela = []

        for (const m of this.modelosLista) {

            for (const t of m.desdobramento.tabelas) {

                if(fn_tabela[t.tabela]) continue

                fn_tabela[t.tabela] = true

                if (t.public_tabela == 'setor') {

                    this.headersPlanejado.push({ text: t.nome, value: t.tabela, align: 'center' })
                    this.headersPlanejado.push({ text: t.nome.substr(6), value: t.tabela + '_usuario', align: 'center' })
                }
                else {

                    this.headersPlanejado.push({ text: t.nome, value: t.tabela, align: 'center' })
                }
            }
        }

        for (const t of this.lista.filtro) {

            this.filtro[t.var_id] = []
        }

        var fnObs = []

        for (const m of this.modelosLista) {

            for (const p of m.passos) {

                if(p.obs && !fnObs[p.ordem]) {

                    fnObs[p.ordem] = true

                    this.headersInvestimento.push({ text: p.obs_nome, value: 'obs_'+p.ordem, align: 'center'})
                }
            }
        }

        var camposLojaAcordo = retorno.data.camposLojaAcordo

        for (const c of camposLojaAcordo) {

            if(c.campo == 'modelo_arquivo') continue

            let index = this.headersInvestimento.findIndex(e => e.value == 'investimento')

            this.headersInvestimento.splice(index, 0, { text: c.nome, value: c.tabela+'_'+c.campo, align: 'center'},)
        }

        var camposInvestimento = retorno.data.camposInvestimento

        for (const c of camposInvestimento) {

            let index = this.headersInvestimento.findIndex(e => e.value == 'investimento')

            this.headersInvestimento.splice(index, 0, { text: c.nome, value: c.tabela+'_'+c.campo, align: 'center'},)
        }

        this.carregando = false

        this.filtrar()
    },
    computed: {
        topNome() {

            let nome = `${this.indicadores.find(e => e.value == this.indicador).text} Top  ${this.lista.filtro.find(e => e.tabela == this.topEixo).plural}`

            return nome
        },
    },
    methods: {
        miniFiltros () {
            localStorage.setItem('menuFiltros', !this.menuFiltros)
            this.menuFiltros = !this.menuFiltros
        },
        iconeSelecionarTudo(var_id, tabela) {

            if(this.filtro[var_id].length === this.lista[tabela].length) return 'mdi-close-box'
            if (this.filtro[var_id].length > 0 && !(this.filtro[var_id].length === this.lista[tabela].length)) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        selecionarTudo (var_id, tabela) {

            this.carregando = true

            setTimeout(() => {

                if(this.filtro[var_id].length === this.lista[tabela].length) {

                    this.filtro[var_id] = []
                }
                else {

                    this.filtro[var_id] = this.lista[tabela].map(e => e.value)
                }
            }, 100)

            setTimeout(() => { this.carregando = false }, (this.lista[tabela].length > 100 ? this.lista[tabela].length : 110))
        },
        removeItemFiltro(item, filtro) {

            var index = filtro.indexOf(item.value)
            if (index >= 0) filtro.splice(index, 1)
        },
        async filtrar(fnGrafico=true) {

            if (this.modelosLista.length == 0) return

            if (!this.$refs.formFiltro.validate()) return

            this.carregando = true

            var data = {id_cargo: this.$store.getters.getCargo.id_cargo}

            data.pagination = this.pagination
            data.filtro = {...this.filtro}
            data.gerencial = 'planejado'

            data.fnGrafico = fnGrafico

            data.fnTop = fnGrafico
            data.topEixo = this.lista.filtro.find(e => e.tabela == this.topEixo)

            data.fnGrupo = fnGrafico
            data.grupo1 = this.lista.filtro.find(e => e.tabela == this.grupo1)
            data.grupo2 = this.lista.filtro.find(e => e.tabela == this.grupo2)

            var response = await this.$http().post(`/gerencial/planejado`, {data: data})

            if(fnGrafico) {

                this.planejado = response.data.planejado[0]
                this.investimento = response.data.investimento[0]
                this.investimentoAnterior = response.data.investimentoAnterior[0]
                this.faturamento = response.data.faturamento[0]
                this.faturamentoAnterior = response.data.faturamentoAnterior[0]

                this.indicadoresTotal[0].valor = this.planejado.planejado*1
                this.indicadoresTotal[1].valor = this.investimento.investimento*1
                this.indicadoresTotal[1].perc = Math.round(this.investimento.investimento*100/this.planejado.planejado*1)
                this.indicadoresTotal[2].valor = this.planejado.planejado*1 - this.investimento.investimento*1

                if (this.fnFaturamento) {

                    this.indicadoresTotal[3].valor = this.faturamento.faturamento*1
                    this.indicadoresTotal[4].valor = Math.round( (this.faturamento.faturamento*1 - this.investimento.investimento*1)*100 )/100
                    this.indicadoresTotal[4].perc = this.indicadoresTotal[4].valor ? Math.round( this.investimento.investimento*100 / this.indicadoresTotal[4].valor ) : 0
                    this.indicadoresTotal[5].valor = Math.round( (this.faturamentoAnterior.faturamento*1 - this.investimentoAnterior.investimento*1)*100 )/100
                    this.indicadoresTotal[5].perc = this.indicadoresTotal[5].valor ? Math.round( this.indicadoresTotal[4].valor*100 / this.indicadoresTotal[5].valor ) - 100 : 0
                }

                this.setaMeses()

                this.estados.estados = response.data.estados
                this.estados.estadosAnterior = response.data.estadosAnterior
                this.estados.estadosFaturamento = response.data.estadosFaturamento
                this.estados.estadosFaturamentoAnterior = response.data.estadosFaturamentoAnterior

                this.setaEstatos()

                this.top = response.data.top

                this.setaTop()

                this.grupo = response.data.grupo

                this.setaGrupo()
            }

            this.carregando = false
        },
        async detalhado(fnPrimeiraBusca=true) {

            if (this.filtroDetalhado.indicador == 'indicador' && !this.indicadores.find(e => e.value == this.indicador).fnDetalhado) return
            if (this.filtroDetalhado.indicador == 'indicadorEstado' && !this.indicadoresEstado.find(e => e.value == this.indicadorEstado).fnDetalhado) return

            if (this.modelosLista.length == 0) return

            if (!this.$refs.formFiltro.validate()) return

            this.dialogoDetalhado = true

            this.carregando = true

            if (fnPrimeiraBusca) {
                
                this.paginationDetalhado = {...this.paginationLimpa}
                return
            }

            var data = {id_cargo: this.$store.getters.getCargo.id_cargo}

            data.pagination = this.paginationDetalhado
            data.filtro = {...this.filtro}
            data.gerencial = 'planejado'

            data.filtro.estado = this.filtroDetalhado.estado ? this.filtroDetalhado.estado.substr(-2) : false
            data.filtro.nome = this.filtroDetalhado.nome 
            data.topEixo = this.lista.filtro.find(e => e.tabela == this.topEixo)

            if (this.filtroDetalhado.mes && this.periodo != 12) {

                let mes = 0 

                if (this.periodo == 1) {

                    mes = this.meses.findIndex(e => e == this.filtroDetalhado.mes) + 1
                }
                else {

                    mes = this.filtroDetalhado.mes.slice(0, 1)*1

                    mes = (mes - 1) * this.periodo + 1
                }

                data.filtro.mesInicial = this.filtro.ano + '-' + ("0" + mes).slice(-2)
                data.filtro.mesFinal = this.filtro.ano + '-' + ("0" + (mes - 1 + this.periodo)).slice(-2)
                
                data.filtro.mesGrafico = mes
                data.filtro.periodo = this.periodo
            }

            if(this.filtroDetalhado.indicador == 'indicador') {

                data.indicador = this.indicadores.find(e => e.value == this.indicador).indicador
            }
            else if(this.filtroDetalhado.indicador == 'indicadorEstado') {

                data.indicador = this.indicadoresEstado.find(e => e.value == this.indicadorEstado).indicador
            }
            else {

                data.indicador = this.filtroDetalhado.indicador
            }

            var response = {}

            this.headers = []
            
            if(data.indicador == 'planejado' || data.indicador == 'investimento_planejado' || data.indicador == 'ainvestir') {

                this.headers = this.headersPlanejado.map(e => {return {...e}})

                let indicador = data.indicador == 'planejado' ? '' : '_'+data.indicador
                let indicadorNome = this.indicadores.find(e => e.value == data.indicador).text

                for (let m = 1; m <= 12; m++) {

                    this.headers.push({ text: indicadorNome + ' ' + this.meses[m - 1], value: 'mes_' + m + indicador, align: 'center'})
                }

                this.headers.push({ text: indicadorNome + ' Total Ano', value: 'total_ano' + indicador, align: 'center'})

                data.colunas = this.lista.filtro

                response = await this.$http().post('/gerencial/planejadoDetalhado', {data: data})
            }
            else if (data.indicador == 'faturamento') { 

                this.headers = this.headersFaturamento.map(e => {return {...e}})
                
                response = await this.$http().post('/gerencial/faturamentoDetalhado', {data: data})
            }
            else { //data.indicador == 'investimento'

                this.headers = this.headersInvestimento.map(e => {return {...e}})
                
                response = await this.$http().post('/gerencial/investimentoDetalhado', {data: data})
            }

            this.dadosDetalhado = response.data.lista

            this.totalDetalhado = response.data.lista[0] ? response.data.lista[0].total : 0

            if(this.totalDetalhado == 0 && this.paginationDetalhado.page > 1) {

                this.paginationDetalhado.page = 1
                this.detalhado()
            }

            this.carregando = false
        },
        limpar() {

            this.filtro = {
               ano: new Date().getFullYear(),
            }  
            
            for (const t of this.lista.filtro) {
                
                this.filtro[t.var_id] = []
            }

            this.filtrar()
        },
        async exportar(fnDetalhado=false) {

            if (this.modelosLista.length == 0) return
           
            this.carregando = true

            var data = {id_cargo: this.$store.getters.getCargo.id_cargo}

            data.filtro = {...this.filtro}
            data.gerencial = 'planejado'

            if (fnDetalhado) {

                data.filtro.estado = this.filtroDetalhado.estado ? this.filtroDetalhado.estado.substr(-2) : false
                data.filtro.nome = this.filtroDetalhado.nome 
                data.topEixo = this.lista.filtro.find(e => e.tabela == this.topEixo)

                if (this.filtroDetalhado.mes && this.periodo != 12) {

                    let mes = 0 

                    if (this.periodo == 1) {

                        mes = this.meses.findIndex(e => e == this.filtroDetalhado.mes) + 1
                    }
                    else {

                        mes = this.filtroDetalhado.mes.slice(0, 1)*1

                        mes = (mes - 1) * this.periodo + 1
                    }

                    data.filtro.mesInicial = this.filtro.ano + '-' + ("0" + mes).slice(-2)
                    data.filtro.mesFinal = this.filtro.ano + '-' + ("0" + (mes - 1 + this.periodo)).slice(-2)
                    
                    data.filtro.mesGrafico = mes
                    data.filtro.periodo = this.periodo
                }
            }

            if(this.filtroDetalhado.indicador == 'indicador') {

                data.indicador = this.indicadores.find(e => e.value == this.indicador).indicador
            }
            else if(this.filtroDetalhado.indicador == 'indicadorEstado') {

                data.indicador = this.indicadoresEstado.find(e => e.value == this.indicadorEstado).indicador
            }
            else {

                data.indicador = this.filtroDetalhado.indicador
            }

            var response = {}

            if(data.indicador == 'planejado' || data.indicador == 'investimento_planejado' || data.indicador == 'ainvestir' || data.indicador == 'planejado_completo') {

                data.colunas = this.lista.filtro

                response = await this.$http().post('/gerencial/exportarPlanejadoDetalhado', {data: data})
            }
            else if (data.indicador == 'faturamento') { 

                response = await this.$http().post('/gerencial/exportarFaturamentoDetalhado', {data: data})
            }
            else { //data.indicador == 'investimento'

                response = await this.$http().post('/gerencial/exportarInvestimentoDetalhado', {data: data})
            }

            window.location = this.$http('baseURL')+response.data.url
            
            this.carregando = false
        },
        setaMeses() {

            this.graficoMeses.title.text = 'Investimento vs Planejado ' + this.periodos.find(e => e.value == this.periodo).text

            this.graficoMeses.xAxis.categories = []
            this.graficoMeses.series[0].data = []
            this.graficoMeses.series[1].data = []
            this.graficoMeses.series[2].data = []

            if (this.fnFaturamento) {

                this.graficoMeses.series[3].data = []
                this.graficoMeses.series[4].data = []
                this.graficoMeses.series[5].data = []
                this.graficoMeses.series[6].data = []
                this.graficoMeses.series[7].data = []
            }

            var planejado = 0
            var investimento = 0
            var faturamento = 0
            var investimentoAnterior = 0
            var faturamentoAnterior = 0
            var contaPeriodo = 1

            for (let m = 1; m <= 12; m++) {

                if (this.filtro.meses.includes(m) || this.filtro.meses.length == 0) {

                    planejado += this.planejado['planejado_'+m]*1
                    investimento += this.investimento['investimento_'+m]*1
                    faturamento += this.faturamento['faturamento_'+m]*1
                    investimentoAnterior += this.investimentoAnterior['investimento_'+m]*1
                    faturamentoAnterior += this.faturamentoAnterior['faturamento_'+m]*1

                    if(this.periodo == 1) {

                        this.graficoMeses.xAxis.categories.push(this.meses[m-1])
                        this.graficoMeses.series[0].data.push(planejado)
                        this.graficoMeses.series[1].data.push(investimento)

                        let perc = planejado > 0 ? Math.round(investimento*100/planejado) : 0

                        this.graficoMeses.series[2].data.push(perc)

                        if (this.fnFaturamento) {

                            this.graficoMeses.series[3].data.push(faturamento)

                            let real = faturamento > 0 ? Math.round((faturamento - investimento)*100)/100 : 0

                            this.graficoMeses.series[4].data.push(real)

                            let percReal = real > 0 ? Math.round(investimento*100/real) : 0

                            this.graficoMeses.series[5].data.push(percReal)

                            let realAnterior = faturamentoAnterior > 0 ? Math.round((faturamentoAnterior - investimentoAnterior)*100)/100 : 0

                            this.graficoMeses.series[6].data.push(realAnterior)

                            let percCrescimento = realAnterior > 0 ? Math.round(real*100/realAnterior) - 100 : 0

                            this.graficoMeses.series[7].data.push(percCrescimento)
                        }

                        planejado = 0
                        investimento = 0
                        faturamento = 0
                        investimentoAnterior = 0
                        faturamentoAnterior = 0
                    }
                }

                if(this.periodo > 1 && m % this.periodo == 0) {

                    let categoria = this.periodo == 12 ? this.filtro.ano : contaPeriodo + 'º ' + this.periodos.find(e => e.value == this.periodo).text.slice(0, -2) + 'e'

                    this.graficoMeses.xAxis.categories.push(categoria)
                    this.graficoMeses.series[0].data.push(planejado)
                    this.graficoMeses.series[1].data.push(investimento)

                    let perc = planejado > 0 ? Math.round(investimento*100/planejado) : 0

                    this.graficoMeses.series[2].data.push(perc)

                    if (this.fnFaturamento) {

                        this.graficoMeses.series[3].data.push(faturamento)

                        let real = Math.round((faturamento - investimento)*100)/100

                        this.graficoMeses.series[4].data.push(real)

                        let percReal = real > 0 ? Math.round(investimento*100/real) : 0

                        this.graficoMeses.series[5].data.push(percReal)

                        let realAnterior = Math.round((faturamentoAnterior - investimentoAnterior)*100)/100

                        this.graficoMeses.series[6].data.push(realAnterior)

                        let percCrescimento = realAnterior > 0 ? Math.round(real*100/realAnterior) - 100 : 0

                        this.graficoMeses.series[7].data.push(percCrescimento)
                    }

                    planejado = 0
                    investimento = 0
                    faturamento = 0
                    investimentoAnterior = 0
                    faturamentoAnterior = 0
                    contaPeriodo++
                }
            }

            if (this.fnFaturamento) {

                this.graficoMeses.series[3].visible = this.legendaVisivel[3]
                this.graficoMeses.series[4].visible = this.legendaVisivel[4]
                this.graficoMeses.series[5].visible = this.legendaVisivel[5]
                this.graficoMeses.series[6].visible = this.legendaVisivel[6]
                this.graficoMeses.series[7].visible = this.legendaVisivel[7]
            }
        },
        mudaLayout() {

            this.layoutCompacto = !this.layoutCompacto 

            setTimeout(() => {

                this.$refs.grafico1.chart.reflow()
                this.$refs.grafico2.chart.reflow()
                this.$refs.grafico3.chart.reflow()
            }, 100)
        },
        setaEstatos() {

            let indicador = this.indicadoresEstado.find(e => e.value == this.indicadorEstado)

            this.filtroDetalhado = {}
            this.filtroDetalhado.fnDetalhado = indicador.fnDetalhado
            this.filtroDetalhado.fnDetalhado = indicador.fnDetalhado

            this.percentualEstados = indicador.tipo == 'percentual' ? true : this.percentualEstados

            this.graficoEstados.title.text = this.percentualEstados && indicador.tipo == 'moeda' ? 'Peso ' + indicador.text + ' (%)' : indicador.text
            this.graficoEstados.series[0].name = this.percentualEstados && indicador.tipo == 'moeda' ? 'Peso ' + indicador.text + ' (%)' : indicador.text
            this.graficoEstados.colorAxis.labels.format = this.percentualEstados ? "{value}%" : "{value}" 
            this.graficoEstados.colorAxis.minColor = indicador.corMi
            this.graficoEstados.colorAxis.maxColor = indicador.cor

            this.graficoEstados.series[0].cursor = indicador.fnDetalhado ? 'pointer' : 'default'

            let estados = [
                'sp', 'ma', 'pa', 'sc', 'ba', 'ap', 'ms', 'mg', 'go', 'rs',
                'to', 'pi', 'al', 'pb', 'ce', 'se', 'rr', 'pe', 'pr', 'es',
                'rj', 'rn', 'am', 'mt', 'df', 'ac', 'ro',
            ]

            this.graficoEstados.series[0].data = []

            for (const est of estados) {

                let valor = {}

                valor.investimento = this.estados.estados.find(e => e.estado == est) ? this.estados.estados.find(e => e.estado == est).investimento*1 : 0
                valor.investimento_p = this.investimento.investimento*1 > 0 ? Math.round(valor.investimento*100/this.investimento.investimento*1) : 0

                if (this.fnFaturamento) {

                    valor.faturamento = this.estados.estadosFaturamento.find(e => e.estado == est) ? this.estados.estadosFaturamento.find(e => e.estado == est).faturamento*1 : 0                
                    valor.faturamento_p = this.faturamento.faturamento*1 > 0 ? Math.round(valor.faturamento*100/this.faturamento.faturamento*1) : 0

                    valor.real = Math.round((valor.faturamento - valor.investimento)*100)/100 

                    valor.real_p = this.indicadoresTotal[4].valor*1 > 0 ? Math.round(valor.real*100/this.indicadoresTotal[4].valor*1) : 0

                    valor.investimento_real = valor.real > 0 ? Math.round(valor.investimento*100/valor.real) : 0

                    valor.investimentoAnterior = this.estados.estadosAnterior.find(e => e.estado == est) ? this.estados.estadosAnterior.find(e => e.estado == est).investimento*1 : 0
                    valor.faturamentoAnterior = this.estados.estadosFaturamentoAnterior.find(e => e.estado == est) ? this.estados.estadosFaturamentoAnterior.find(e => e.estado == est).faturamento*1 : 0

                    valor.investimentoAnterior_p = this.investimentoAnterior.investimento*1 > 0 ? Math.round(valor.investimentoAnterior*100/this.investimentoAnterior.investimento*1) : 0
                    valor.faturamentoAnterior_p = this.faturamentoAnterior.faturamento*1 > 0 ? Math.round(valor.faturamentoAnterior*100/this.faturamentoAnterior.faturamento*1) : 0

                    valor.realAnterior = Math.round((valor.faturamentoAnterior - valor.investimentoAnterior)*100)/100

                    valor.realAnterior_p = this.indicadoresTotal[5].valor*1 > 0 ? Math.round(valor.realAnterior*100/this.indicadoresTotal[5].valor*1) : 0

                    valor.realCrescimento = valor.realAnterior > 0 ? Math.round(valor.real*100/valor.realAnterior) - 100 : 0
                }

                valor.final = valor[ indicador.value + ( this.percentualEstados && indicador.tipo == 'moeda' ? '_p' : '' ) ]

                this.graficoEstados.series[0].data.push(['br-'+est, valor.final])
            }
        },
        async setaTop(fnTop=false) {

            if (this.modelosLista.length == 0) return

            if(fnTop) {

                if (!this.$refs.formFiltro.validate()) return

                this.carregando = true

                var data = {id_cargo: this.$store.getters.getCargo.id_cargo}

                data.filtro = {...this.filtro}
                data.gerencial = 'planejado'
                data.fnTop = fnTop
                data.topEixo = this.lista.filtro.find(e => e.tabela == this.topEixo)

                var response = await this.$http().post(`/gerencial/planejado`, {data: data})

                this.top = response.data.top

                this.carregando = false
            }

            let nomes = [ ...new Set( [ 
                ...this.top.planejado.map(e => e.nome), 
                ...this.top.investimento.map(e => e.nome),
                ...this.top.faturamento.map(e => e.nome),
                ...this.top.faturamentoAnterior.map(e => e.nome),
                ...this.top.investimentoAnterior.map(e => e.nome)
            ] ) ]

            let top = []

            for (const n of nomes) {

                let valor = {}

                valor.planejado = this.top.planejado.find(e => e.nome == n) ? this.top.planejado.find(e => e.nome == n).planejado*1 : 0
                valor.investimento = this.top.investimento.find(e => e.nome == n) ? this.top.investimento.find(e => e.nome == n).investimento*1 : 0
                
                valor.planejado_p = this.planejado.planejado*1 ? Math.round(valor.planejado*100/this.planejado.planejado*1) : 0
                valor.investimento_p = this.investimento.investimento*1 ? Math.round(valor.investimento*100/this.investimento.investimento*1) : 0

                valor.investimento_planejado = valor.planejado ? Math.round(valor.investimento*100/valor.planejado) : 0

                valor.ainvestir = valor.planejado - valor.investimento > 0 ? valor.planejado - valor.investimento : 0

                valor.faturamento = this.top.faturamento.find(e => e.nome == n) ? this.top.faturamento.find(e => e.nome == n).faturamento*1 : 0

                valor.real = Math.round((valor.faturamento - valor.investimento)*100)/100 

                valor.investimento_real = valor.real > 0 ? Math.round(valor.investimento*100/valor.real) : 0

                valor.faturamentoAnterior = this.top.faturamentoAnterior.find(e => e.nome == n) ? this.top.faturamentoAnterior.find(e => e.nome == n).faturamento*1 : 0
                valor.investimentoAnterior = this.top.investimentoAnterior.find(e => e.nome == n) ? this.top.investimentoAnterior.find(e => e.nome == n).investimento*1 : 0

                valor.realAnterior = Math.round((valor.faturamentoAnterior - valor.investimentoAnterior)*100)/100 

                valor.realCrescimento = valor.realAnterior > 0 ? Math.round(valor.real*100/valor.realAnterior) - 100 : 0

                top.push({nome: n, valor: valor[this.indicador]})
            }

            this.graficoTop.title.text = this.topNome
            this.graficoTop.series[0].color = this.indicadores.find(e => e.value == this.indicador).cor

            if(this.indicadores.find(e => e.value == this.indicador).tipo == 'moeda') {

                this.graficoTop.plotOptions.bar.dataLabels.formatter = function () { return this.point.y.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }
            }
            if(this.indicadores.find(e => e.value == this.indicador).tipo == 'percentual') {

                this.graficoTop.plotOptions.bar.dataLabels.formatter = function () { return this.point.y + '%' }
            }

            this.graficoTop.series[0].cursor = this.indicadores.find(e => e.value == this.indicador).fnDetalhado ? 'pointer' : 'default'

            top = top.sort((a,b) => b.valor - a.valor)

            this.graficoTop.series[0].data = []
            this.graficoTop.xAxis.categories = []

            let topNumero = 10

            let elementos = top.length >= topNumero ? topNumero : top.length 

            for (let i = 0; i < elementos; i++) {

                this.graficoTop.xAxis.categories.push(top[i].nome)
                this.graficoTop.series[0].data.push(top[i].valor)
            }
        },
        async setaGrupo(fnGrupo=false) {

            if (this.modelosLista.length == 0) return

            if(fnGrupo) {

                if (!this.$refs.formFiltro.validate()) return

                if (this.grupo1 == this.grupo2) {

                    this.alerta = true
                    this.alertaColor = 'error'
                    this.alertaTimeout = 3000
                    this.alertaTexto = 'Os atributos selecionados não podem ser iguais.'
                    return
                }

                this.carregando = true

                var data = {id_cargo: this.$store.getters.getCargo.id_cargo}

                data.filtro = {...this.filtro}
                data.gerencial = 'planejado'
                data.fnGrupo = fnGrupo
                data.grupo1 = this.lista.filtro.find(e => e.tabela == this.grupo1)
                data.grupo2 = this.lista.filtro.find(e => e.tabela == this.grupo2)

                var response = await this.$http().post(`/gerencial/planejado`, {data: data})

                this.grupo = response.data.grupo

                this.carregando = false
            }

            this.headersGrupo[0].text = this.lista.filtro.find(e => e.tabela == this.grupo1).plural
            this.headersGrupo[1].text = this.lista.filtro.find(e => e.tabela == this.grupo2).plural

            let nomes = [ ...new Set( [ 
                ...this.grupo.planejado.map(e => e.grupo + '%$&_' + e.nome), 
                ...this.grupo.investimento.map(e => e.grupo + '%$&_' + e.nome), 
                ...this.grupo.faturamento.map(e => e.grupo + '%$&_' + e.nome),
                ...this.grupo.faturamentoAnterior.map(e => e.grupo + '%$&_' + e.nome),
                ...this.grupo.investimentoAnterior.map(e => e.grupo + '%$&_' + e.nome)
            ] ) ]

            let grupos = []

            for (const n of nomes) {

                let item = {}

                item.grupo = n.split('%$&_')[0] == 'null' ? null : n.split('%$&_')[0]
                item.nome = n.split('%$&_')[1] == 'null' ? null : n.split('%$&_')[1]
                item.grupoOrdem = item.grupo

                item.planejado = this.grupo.planejado.find(e => e.grupo == item.grupo && e.nome == item.nome) ? this.grupo.planejado.find(e => e.grupo == item.grupo && e.nome == item.nome).planejado*1 : 0
                item.investimento = this.grupo.investimento.find(e => e.grupo == item.grupo && e.nome == item.nome) ? this.grupo.investimento.find(e => e.grupo == item.grupo && e.nome == item.nome).investimento*1 : 0
                
                item.investimento_planejado = item.planejado ? Math.round(item.investimento*100/item.planejado) : 0

                item.ainvestir = item.planejado - item.investimento > 0 ? item.planejado - item.investimento : 0

                item.faturamento = this.grupo.faturamento.find(e => e.grupo == item.grupo && e.nome == item.nome) ? this.grupo.faturamento.find(e => e.grupo == item.grupo && e.nome == item.nome).faturamento*1 : 0

                item.real = Math.round((item.faturamento - item.investimento)*100)/100 

                item.investimento_real = item.real > 0 ? Math.round(item.investimento*100/item.real) : 0

                item.faturamentoAnterior = this.grupo.faturamentoAnterior.find(e => e.grupo == item.grupo && e.nome == item.nome) ? this.grupo.faturamentoAnterior.find(e => e.grupo == item.grupo && e.nome == item.nome).faturamento*1 : 0
                item.investimentoAnterior = this.grupo.investimentoAnterior.find(e => e.grupo == item.grupo && e.nome == item.nome) ? this.grupo.investimentoAnterior.find(e => e.grupo == item.grupo && e.nome == item.nome).investimento*1 : 0

                item.realAnterior = Math.round((item.faturamentoAnterior - item.investimentoAnterior)*100)/100 

                item.realCrescimento = item.realAnterior > 0 ? Math.round(item.real*100/item.realAnterior) - 100 : 0

                grupos.push(item)
            }

            let count = 0

            for (const g of grupos) {

                g.chave = count
                count++

                for (const h of this.headersGrupo) {

                    if(h.tipo == 'moeda') {

                        g[h.value + '_total'] = grupos.filter(e => e.grupo == g.grupo).reduce((a, b) => a + b[h.value]*1, 0)
                    }
                }

                if (this.fnFaturamento) {

                    g.faturamentoAnterior_total = grupos.filter(e => e.grupo == g.grupo).reduce((a, b) => a + b.faturamentoAnterior*1, 0)
                    g.investimentoAnterior_total = grupos.filter(e => e.grupo == g.grupo).reduce((a, b) => a + b.investimentoAnterior*1, 0)

                    g.investimento_planejado_total = g.planejado_total*1 > 0 ? Math.round( g.investimento_total*100 / g.planejado_total*1 ) : 0
                    g.investimento_real_total = g.real_total*1 > 0 ? Math.round( g.investimento_total*100 / g.real_total*1 ) : 0
                    g.realCrescimento_total = g.realAnterior_total*1 > 0 ? Math.round( g.real_total*100 / g.realAnterior_total*1 ) - 100 : 0
                }
            }

            this.dadosGrupoTemp = grupos.filter(e => e.grupo != null && !( e.planejado == 0 && e.investimento == 0 && e.faturamento == 0 && e.real == 0 && e.realAnterior == 0 ) )

            this.csvGrupo()

            this.ordenaGrupo()
        },
        ordenaGrupo() {

            let coluna = !this.sortByGrupo[0] ? 'planejado' : this.sortByGrupo[0]
            let ordemDesc = !this.sortDescGrupo[0] ? false : true
            let tipo = this.headersGrupo.find(e => e.value == coluna).tipo

            let grupos = []

            if(coluna == 'grupo' || coluna == 'nome') {

                if(ordemDesc) {

                    grupos = this.dadosGrupoTemp.sort((a,b) => (b[coluna] > a[coluna]) ? 1 : ((a[coluna] > b[coluna]) ? -1 : 0))
                }
                else {

                    grupos = this.dadosGrupoTemp.sort((a,b) => (a[coluna] > b[coluna]) ? 1 : ((b[coluna] > a[coluna]) ? -1 : 0))
                }
            }
            else {

                let percentual = tipo == 'percentual' ? 1000000 : 1

                if(ordemDesc) {

                    grupos = this.dadosGrupoTemp.sort((a,b) => (b[coluna + '_total']*percentual + b[coluna]*1)  - (a[coluna + '_total']*percentual + a[coluna]*1))
                }
                else {

                    grupos = this.dadosGrupoTemp.sort((a,b) => (a[coluna + '_total']*percentual + a[coluna]*1)  - (b[coluna + '_total']*percentual + b[coluna]*1))
                }
            }

            let ordem = 1
            let grupoOrdem = []

            for (const g of grupos) {

                if(grupoOrdem[g.grupo]) {

                    g.grupoOrdem = grupoOrdem[g.grupo]
                }
                else {

                    grupoOrdem[g.grupo] = ordem
                    g.grupoOrdem = ordem
                    ordem++
                }
            }

            this.dadosGrupo = grupos

            //fecha grupos
            setTimeout(() => {
                    for (const e of  document.getElementsByClassName("grupos")) {
                    if (e.getAttribute('data-open')) e.click()
                }
            }, 100)
        },
        csvGrupo() {

            this.grupoCsv.arquivo = this.headersGrupo[0].text + '_' + this.headersGrupo[1].text + '.csv'
            this.grupoCsv.label = {}
            this.grupoCsv.colunas = []
            this.grupoCsv.dados = []

            for (const h of this.headersGrupo) {

                this.grupoCsv.label[h.value] = h.text
                this.grupoCsv.colunas.push(h.value)
            }

            let dados = this.dadosGrupoTemp.sort((a,b) => (a.grupo > b.grupo) ? 1 : ((b.grupo > a.grupo) ? -1 : 0))

            let grupoAnterior = ''

            for (const d of dados) {

                if(grupoAnterior != d.grupo) {
                
                    let grupo = {}

                    for (const h of this.headersGrupo) {
                        
                        if(h.tipo == 'moeda') {

                            grupo[h.value] = 'R$ ' + d[h.value + '_total'].toLocaleString('pt-br',{style: 'decimal', minimumFractionDigits: 2})
                        }
                        if(h.tipo == 'percentual') {

                            grupo[h.value] = d[h.value + '_total'] + '%'
                        }
                        if(h.value == 'grupo') {

                            grupo[h.value] = d[h.value]
                        }
                        if(h.value == 'nome') {

                            grupo[h.value] = ''
                        }
                    }

                    this.grupoCsv.dados.push(grupo)
                }

                grupoAnterior = d.grupo

                let item = {}

                for (const h of this.headersGrupo) {
                    
                    if(h.tipo == 'moeda') {

                        item[h.value] = 'R$ ' + d[h.value].toLocaleString('pt-br',{style: 'decimal', minimumFractionDigits: 2})
                    }
                    if(h.tipo == 'percentual') {

                        item[h.value] = d[h.value] + '%'
                    }
                    if(h.value == 'grupo') {

                        item[h.value] = ''
                    }
                    if(h.value == 'nome') {

                        item[h.value] = d[h.value]
                    }
                }

                this.grupoCsv.dados.push(item)
            }
        },
     },
}

</script>
