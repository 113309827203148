<template>
    <v-container fluid>

        <!-- Ocultar / Exibir Filtros -->
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <div
                    class="botao_filtro rounded"
                    @click="miniFiltros"
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon 
                        color="white"
                        dense
                    >mdi-filter</v-icon>
                </div>
            </template>
            <span>Exibir Filtros</span>
        </v-tooltip>

        <!-- Filtros -->
        <v-navigation-drawer
            v-model="menuFiltros"
            :permanent="menuFiltros"
            style="position: fixed; top: 65px;"
            right
            app
            width="350"
        >
            <v-list-item 
                @click.stop="miniFiltros"
                class="px-4"
            >
                <v-list-item-title>Filtros</v-list-item-title>
                <v-icon>mdi-chevron-right</v-icon>
            </v-list-item>
            <v-divider></v-divider>
            <v-col cols="12" class="px-2 ml-auto" align="end">
                <v-btn
                    class="ml-2 mb-1"
                    color="primary"
                    @click="limpar()"
                >
                    <v-icon left>mdi-filter-off</v-icon> Limpar
                </v-btn> 
                <v-btn
                    class="ml-2 mb-1"
                    color="primary"
                    @click="filtrar()"
                >
                    <v-icon left>mdi-filter</v-icon> Filtrar
                </v-btn>
            </v-col>
            <v-form ref="formFiltro">
                <v-row no-gutters class="mt-2">
                    <v-col cols="12" class="px-2">
                        <v-text-field
                            label="Período Inicial"
                            v-model="filtro.mesInicial"
                            :rules="regra.mesInicial"
                            required
                            outlined
                            dense 
                            type="month"
                            :style="$vuetify.theme.dark ? `color-scheme: dark;` : ``"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="px-2">
                        <v-text-field
                            label="Período Final"
                            v-model="filtro.mesFinal"
                            :rules="regra.mesFinal"
                            required
                            outlined
                            dense 
                            type="month"
                            :style="$vuetify.theme.dark ? `color-scheme: dark;` : ``"
                        ></v-text-field>
                    </v-col>
                    <v-col v-for="(obj, index) in lista.filtro" :key="index" cols="12" class="px-2">                                 
                        <v-autocomplete
                            :label="obj.nome"
                            :items="lista[obj.tabela]"
                            v-model="filtro[obj.var_id]"
                            outlined
                            dense
                            multiple
                            clearable
                            chips
                            small-chips
                            deletable-chips
                        >
                            <template v-slot:prepend-item>
                                <v-list-item
                                ripple
                                @mousedown.prevent
                                @click="selecionarTudo(obj.var_id, obj.tabela)"
                                >
                                <v-list-item-action>
                                    <v-icon :color="filtro[obj.var_id].length > 0 ? 'indigo darken-4' : ''">
                                    {{ iconeSelecionarTudo(obj.var_id, obj.tabela) }}
                                    </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                    Selecionar Tudo
                                    </v-list-item-title>
                                </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                            </template>
                            <template v-slot:selection="{item}">
                                <v-chip
                                    class="mt-1"
                                    pill
                                    small
                                    :title="item.text"
                                >
                                    <v-icon 
                                        left
                                        small
                                        @click="removeItemFiltro(item, filtro[obj.var_id])"
                                    >mdi-close-circle</v-icon>
                                    {{ item.text }}
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </v-form>
            <br>
            <br>
        </v-navigation-drawer>

        <!-- Titulo e Botões -->
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-toolbar-title class="ml-2 mt-2">Auditorias</v-toolbar-title>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">
                <v-btn
                    class="mt-2 ml-2 my-1"
                    color="primary" 
                    @click="exportarFotos()"  
                >
                    <v-icon left>mdi-microsoft-powerpoint</v-icon> Exportar Evidências
                </v-btn>
                <v-btn
                    class="mt-2 ml-2 my-1"
                    color="primary"
                    @click="exportar()"
                >
                    <v-icon left>mdi-microsoft-excel</v-icon> Exportar
                </v-btn>
            </v-col>
        </v-row>

        <!-- Indicadores -->
        <v-row no-gutters>
            <div
                v-for="indicador in indicadoresTotal"
                :key="indicador.nome"
            >
                <v-tooltip top :color="indicador.cor" v-if="indicador.filtro">
                    <template v-slot:activator="{ on, attrs }">
                        <v-card
                            :class="'elevation-1 mt-3 indicadoresCartao' + (indicador.ml ? indicador.ml : '') + (indicador.mr ? indicador.mr : '')"
                            :color="indicador.cor"
                            v-bind="attrs"
                            v-on="on"
                            @click="filtroIndicadore(indicador.filtro)"
                        >
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <div 
                                        class="indicadoresTitulo"
                                        v-bind="attrs"
                                        v-on="on"
                                    ><v-icon dark small>mdi-filter</v-icon> {{indicador.nome}}</div>
                                </template>
                                <span>{{indicador.desc}}</span>
                            </v-tooltip>
                            <div 
                                class="indicadoresValor"
                            >{{(indicador.valor*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</div>
                            <v-avatar 
                                class="indicadoresIcone"
                                size="46"
                                :color="indicador.cor2 ? indicador.cor2 : indicador.cor + ' darken-1'"
                            >
                                <v-icon dark>{{indicador.icone}}</v-icon>
                            </v-avatar>
                            <v-progress-linear
                                v-show="indicador.percSufixo"
                                :value="indicador.perc"
                                color="#fff"
                                height="12"
                                class="indicadoresPercemtualBarra"
                            ></v-progress-linear>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <div 
                                        v-show="indicador.percSufixo"
                                        class="indicadoresPercemtualValor"
                                        v-bind="attrs"
                                        v-on="on"
                                    >{{indicador.percNome}} ({{indicador.perc}}%)</div>
                                </template>
                                <span>{{indicador.percDesc}}</span>
                            </v-tooltip>
                        </v-card>
                    </template>
                    <span>{{indicador.filtroTitulo}}</span>
                </v-tooltip>
                <v-card
                    :class="'elevation-1 mt-3 indicadoresCartao' + (indicador.ml ? indicador.ml : '') + (indicador.mr ? indicador.mr : '')"
                    :color="indicador.cor"
                    v-else
                >
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <div 
                                class="indicadoresTitulo"
                                v-bind="attrs"
                                v-on="on"
                            >{{indicador.nome}}</div>
                        </template>
                        <span>{{indicador.desc}}</span>
                    </v-tooltip>
                    <div 
                        class="indicadoresValor"
                    >{{(indicador.valor*1)}}</div>
                    <v-avatar 
                        class="indicadoresIcone"
                        size="46"
                        :color="indicador.cor2 ? indicador.cor2 : indicador.cor + ' darken-1'"
                    >
                        <v-icon dark>{{indicador.icone}}</v-icon>
                    </v-avatar>
                    <v-progress-linear
                        v-show="indicador.percSufixo"
                        :value="indicador.perc"
                        color="#fff"
                        height="12"
                        class="indicadoresPercemtualBarra"
                    ></v-progress-linear>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <div 
                                v-show="indicador.percSufixo"
                                class="indicadoresPercemtualValor"
                                v-bind="attrs"
                                v-on="on"
                            >{{indicador.percNome}} ({{indicador.perc}}%)</div>
                        </template>
                        <span>{{indicador.percDesc}}</span>
                    </v-tooltip>
                </v-card>
            </div>
        </v-row>

        <!-- Graficos -->
        <v-row no-gutters>
            <!-- Grafico de Auditorias -->
            <v-col cols="12" sm="12" :md="layoutCompacto?6:12" :lg="layoutCompacto?6:12" :xl="layoutCompacto?6:12">
                <v-card class="mt-4 px-2 pt-2" elevation="1">  
                    <v-icon 
                        style="position: absolute; top: 10px; left: 10px; z-index: 2" 
                        left
                        :color="layoutCompacto?'primary':''"
                        @click="mudaLayout()"
                        title="Layout compacto"
                    >mdi-view-compact</v-icon>
                    <v-icon 
                        style="position: absolute; top: 10px; left: 50px; z-index: 2" 
                        left
                        :color="percentualExecucoes?'primary':''"
                        @click="clickPercentualExecucoes()"
                        title="Exibir percentual / quantidade"
                    >mdi-label-percent</v-icon>
                    <highcharts :options="graficoExecucoes" ref="grafico1"></highcharts>
                </v-card>
            </v-col>
            <!-- Grafico de Justificativas -->
            <v-col cols="12" sm="12" :md="layoutCompacto?6:12" :lg="layoutCompacto?6:12" :xl="layoutCompacto?6:12">
                <v-card class="mt-4 px-2 pt-2" :class="layoutCompacto?'ml-4':''" elevation="1"> 
                    <v-icon 
                        style="position: absolute; top: 10px; left: 10px; z-index: 2" 
                        left
                        :color="layoutCompacto?'primary':''"
                        @click="mudaLayout()"
                        title="Layout compacto"
                    >mdi-view-compact</v-icon>
                    <v-icon 
                        style="position: absolute; top: 10px; left: 50px; z-index: 2" 
                        left
                        :color="percentualJustificativas?'primary':''"
                        @click="clickPercentualJustificativas()"
                        title="Exibir percentual / quantidade"
                    >mdi-label-percent</v-icon>
                    <highcharts :options="graficoJustificativas" ref="grafico2"></highcharts>
                </v-card>
            </v-col>
            <!-- Grafico por Estado -->
            <v-col cols="12" sm="12" :md="layoutCompacto?6:12" :lg="layoutCompacto?6:12" :xl="layoutCompacto?6:12">
                <v-card class="mt-4 px-2 pt-2" elevation="1"> 
                    <v-icon 
                        style="position: absolute; top: 10px; left: 10px; z-index: 2" 
                        left
                        :color="layoutCompacto?'primary':''"
                        @click="mudaLayout()"
                        title="Layout compacto"
                    >mdi-view-compact</v-icon>
                    <v-icon 
                        style="position: absolute; top: 10px; left: 50px; z-index: 2" 
                        left
                        :color="percentualEstados ? 'primary' : ''"
                        @click="percentualEstados = !percentualEstados; setaEstatos();"
                        title="Exibir percentual / quantidade"
                    >mdi-label-percent</v-icon>
                    <v-select
                        style="position: absolute; top: 0px; left: 50%;  margin-left: -110px; z-index: 2; width: 220px;" 
                        :items="indicadores"
                        v-model="indicador"
                        v-on:change="setaEstatos(); setaTop();"
                        label="Indicador"
                        flat solo 
                    >
                        <template v-slot:selection="{item}" class="highcharts-title">
                           <span class="highcharts-title" :style="`font-size: 18px; color: ${$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e'};`">{{ item.text + (percentualEstados ? ' (%)' : '') }}</span>
                        </template>
                    </v-select>
                    <highcharts :constructorType="'mapChart'" class="hc" :options="graficoEstados" ref="grafico3"></highcharts>
                </v-card>
            </v-col>
            <!-- Grafico Top 10 -->
            <v-col cols="12" sm="12" :md="layoutCompacto?6:12" :lg="layoutCompacto?6:12" :xl="layoutCompacto?6:12">
                <v-card class="mt-4 px-2 pt-2" :class="layoutCompacto?'ml-4':''" elevation="1"> 
                    <v-icon 
                        style="position: absolute; top: 10px; left: 10px; z-index: 2" 
                        left
                        :color="layoutCompacto?'primary':''"
                        @click="mudaLayout()"
                        title="Layout compacto"
                    >mdi-view-compact</v-icon>
                    <v-icon 
                        style="position: absolute; top: 10px; left: 50px; z-index: 2" 
                        left
                        :color="percentualTop ? 'primary' : ''"
                        @click="percentualTop = !percentualTop; setaTop();"
                        title="Exibir percentual / quantidade"
                    >mdi-label-percent</v-icon>
                    <v-select
                        style="position: absolute; top: 0px; left: 50%;  margin-left: -150px; z-index: 2; width:300px;" 
                        :items="lista.filtro ? lista.filtro.map(e => ({value: e.tabela, text: e.plural})) : []"
                        v-model="topEixo"
                        v-on:change="setaTop(true)"
                        label="Top Bandeiras (%)"
                        flat solo 
                    >
                        <template v-slot:selection="{}" class="highcharts-title">
                           <span class="highcharts-title" :style="`font-size: 18px; color: ${$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e'};`">{{topNome}}</span>
                        </template>
                    </v-select>
                    <highcharts :options="graficoTop" ref="grafico4"></highcharts>
                </v-card>
            </v-col>
        </v-row>

        <!-- Tabela Detalhada -->
        <v-card class="my-4 px-2 pt-2" elevation="1">
            <v-data-table
                :headers="headers"
                :items="dados"
                :server-items-length="total" 
                :items-per-page="5"
                :footer-props="{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100]
                }"
                :options.sync="pagination"
                multi-sort
                class="elevation-0"
            >
                <template v-slot:[`item.historico`]="{ item }"> 
                    <v-icon
                        v-if="item.id_auditoria"
                        small
                        class="mr-2"
                        color="primary"
                        @click="buscaAuditoriasHistorico(item)"
                        title="Histórico"
                    >
                        mdi-file-document-multiple
                    </v-icon>
                </template>
                <template v-slot:[`item.evidencia`]="{ item }"> 
                    <div
                        v-if="item.evidencia"
                        @click="mostraEvidencia(item)"
                        title="Evidências"
                        style="cursor: pointer;"
                    >
                        <v-badge
                            bordered
                            overlap
                            color="primary"
                            :content="item.evidencia.length"
                        >
                            <v-icon
                                small
                                class="mr-2"
                                color="primary"
                            >
                                mdi-tooltip-image
                            </v-icon>
                        </v-badge>
                    </div>
                </template>
                <template v-slot:[`item.acordo_modelo_data`]="{ item }">
                    {{item.acordo_modelo_data ? $moment(item.acordo_modelo_data).format('DD/MM/YYYY') : ''}}
                </template>
                <template v-slot:[`item.acordo_ad_6`]="{ item }">
                    {{item.acordo_ad_6 ? $moment(item.acordo_ad_6).format('DD/MM/YYYY') : ''}}
                </template>
                <template v-slot:[`item.acordo_ad_7`]="{ item }">
                    {{item.acordo_ad_7 ? $moment(item.acordo_ad_7).format('DD/MM/YYYY') : ''}}
                </template>
                <template v-slot:[`item.acordo_ad_8`]="{ item }">
                    {{item.acordo_ad_8 ? $moment(item.acordo_ad_8).format('DD/MM/YYYY') : ''}}
                </template>
                <template v-slot:[`item.acordo_ad_9`]="{ item }">
                    {{item.acordo_ad_9 ? $moment(item.acordo_ad_9).format('DD/MM/YYYY') : ''}}
                </template>
                <template v-slot:[`item.acordo_ad_10`]="{ item }">
                    {{item.acordo_ad_10 ? $moment(item.acordo_ad_10).format('DD/MM/YYYY') : ''}}
                </template>
                <template v-slot:[`item.investimento`]="{ item }"> 
                   {{ (item.investimento*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
                </template>
            </v-data-table>  
        </v-card>

        <!-- Tabela Composta -->
        <v-card class="my-4 px-2 pt-2 pb-2" elevation="1">
            <v-row no-gutters>
                <v-col cols="4">
                    <v-select
                        :items="lista.filtro ? lista.filtro.map(e => ({value: e.tabela, text: e.plural})) : []"
                        v-model="grupo1"
                        flat solo
                        hide-details
                    >
                        <template v-slot:selection="{item}" class="highcharts-title">
                        <span class="highcharts-title" :style="`font-size: 18px; color: ${$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e'};`">{{ item.text }}</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="4">
                    <v-select
                        :items="lista.filtro ? lista.filtro.map(e => ({value: e.tabela, text: e.plural})) : []"
                        v-model="grupo2"
                        flat solo
                        hide-details
                    >
                        <template v-slot:selection="{item}" class="highcharts-title">
                        <span class="highcharts-title" :style="`font-size: 18px; color: ${$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e'};`">{{ item.text }}</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="2" align="end">
                    <v-btn
                        color="primary"
                        @click="setaGrupo(true)"
                    >
                        <v-icon left>mdi-refresh</v-icon> Atualizar
                    </v-btn>
                </v-col>
                <v-col cols="2" align="end">
                    <download-csv
                        :data="grupoCsv.dados"
                        :name="grupoCsv.arquivo"
                        :labels="grupoCsv.label"
                        :fields="grupoCsv.colunas"
                        delimiter=";"
                    >
                        <v-btn
                            class="ml-2"
                            color="primary"
                        >
                            <v-icon left>mdi-microsoft-excel</v-icon> Exportar
                        </v-btn>
                    </download-csv>
                </v-col>
            </v-row>
            <v-data-table
                :headers="headersGrupo"
                :items="dadosGrupo" 
                item-key="chave"
                group-by="grupoOrdem"
                class="elevation-0"
                hide-default-footer
                disable-pagination
                :sort-by.sync="sortByGrupo"
                :sort-desc.sync="sortDescGrupo"
            >
                <template v-slot:[`group.header`]="{items, isOpen, toggle}">
                    <td class="text-center grupos" :data-open="isOpen" @click="toggle" style="cursor: pointer">
                        {{ items[0].grupo }}
                        <v-icon>{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                    </td>
                    <td class="text-center" @click="toggle" style="cursor: pointer">
                    </td>
                    <td v-for="(indicador) in headersGrupo.filter(e => e.fnPercentual)" :key="indicador.value" class="text-center" @click="toggle" style="cursor: pointer">
                        {{ items[0][indicador.value+'_total'] }}%
                    </td>
                    <td v-for="(indicador) in headersGrupo.filter(e => e.fnTotal)" :key="indicador.value" class="text-center" @click="toggle" style="cursor: pointer">
                        {{ items[0][indicador.value+'_total'] }}
                    </td>
                </template>
                <template v-slot:[`item.grupo`]="{ }"> 
                    <v-icon
                        small
                        class="mr-2"
                        color="primary"
                    >
                        mdi-subdirectory-arrow-right
                    </v-icon>
                </template>
                <template v-for="(indicador, index) in headersGrupo.filter(e => e.fnPercentual)" v-slot:[`item.${indicador.value}`]="{ item }" > 
                    <div :key="index">
                        {{ item[indicador.value] }}%
                    </div>
                </template>
            </v-data-table>
        </v-card>

        <!-- Dialogo Detalhado -->
        <v-dialog
            v-model="dialogoDetalhado"
            width="95%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Detalhado
                </v-card-title>
                <v-row no-gutters class="mx-2">
                    <v-col cols="6">
                    </v-col>
                    <v-col cols="6" align="end"  class="py-2">  
                        <v-btn
                            class="ml-2 my-1"
                            color="primary" 
                            @click="exportar({fnDetalhado: true})"  
                        >
                            <v-icon left>mdi-microsoft-excel</v-icon> Exportar CSV
                        </v-btn>
                    </v-col>
                </v-row>
                <v-data-table
                    :headers="headers"
                    :items="dadosDetalhado"
                    :server-items-length="totalDetalhado" 
                    :items-per-page="5"
                    :footer-props="{
                        'items-per-page-options': [5, 10, 15, 25, 50, 100]
                    }"
                    :options.sync="paginationDetalhado"
                    multi-sort
                    class="elevation-0"
                >
                    <template v-slot:[`item.historico`]="{ item }"> 
                        <v-icon
                            v-if="item.id_auditoria"
                            small
                            class="mr-2"
                            color="primary"
                            @click="buscaAuditoriasHistorico(item)"
                            title="Histórico"
                        >
                            mdi-file-document-multiple
                        </v-icon>
                    </template>
                    <template v-slot:[`item.evidencia`]="{ item }"> 
                        <div
                            v-if="item.evidencia"
                            @click="mostraEvidencia(item)"
                            title="Evidências"
                            style="cursor: pointer;"
                        >
                            <v-badge
                                bordered
                                overlap
                                color="primary"
                                :content="item.evidencia.length"
                            >
                                <v-icon
                                    small
                                    class="mr-2"
                                    color="primary"
                                >
                                    mdi-tooltip-image
                                </v-icon>
                            </v-badge>
                        </div>
                    </template>
                    <template v-slot:[`item.acordo_modelo_data`]="{ item }">
                        {{item.acordo_modelo_data ? $moment(item.acordo_modelo_data).format('DD/MM/YYYY') : ''}}
                    </template>
                    <template v-slot:[`item.acordo_ad_6`]="{ item }">
                        {{item.acordo_ad_6 ? $moment(item.acordo_ad_6).format('DD/MM/YYYY') : ''}}
                    </template>
                    <template v-slot:[`item.acordo_ad_7`]="{ item }">
                        {{item.acordo_ad_7 ? $moment(item.acordo_ad_7).format('DD/MM/YYYY') : ''}}
                    </template>
                    <template v-slot:[`item.acordo_ad_8`]="{ item }">
                        {{item.acordo_ad_8 ? $moment(item.acordo_ad_8).format('DD/MM/YYYY') : ''}}
                    </template>
                    <template v-slot:[`item.acordo_ad_9`]="{ item }">
                        {{item.acordo_ad_9 ? $moment(item.acordo_ad_9).format('DD/MM/YYYY') : ''}}
                    </template>
                    <template v-slot:[`item.acordo_ad_10`]="{ item }">
                        {{item.acordo_ad_10 ? $moment(item.acordo_ad_10).format('DD/MM/YYYY') : ''}}
                    </template>
                    <template v-slot:[`item.investimento`]="{ item }"> 
                        {{ (item.investimento*1).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
                    </template>
                </v-data-table>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="dialogoDetalhado = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Lista de Evidências -->
        <v-dialog
            v-model="evidencias.dialogo"
            width="70%"
            scrollable
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{evidencias.titulo}}
                </v-card-title>
                <v-card-text class="mt-4">
                    <v-list>
                        <v-list-item-group>
                            <v-list-item v-for="(item, i) in evidencias.lista" :key="i" class="pa-0" @click="mostraEvidenciaItem(item)">
                                <v-list-item-action class="ma-2">
                                    <v-card class="text-subtitle-2 d-flex align-center justify-center ma-0" width="80" height="40" rounded="1" elevation="0" color="secondary lighten-5">
                                        {{item.extensao}}
                                    </v-card>
                                </v-list-item-action>
                                <v-list-item-content class="text-caption ml-2">
                                    <v-list-item-title v-text="item.nome" :title="item.nome"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="evidencias.dialogo = false"
                    >
                        ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Evidência -->
        <v-dialog
            v-model="evidencia.dialogo"
            width="70%"
            scrollable
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{evidencia.titulo}}
                </v-card-title>
                <v-card-text class="mt-4" v-html="evidencia.html">
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="evidencia.dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- PPT -->
        <v-dialog
            v-model="dialogPPT"
            width="80%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Exportando PPTX
                </v-card-title>
                <v-row no-gutters>
                    <v-col cols="12" class="px-8 pt-8 pb-2"> 
                        Serão exportados arquivos com 100 fotos cada.
                        <br/>
                        Total de fotos: {{fotos}}
                        <br/>
                        Total de arquivos: {{Math.ceil(fotos/100)}}
                        <br/>
                        Escrevendo arquivo {{ arquivo }}.
                    </v-col>
                    <v-col cols="12" class="px-8 pb-8"> 
                        <v-progress-linear 
                            v-model="index" 
                            height="25" 
                        >
                            <span class="font-weight-bold white--text">{{ Math.ceil(index) }}%</span>
                        </v-progress-linear>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>

        <!-- Historico de Auditoria -->
        <v-dialog
            v-model="auditoriasHistorico.dialogo"
            width="80%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{auditoriasHistorico.titulo}}
                </v-card-title>
                <v-data-table
                    :headers="auditoriasHistorico.headers"
                    :items="auditoriasHistorico.dados"
                    item-key="id_historico"
                    :items-per-page="5"
                    class="elevation-1 data-table-2"
                >
                    <template v-slot:[`item.evidencia`]="{ item }"> 
                        <div
                            v-if="item.evidencia"
                            @click="mostraEvidencia(item)"
                            title="Evidências"
                            style="cursor: pointer;"
                        >
                            <v-badge
                                bordered
                                overlap
                                color="primary"
                                :content="item.evidencia.length"
                            >
                                <v-icon
                                    small
                                    class="mr-2"
                                    color="primary"
                                >
                                    mdi-tooltip-image
                                </v-icon>
                            </v-badge>
                        </div>
                    </template>
                </v-data-table>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="auditoriasHistorico.dialogo = false"
                    >
                        ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> 

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay> 

        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn
                dark
                text
                class="float-right"
                @click="alerta=false"
            >
            Fechar
            </v-btn>
        </v-snackbar>


    </v-container>
</template>

<script>

import brasilMap from '@highcharts/map-collection/countries/br/br-all.geo.json'

export default {
    data() {
        return {
            menuFiltros: null,
            lista: {},
            filtro: {},
            headers: [
                { text: 'Histórico', value: 'historico', sortable: false, align: 'center'},
                { text: 'Tipo de Acordo', value: 'modelo', align: 'center'},
                { text: 'Contrato', value: 'id_contrato', align: 'center'},
                { text: 'Acordo', value: 'id_acordo', align: 'center'},
                { text: 'Mês', value: 'mes_nome', align: 'center'},
                { text: 'Parcela', value: 'parcela', align: 'center'},
                { text: 'Status', value: 'status', align: 'center'},
                { text: 'Setor', value: 'setor_loja', align: 'center'},
                { text: 'Usuário', value: 'usuario_loja', align: 'center'},
                { text: 'Ação', value: 'acao', align: 'center'},
                { text: 'Razão Social', value: 'razao_social_filial', align: 'center'},
                { text: 'CNPJ Filial', value: 'cnpj_filial', align: 'center'},
                { text: 'Auditoria', value: 'auditoria', align: 'center'},
                { text: 'Evidências', value: 'evidencia', sortable: false, align: 'center'},
                { text: 'Justificativa.', value: 'justificativa', align: 'center'},
                { text: 'Observação Auditoria', value: 'observacao', align: 'center'},
            ],
            dados: [],
            total: null, 
            paginationLimpa: {
                groupBy: [],
                groupDesc: [],
                itemsPerPage: 5,
                multiSort: true,
                mustSort: false,
                page: 1,
                sortBy: [],
                sortDesc: [],
            },
            pagination: {},
            regra: { 
                mesInicial: [
                    v => !!v || 'Campo obrigatório.',
                ],
                mesFinal: [
                    v => !!v || 'Campo obrigatório.',
                    v => (!!v && v >= this.filtro.mesInicial) || 'O período final deve ser igual ou posterior ao período inicial',
                ],
            },
            modelosLista: [],
            carregando: false,
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            evidencias: {
                dialogo: false,
                titulo: null,
                lista: [],
            },
            evidencia: {
                dialogo: false,
                titulo: null,
                html: null,
            },
            auditoriasHistorico: {
                dialogo: false,
                titulo: null,
                headers: [
                    { text: 'Ação', value: 'acao', sortable: false, align: 'center'},
                    { text: 'CNPJ Filial', value: 'cnpj', sortable: false, align: 'center'},
                    { text: 'Razão Social', value: 'razao_social', sortable: false, align: 'center'},
                    { text: 'Data', value: 'data', sortable: false, align: 'center'},
                    { text: 'Responsável', value: 'usuario', sortable: false, align: 'center'},
                    { text: 'Auditoria', value: 'executado_nome', sortable: false, align: 'center'},
                    { text: 'Evidências', value: 'evidencia', sortable: false, align: 'center'},
                    { text: 'Justificativa', value: 'justificativa', sortable: false, align: 'center'},
                    { text: 'Observação', value: 'observacao', sortable: false, align: 'center'},
                ],
                dados: [],
            },  
            execucoes: [],
            indicadoresTotal: [
                {
                    nome: 'PENDENTE', 
                    valor: 0, 
                    sufixo: '', 
                    desc: 'Número de ações pendentes de auditoria.', 
                    icone: 'mdi-image-edit', 
                    cor: 'warning', 
                    perc: 0, 
                    percSufixo: 'p', 
                    percNome: 'Percentual Pendente', 
                    percDesc: 'Percentual de ações pendentes de auditoria.',
                    mr: ' mr-4',
                },
                {
                    nome: 'AUDITADO', 
                    valor: 0, 
                    sufixo: '', 
                    desc: 'Número de ações auditadas.', 
                    icone: 'mdi-image', 
                    cor: 'info', 
                    perc: 0, 
                    percSufixo: 'p', 
                    percNome: 'Percentual Auditado', 
                    percDesc: 'Percentual de ações auditadas.',
                    mr: ' mr-4',
                },
                {
                    nome: 'EXECUTADO', 
                    valor: 0, 
                    sufixo: '', 
                    desc: 'Número de ações executadas.', 
                    icone: 'mdi-image-check', 
                    cor: 'success', 
                    perc: 0, 
                    percSufixo: 'p', 
                    percNome: 'Percentual Executado', 
                    percDesc: 'Percentual de ações executadas.',
                    mr: ' mr-4',
                },
                {
                    nome: 'NÃO EXECUTADO', 
                    valor: 0, 
                    sufixo: '', 
                    desc: 'Número de ações não executadas.', 
                    icone: 'mdi-image-remove', 
                    cor: 'error', 
                    perc: 0, 
                    percSufixo: 'p', 
                    percNome: 'Percentual não Executado', 
                    percDesc: 'Percentual de ações não executadas.',
                    mr: ' mr-4',
                },
            ],
            layoutCompacto: true,
            percentualExecucoes: true,
            graficoExecucoes: {
                credits: {
                    enabled: false
                },
                tooltip: {
                    enabled: false
                },
                title: {
                    text: "Auditorias (%)",
                    style: {
                        color: this.$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e',
                    }
                },
                chart: {
                    type: 'column',
                    backgroundColor: this.$vuetify.theme.dark ? '#1E1E1E' : '#fff',
                },
                yAxis: {
                    visible: false
                },
                legend: {
                    itemStyle: {
                        color: this.$vuetify.theme.dark ? '#fff' : null,
                    },
                    itemHoverStyle: {
                        color: this.$vuetify.theme.dark ? '#9e9e9e' : null,
                    },
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: true,
                            formatter: function () { return this.point.y*1 == 0 ? '' : this.point.y + '%' },
                            style: {
                                fontWeight: 'bold',
                                color: '#FFF',
                                textOutline: 'none'
                            }
                        },
                        states: {
                            inactive: {
                                 opacity: 1
                            }
                        },
                        maxPointWidth: 100
                    },
                },
                xAxis: {
                    categories: [],
                    labels: {
                        style: {
                            color: this.$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e',
                        }
                    },
                },
                series: [{
                    name: 'Pendente',
                    data: [],
                    stack: 'total',
                    color: this.$vuetify.theme.themes.light.warning,
                    borderWidth: 0,
                    dataLabels: {
                        style: {
                            textOutline: this.$vuetify.theme.themes.light.warning,
                        },
                    },
                    cursor: 'pointer',
                    events: {
                        click: ({point}) => {
                            this.filtroDetalhado = {
                                auditoria: 'Pendente',
                                mes: point.category.split('/')[1]+'-'+point.category.split('/')[0]
                            }
                            this.detalhado()
                        }
                    }
                }, {
                    name: 'Auditado',
                    data: [],
                    stack: 'total',
                    color: this.$vuetify.theme.themes.light.info,
                    borderWidth: 0,
                    dataLabels: {
                        style: {
                            textOutline: this.$vuetify.theme.themes.light.info,
                        },
                    },
                    cursor: 'pointer',
                    events: {
                        click: ({point}) => {
                            this.filtroDetalhado = {
                                auditoria: 'Auditado',
                                mes: point.category.split('/')[1]+'-'+point.category.split('/')[0]
                            }
                            this.detalhado()
                        }
                    }
                }, {
                    name: 'Executado',
                    data: [],
                    stack: 'auditado',
                    color: this.$vuetify.theme.themes.light.success,
                    borderWidth: 0,
                    dataLabels: {
                        style: {
                            textOutline: this.$vuetify.theme.themes.light.success,
                        },
                    },
                    cursor: 'pointer',
                    events: {
                        click: ({point}) => {
                            this.filtroDetalhado = {
                                auditoria: 'Executado',
                                mes: point.category.split('/')[1]+'-'+point.category.split('/')[0]
                            }
                            this.detalhado()
                        }
                    }
                }, {
                    name: 'Não executado',
                    data: [],
                    stack: 'auditado',
                    color: this.$vuetify.theme.themes.light.error,
                    borderWidth: 0,
                    dataLabels: {
                        style: {
                            textOutline: this.$vuetify.theme.themes.light.error,
                        },
                    },
                    cursor: 'pointer',
                    events: {
                        click: ({point}) => {
                            this.filtroDetalhado = {
                                auditoria: 'Não executado',
                                mes: point.category.split('/')[1]+'-'+point.category.split('/')[0]
                            }
                            this.detalhado()
                        }
                    }
                }]
            },
            justificativas: [],
            percentualJustificativas: true,
            graficoJustificativas: {
                credits: {
                    enabled: false
                },
                tooltip: {
                    enabled: false
                },
                title: {
                    text: "Justificativas de não execução (%)",
                    style: {
                        color: this.$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e',
                    }
                },
                chart: {
                    type: 'column',
                    backgroundColor: this.$vuetify.theme.dark ? '#1E1E1E' : '#fff',
                },
                yAxis: {
                    visible: false
                },
                legend: {
                    enabled: false,
                    itemStyle: {
                        color: this.$vuetify.theme.dark ? '#fff' : null,
                    },
                    itemHoverStyle: {
                        color: this.$vuetify.theme.dark ? '#9e9e9e' : null,
                    },
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: true,
                            format: "{point.y}%",
                            style: {
                                fontWeight: 'bold',
                                color: '#FFF',
                                textOutline: this.$vuetify.theme.themes.light.primary,
                            }
                        },
                        maxPointWidth: 100
                    },
                },
                xAxis: {
                    categories: [],
                    labels: {
                        style: {
                            color: this.$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e',
                        }
                    },
                },
                series: [{
                    data: [],
                    color: this.$vuetify.theme.themes.light.primary,
                    borderWidth: 0,
                    cursor: 'pointer',
                    events: {
                        click: ({point}) => {
                            this.filtroDetalhado = {
                                auditoria: 'Não executado',
                                justificativa: point.category
                            }
                            this.detalhado()
                        }
                    }
                }]
            },
            dialogPPT: false,
            fotos: 0,
            index: 0,
            arquivo: 0,
            dialogoDetalhado: false,
            dadosDetalhado: [], 
            paginationDetalhado: {}, 
            totalDetalhado: null,
            filtroDetalhado: {},
            indicadores: [
                {value: 'pendentes', text: 'Pendentes', minColor: '#ffff9e', maxColor: this.$vuetify.theme.themes.light.warning},
                {value: 'auditadas', text: 'Auditados', minColor: '#d4ffff', maxColor: this.$vuetify.theme.themes.light.info},
                {value: 'executadas', text: 'Executados', minColor: '#dcffd6', maxColor: this.$vuetify.theme.themes.light.success},
                {value: 'nao_executadas', text: 'Não executados', minColor: '#ffe4d5', maxColor: this.$vuetify.theme.themes.light.error},
            ],
            indicador: 'executadas',
            estados: [],
            percentualEstados: true,
            graficoEstados: {
                chart: {
                    map: brasilMap,
                    height: 550,
                    backgroundColor: this.$vuetify.theme.dark ? '#1E1E1E' : '#fff',
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: "Executados (%)",
                    style: {
                        color: this.$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e',
                    }
                },
                mapNavigation: {
                    enabled: true,
                },
                colorAxis: {
                    minColor: '#dcffd6',
                    maxColor: this.$vuetify.theme.themes.light.success,
                    labels: {
                        format: '{value}%',
                        style: {
                            color: this.$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e',
                        }
                    },
                },
                series: [{
                    name: 'Executados (%)',
                    states: {
                        hover: {
                            color: '#18FFFF'
                        }
                    },
                    dataLabels: {
                        enabled: false,
                        format: '{point.name}: {point.value}%'
                    },
                    allAreas: true,
                    data: [],
                    tooltip: {
                        valueSuffix: '%'
                    },
                    cursor: 'pointer',
                    events: {
                        click: ({point}) => {
                            this.filtroDetalhado = {
                                auditoria: 'indicador',
                                estado: point["hc-key"]
                            }
                            this.detalhado()
                        }
                    }
                }]
            },
            topEixo: 'bandeira',
            top: [],
            percentualTop: true,
            graficoTop: {
                credits: {
                    enabled: false
                },
                tooltip: {
                    enabled: false
                },
                title: {
                    text: "Top Executados por Bandeira (%)",
                    style: {
                        color: this.$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e',
                    }
                },
                chart: {
                    type: 'bar',
                    height: 550,
                    backgroundColor: this.$vuetify.theme.dark ? '#1E1E1E' : '#fff',
                },
                yAxis: {
                    visible: false
                },
                legend: {
                    enabled: false,
                    itemStyle: {
                        color: this.$vuetify.theme.dark ? '#fff' : null,
                    },
                    itemHoverStyle: {
                        color: this.$vuetify.theme.dark ? '#9e9e9e' : null,
                    },
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            enabled: true,
                            format: '{point.y}%',
                            style: {
                                fontWeight: 'bold',
                                color: '#000',
                                textOutline: 'none'
                            },
                            color: this.$vuetify.theme.dark ? '#fff' : null,
                        },
                    },
                },
                xAxis: {
                    categories: [],
                    labels: {
                        style: {
                            color: this.$vuetify.theme.dark ? '#C3C3C3' : '#9e9e9e',
                        }
                    },
                },
                series: [{
                    data: [],
                    color: this.$vuetify.theme.themes.light.success,
                    borderWidth: 0,
                    cursor: 'pointer',
                    events: {
                        click: ({point}) => {
                            this.filtroDetalhado = {
                                auditoria: 'indicador',
                                nome: point.category
                            }
                            this.detalhado()
                        }
                    }
                }]
            }, 
            headersGrupo: [
                { text: null, value: 'grupo', align: 'center'},
                { text: null, value: 'nome', align: 'center'},
                { text: 'Pendente %', value: 'pendentes_p', align: 'center', fnPercentual: true},
                { text: 'Auditado %', value: 'auditadas_p', align: 'center', fnPercentual: true},
                { text: 'Executado %', value: 'executadas_p', align: 'center', fnPercentual: true},
                { text: 'Não executado %', value: 'nao_executadas_p', align: 'center', fnPercentual: true},
                { text: 'Pendente', value: 'pendentes', align: 'center', fnTotal: true},
                { text: 'Auditado', value: 'auditadas', align: 'center', fnTotal: true},
                { text: 'Executado', value: 'executadas', align: 'center', fnTotal: true},
                { text: 'Não executado', value: 'nao_executadas', align: 'center', fnTotal: true},
                { text: 'Total', value: 'total', align: 'center', fnTotal: true},
            ],
            dadosGrupo: [],
            dadosGrupoTemp: [],
            sortByGrupo: ['grupo'],
            sortDescGrupo: [false],
            grupo1: 'marca',
            grupo2: 'acao',
            grupoCsv: {
                arquivo: '',
                label: {},
                colunas: [],
                dados: [],
            },
            camposInvestimentoPpt: [],
        }
    },
    watch: {
        pagination: {
            handler () {
                if(this.modelosLista.length > 0) this.filtrar(false)
            },
            deep: true,
        },
        paginationDetalhado: {
            handler () {
                if(this.modelosLista.length > 0) this.detalhado(false)
            },
            deep: true,
        },
        sortByGrupo: {
            handler () {

                this.ordenaGrupo()
            },
            deep: true,
        },
        sortDescGrupo: {
            handler () {

                this.ordenaGrupo()
            },
            deep: true,
        },
    },
    async created() {

        this.carregando = true

        this.menuFiltros = localStorage.getItem('menuFiltros') == 'true' ? true : false

        this.filtro.mesInicial = this.mesInicio
        this.filtro.mesFinal = this.mesAtual

        var data = {id_cargo: this.$store.getters.getCargo.id_cargo}

        var retorno = await this.$http().post(`/gerencial/auditoriaModelos`, {data: data})

        this.modelosLista = retorno.data.modelosLista

        this.lista = retorno.data.lista

        if ( !this.lista.filtro.map(e => e.tabela).includes(this.topEixo) ) {

            this.topEixo = this.lista.filtro.find(e => e.fn_atributo == '1').tabela
        }

        if ( !this.lista.filtro.map(e => e.tabela).includes(this.grupo1) ) {

            this.grupo1 = this.lista.filtro.find(e => e.fn_atributo == '1' && e.tabela != this.grupo2).tabela
        }

        if ( !this.lista.filtro.map(e => e.tabela).includes(this.grupo2) ) {

            this.grupo2 = this.lista.filtro.find(e => e.fn_atributo == '1' && e.tabela != this.grupo1).tabela
        }

        for (const t of this.lista.filtro) {

            this.filtro[t.var_id] = []
        }

        var fnObs = []

        for (const m of this.modelosLista) {

            for (const p of m.passos) {

                if(p.obs && !fnObs[p.ordem]) {

                    fnObs[p.ordem] = true

                    this.headers.push({ text: p.obs_nome, value: 'obs_'+p.ordem, align: 'center'})
                }
            }
        }

        var camposLojaAcordo = retorno.data.camposLojaAcordo

        for (const c of camposLojaAcordo) {

            if(c.campo == 'modelo_arquivo') continue

            let index = this.headers.findIndex(e => e.value == 'acao')

            this.headers.splice(index, 0, { text: c.nome, value: c.tabela+'_'+c.campo, align: 'center'},)
        }

        this.camposInvestimentoPpt = retorno.data.camposInvestimentoPpt

        this.carregando = false

        this.filtrar()
    },
    computed: {
        mesAtual() {

            return this.$moment().format('YYYY-MM')
        },
        mesInicio() {

            return this.$moment().subtract(2, 'months').format('YYYY-MM')
        },
        topNome() {

            let nome = `Top  ${this.lista.filtro.find(e => e.tabela == this.topEixo).plural}`

            return this.percentualTop ? nome  + ' (%)' : nome
        },
    },
    methods: {
        miniFiltros () {

            localStorage.setItem('menuFiltros', !this.menuFiltros)
            this.menuFiltros = !this.menuFiltros
        },
        iconeSelecionarTudo(var_id, tabela) {

            if(this.filtro[var_id].length === this.lista[tabela].length) return 'mdi-close-box'
            if (this.filtro[var_id].length > 0 && !(this.filtro[var_id].length === this.lista[tabela].length)) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        selecionarTudo (var_id, tabela) {

            this.carregando = true

            setTimeout(() => {

                if(this.filtro[var_id].length === this.lista[tabela].length) {

                    this.filtro[var_id] = []
                }
                else {

                    this.filtro[var_id] = this.lista[tabela].map(e => e.value)
                }
            }, 100)

            setTimeout(() => { this.carregando = false }, (this.lista[tabela].length > 100 ? this.lista[tabela].length : 110))
        },
        removeItemFiltro(item, filtro) {

            var index = filtro.indexOf(item.value)
            if (index >= 0) filtro.splice(index, 1)
        },
        async filtrar(fnGrafico=true) {

            if (this.modelosLista.length == 0) return
            
            if (!this.$refs.formFiltro.validate()) return

            this.carregando = true

            var data = {id_cargo: this.$store.getters.getCargo.id_cargo}

            data.pagination = this.pagination
            data.filtro = {...this.filtro}
            data.gerencial = 'auditoria'
            data.fnLista = true
            data.fnGrafico = fnGrafico
            data.fnTop = fnGrafico
            data.eixoAlias = this.lista.filtro.find(e => e.tabela == this.topEixo).alias
            data.fnGrupo = fnGrafico
            data.grupo1Alias = this.lista.filtro.find(e => e.tabela == this.grupo1).alias
            data.grupo2Alias = this.lista.filtro.find(e => e.tabela == this.grupo2).alias

            var response = await this.$http().post(`/gerencial/auditoria`, {data: data})   
            
            this.dados = response.data.lista
            this.total = response.data.lista.length ? response.data.lista[0].total*1 : 0

            if(this.total == 0 && this.pagination.page > 1) {

                this.pagination.page = 1
                this.filtrar(false)
            }

            if(fnGrafico) {

                this.execucoes = response.data.execucoes
                
                this.indicadoresTotal[0].valor = response.data.execucoes.reduce((a, e) => a + e.pendentes*1, 0)
                this.indicadoresTotal[0].perc = Math.round (
                    100 * response.data.execucoes.reduce((a, e) => a + e.pendentes*1, 0) / 
                    response.data.execucoes.reduce((a, e) => a + e.total*1, 0)
                )

                this.indicadoresTotal[1].valor = response.data.execucoes.reduce((a, e) => a + e.auditadas*1, 0)
                this.indicadoresTotal[1].perc = Math.round (
                    100 * response.data.execucoes.reduce((a, e) => a + e.auditadas*1, 0) / 
                    response.data.execucoes.reduce((a, e) => a + e.total*1, 0)
                )

                this.indicadoresTotal[2].valor = response.data.execucoes.reduce((a, e) => a + e.executadas*1, 0)
                this.indicadoresTotal[2].perc = Math.round (
                    100 * response.data.execucoes.reduce((a, e) => a + e.executadas*1, 0) / 
                    response.data.execucoes.reduce((a, e) => a + e.total*1, 0)
                )

                this.indicadoresTotal[3].valor = response.data.execucoes.reduce((a, e) => a + e.nao_executadas*1, 0)
                this.indicadoresTotal[3].perc = Math.round (
                    100 * response.data.execucoes.reduce((a, e) => a + e.nao_executadas*1, 0) / 
                    response.data.execucoes.reduce((a, e) => a + e.total*1, 0)
                )
                
                this.graficoExecucoes.xAxis.categories = []
                this.graficoExecucoes.series[0].data = []
                this.graficoExecucoes.series[1].data = []
                this.graficoExecucoes.series[2].data = []
                this.graficoExecucoes.series[3].data = []

                for (const e of this.execucoes) {
                    
                    this.graficoExecucoes.xAxis.categories.push(e.mes)

                    if(this.percentualExecucoes) {

                        this.graficoExecucoes.series[0].data.push(Math.round((e.pendentes*100)/e.total))
                        this.graficoExecucoes.series[1].data.push(Math.round((e.auditadas*100)/e.total))
                        this.graficoExecucoes.series[2].data.push(Math.round((e.executadas*100)/e.total))
                        this.graficoExecucoes.series[3].data.push(Math.round((e.nao_executadas*100)/e.total))
                    }
                    else {

                        this.graficoExecucoes.series[0].data.push(e.pendentes)
                        this.graficoExecucoes.series[1].data.push(e.auditadas)
                        this.graficoExecucoes.series[2].data.push(e.executadas)
                        this.graficoExecucoes.series[3].data.push(e.nao_executadas)
                    }
                }

                this.justificativas = response.data.justificativas
                
                this.graficoJustificativas.xAxis.categories = []
                this.graficoJustificativas.series[0].data = []

                let total = this.justificativas.length ? this.justificativas.map(e => e.total).reduce((a, c) => a*1 + c*1 ) : 0

                for (const j of this.justificativas) {
                    
                    this.graficoJustificativas.xAxis.categories.push(j.justificativa)

                    if(this.percentualJustificativas) {

                        this.graficoJustificativas.series[0].data.push(Math.round((j.total*100)/total))
                    }
                    else {

                        this.graficoJustificativas.series[0].data.push(j.total*1)
                    }
                }

                this.estados = response.data.estados

                this.setaEstatos()

                this.top = response.data.top

                this.setaTop()

                this.dadosGrupoTemp = response.data.grupo

                this.setaGrupo()
            }

            this.carregando = false
        },
        async detalhado(fnPrimeiraBusca=true) {

            if (this.modelosLista.length == 0) return

            if(!this.$refs.formFiltro.validate()) return

            this.dialogoDetalhado = true

            this.carregando = true

            if (fnPrimeiraBusca) {
                
                this.paginationDetalhado = {...this.paginationLimpa}
                return
            }

            var data = {id_cargo: this.$store.getters.getCargo.id_cargo}

            data.pagination = this.paginationDetalhado
            data.filtro = {...this.filtro}
            data.gerencial = 'auditoria'
            data.fnLista = true

            data.filtro.auditoria = this.filtroDetalhado.auditoria == 'indicador' ? this.indicadores.find(e => e.value == this.indicador).text.slice(0, -1) : this.filtroDetalhado.auditoria
            data.filtro.justificativa = this.filtroDetalhado.justificativa 
            data.filtro.estado = this.filtroDetalhado.estado ? this.filtroDetalhado.estado.substr(-2) : false
            data.filtro.nome = this.filtroDetalhado.nome 
            data.topEixo = this.lista.filtro.find(e => e.tabela == this.topEixo)

            if (this.filtroDetalhado.mes) {

                data.filtro.mesInicial = this.filtroDetalhado.mes
                data.filtro.mesFinal = this.filtroDetalhado.mes 
            }

            var response = await this.$http().post('/gerencial/auditoriaDetalhado', {data: data})

            this.dadosDetalhado = response.data.lista

            this.totalDetalhado = response.data.lista[0] ? response.data.lista[0].total : 0

            if(this.totalDetalhado == 0 && this.paginationDetalhado.page > 1) {

                this.paginationDetalhado.page = 1
                this.detalhado()
            }

            this.carregando = false
        },
        limpar() {

            this.filtro = {
                mesInicial: this.mesInicio,
                mesFinal: this.mesAtual,
            }  
            
            for (const t of this.lista.filtro) {
                
                this.filtro[t.var_id] = []
            }

            this.filtrar()
        },
        async exportar(fnDetalhado=false) {

            if (this.modelosLista.length == 0) return
           
            this.carregando = true

            var data = {id_cargo: this.$store.getters.getCargo.id_cargo}

            data.filtro = {...this.filtro}
            data.gerencial = 'auditoria'

            if(fnDetalhado) {

                data.filtro.auditoria = this.filtroDetalhado.auditoria == 'indicador' ? this.indicadores.find(e => e.value == this.indicador).text.slice(0, -1) : this.filtroDetalhado.auditoria
                data.filtro.justificativa = this.filtroDetalhado.justificativa 
                data.filtro.estado = this.filtroDetalhado.estado ? this.filtroDetalhado.estado.substr(-2) : false
                data.filtro.nome = this.filtroDetalhado.nome 
                data.topEixo = this.lista.filtro.find(e => e.tabela == this.topEixo)

                if (this.filtroDetalhado.mes) {

                    data.filtro.mesInicial = this.filtroDetalhado.mes
                    data.filtro.mesFinal = this.filtroDetalhado.mes 
                }
            }

            var response = await this.$http().post(`/gerencial/exportarAuditoriaDetalhado`, {data: data})

            window.location = this.$http('baseURL')+response.data.url
            
            this.carregando = false
        },
        mostraEvidencia(item) {

            this.evidencias.titulo = `Evidências (Acordo: ${item.id_acordo}${item.id_acao ? ' / Ação: '+item.acao : ''}${item.cnpj ? ' / Loja: '+item.cnpj : ''})`
            this.evidencias.lista = item.evidencia
            this.evidencias.dialogo = true
        },
        mostraEvidenciaItem(item) {

            this.evidencia.titulo = `Evidência: ${item.nome}`

            if (item.tipo == 'application/pdf')  {

                this.evidencia.html = `<embed src="${item.evidencia}" type="application/pdf" width="100%" height="450px">`
                this.evidencia.dialogo = true
            }
            else if (item.tipo == 'text/plain')  {

                this.evidencia.html = `<embed src="${item.evidencia}" type="text/plain" width="100%" height="450px">`
                this.evidencia.dialogo = true
            }
            else if (item.tipo == 'application/json')  {

                this.evidencia.html = `<embed src="${item.evidencia}" type="application/json" width="100%" height="450px">`
                this.evidencia.dialogo = true
            }
            else if (item.tipo.split('/')[0] == 'image') {

                this.evidencia.html = `<img src="${item.evidencia}" width="100%"></img>`
                this.evidencia.dialogo = true
            }
            else {

                window.location = item.evidencia
            }
        },
        mudaLayout() {

            this.layoutCompacto = !this.layoutCompacto 

            setTimeout(() => {

                this.$refs.grafico1.chart.reflow()
                this.$refs.grafico2.chart.reflow()
                this.$refs.grafico3.chart.reflow()
                this.$refs.grafico4.chart.reflow()
            }, 100)
        },
        clickPercentualExecucoes() {

            this.percentualExecucoes = !this.percentualExecucoes

            this.graficoExecucoes.title.text = this.percentualExecucoes ? 'Auditorias (%)' : 'Auditorias'

            if(this.percentualExecucoes) {

                this.graficoExecucoes.plotOptions.column.dataLabels.formatter = function () { return this.point.y*1 == 0 ? '' : this.point.y + '%' }
            }
            else {

                this.graficoExecucoes.plotOptions.column.dataLabels.formatter = function () { return this.point.y*1 == 0 ? '' : this.point.y }
            }
            
            this.graficoExecucoes.series[0].data = []
            this.graficoExecucoes.series[1].data = []
            this.graficoExecucoes.series[2].data = []
            this.graficoExecucoes.series[3].data = []

            for (const e of this.execucoes) {
                
                if(this.percentualExecucoes) {

                    this.graficoExecucoes.series[0].data.push(Math.round((e.pendentes*100)/e.total))
                    this.graficoExecucoes.series[1].data.push(Math.round((e.auditadas*100)/e.total))
                    this.graficoExecucoes.series[2].data.push(Math.round((e.executadas*100)/e.total))
                    this.graficoExecucoes.series[3].data.push(Math.round((e.nao_executadas*100)/e.total))
                }
                else {

                    this.graficoExecucoes.series[0].data.push(e.pendentes)
                    this.graficoExecucoes.series[1].data.push(e.auditadas)
                    this.graficoExecucoes.series[2].data.push(e.executadas)
                    this.graficoExecucoes.series[3].data.push(e.nao_executadas)
                }                    
            }
        },
        clickPercentualJustificativas() {

            this.percentualJustificativas = !this.percentualJustificativas

            this.graficoJustificativas.title.text = this.percentualJustificativas ? 'Justificativas de não execução (%)' : 'Justificativas de não execução'
            this.graficoJustificativas.plotOptions.column.dataLabels.format = this.percentualJustificativas ? "{point.y}%" : "{point.y}" 

            let total = this.justificativas.length ? this.justificativas.map( e => e.total).reduce( (a, c) => a*1 + c*1 ) : 0

            this.graficoJustificativas.series[0].data = []

            for (const j of this.justificativas) {

                if(this.percentualJustificativas) {

                    this.graficoJustificativas.series[0].data.push(Math.round((j.total*100)/total))
                }
                else {

                    this.graficoJustificativas.series[0].data.push(j.total*1)
                }
            }
        },
        setaEstatos() {

            this.graficoEstados.title.text = this.percentualEstados ? this.indicadores.find(e => e.value == this.indicador).text + ' (%)' : this.indicadores.find(e => e.value == this.indicador).text
            this.graficoEstados.series[0].name = this.percentualEstados ? this.indicadores.find(e => e.value == this.indicador).text + ' (%)' : this.indicadores.find(e => e.value == this.indicador).text
            this.graficoEstados.series[0].tooltip.valueSuffix = this.percentualEstados ? '%' : ''
            this.graficoEstados.colorAxis.labels.format = this.percentualEstados ? "{value}%" : "{value}" 
            this.graficoEstados.colorAxis.minColor = this.indicadores.find(e => e.value == this.indicador).minColor 
            this.graficoEstados.colorAxis.maxColor = this.indicadores.find(e => e.value == this.indicador).maxColor

            let estados = [
                'sp', 'ma', 'pa', 'sc', 'ba', 'ap', 'ms', 'mg', 'go', 'rs',
                'to', 'pi', 'al', 'pb', 'ce', 'se', 'rr', 'pe', 'pr', 'es',
                'rj', 'rn', 'am', 'mt', 'df', 'ac', 'ro',
            ]

            this.graficoEstados.series[0].data = []

            for (const est of estados) {

                let estado = this.estados.find(e => e.estado == est)

                let valor = 0

                if(estado && estado[this.indicador]*1 > 0) {

                    valor = this.percentualEstados ? Math.round(estado[this.indicador]*100/estado.total) : estado[this.indicador]*1
                }

                this.graficoEstados.series[0].data.push(['br-'+est, valor])
            }
        },
        async setaTop(fnTop=false) {

            if (this.modelosLista.length == 0) return

            if(fnTop) {

                if (!this.$refs.formFiltro.validate()) return

                this.carregando = true

                var data = {id_cargo: this.$store.getters.getCargo.id_cargo}

                data.filtro = {...this.filtro}
                data.gerencial = 'auditoria'
                data.fnTop = fnTop
                data.eixoAlias = this.lista.filtro.find(e => e.tabela == this.topEixo).alias

                var response = await this.$http().post(`/gerencial/auditoria`, {data: data})

                this.top = response.data.top

                this.carregando = false
            }

            //if(this.top.length == 0) return

            this.graficoTop.title.text = this.topNome
            this.graficoTop.plotOptions.bar.dataLabels.format = this.percentualTop ? '{point.y}%' : '{point.y}'
            this.graficoTop.series[0].color = this.indicadores.find(e => e.value == this.indicador).maxColor

            let sortTop = this.top.map(e => ({...{
                nome: e.nome, 
                valor: this.percentualTop ? Math.round(e[this.indicador]*100/e.total*1) : e[this.indicador]*1,
            }}))

            sortTop = sortTop.sort((a,b) => b.valor - a.valor)

            this.graficoTop.series[0].data = []
            this.graficoTop.xAxis.categories = []

            let topNumero = 10

            let elementos = sortTop.length >= topNumero ? topNumero : sortTop.length 

            for (let i = 0; i < elementos; i++) {

                this.graficoTop.xAxis.categories.push(sortTop[i].nome)
                this.graficoTop.series[0].data.push(sortTop[i].valor)
            }
        },
        async setaGrupo(fnGrupo=false) {

            if (this.modelosLista.length == 0) return
            
            if(fnGrupo) {

                if (!this.$refs.formFiltro.validate()) return

                if (this.grupo1 == this.grupo2) {

                    this.alerta = true
                    this.alertaColor = 'error'
                    this.alertaTimeout = 3000
                    this.alertaTexto = 'Os atributos selecionados não podem ser iguais.'
                    return
                }

                this.carregando = true

                var data = {id_cargo: this.$store.getters.getCargo.id_cargo}

                data.filtro = {...this.filtro}
                data.gerencial = 'auditoria'
                data.fnGrupo = fnGrupo
                data.grupo1Alias = this.lista.filtro.find(e => e.tabela == this.grupo1).alias
                data.grupo2Alias = this.lista.filtro.find(e => e.tabela == this.grupo2).alias

                var response = await this.$http().post(`/gerencial/auditoria`, {data: data})

                this.dadosGrupoTemp = response.data.grupo

                this.carregando = false
            }

            this.headersGrupo[0].text = this.lista.filtro.find(e => e.tabela == this.grupo1).plural
            this.headersGrupo[1].text = this.lista.filtro.find(e => e.tabela == this.grupo2).plural

            let count = 0

            for (const g of this.dadosGrupoTemp) {

                g.grupoOrdem = g.grupo
                g.chave = count
                count++
                
                for (const h of this.headersGrupo) {

                    if(h.fnTotal) {

                        g[h.value + '_total'] = this.dadosGrupoTemp.filter(e => e.grupo == g.grupo).reduce((a, b) => a + b[h.value]*1, 0)
                    }
                }

                for (const h of this.headersGrupo) {

                    if(h.fnPercentual) {

                        let indicador = h.value.substr(0, h.value.length - 2)

                        g[h.value] = Math.round( g[indicador]*100 / g.total*1 )

                        g[h.value + '_total'] = Math.round( g[indicador + '_total' ]*100 / g.total_total*1 )
                    }
                }
            }

            this.csvGrupo()

            this.ordenaGrupo()
        },
        ordenaGrupo() {

            let coluna = !this.sortByGrupo[0] ? 'grupo' : this.sortByGrupo[0]
            let ordemDesc = !this.sortDescGrupo[0] ? false : true
            let fnPercentual = this.headersGrupo.find(e => e.value == coluna).fnPercentual

            let grupos = []

            if(coluna == 'grupo' || coluna == 'nome') {

                if(ordemDesc) {

                    grupos = this.dadosGrupoTemp.sort((a,b) => (b[coluna] > a[coluna]) ? 1 : ((a[coluna] > b[coluna]) ? -1 : 0))
                }
                else {

                    grupos = this.dadosGrupoTemp.sort((a,b) => (a[coluna] > b[coluna]) ? 1 : ((b[coluna] > a[coluna]) ? -1 : 0))
                }
            }
            else {

                let percentual = fnPercentual ? 1000000 : 1

                if(ordemDesc) {

                    grupos = this.dadosGrupoTemp.sort((a,b) => (b[coluna + '_total']*percentual + b[coluna]*1)  - (a[coluna + '_total']*percentual + a[coluna]*1))
                }
                else {

                    grupos = this.dadosGrupoTemp.sort((a,b) => (a[coluna + '_total']*percentual + a[coluna]*1)  - (b[coluna + '_total']*percentual + b[coluna]*1))
                }
            }

            let ordem = 1
            let grupoOrdem = []

            for (const g of grupos) {

                if(grupoOrdem[g.grupo]) {

                    g.grupoOrdem = grupoOrdem[g.grupo]
                }
                else {

                    grupoOrdem[g.grupo] = ordem
                    g.grupoOrdem = ordem
                    ordem++
                }
            }

            this.dadosGrupo = grupos

            //fecha grupos
            setTimeout(() => {
                    for (const e of  document.getElementsByClassName("grupos")) {
                    if (e.getAttribute('data-open')) e.click()
                }
            }, 100)
        },
        csvGrupo() {

            this.grupoCsv.arquivo = this.headersGrupo[0].text + '_' + this.headersGrupo[1].text + '.csv'
            this.grupoCsv.label = {}
            this.grupoCsv.colunas = []
            this.grupoCsv.dados = []

            for (const h of this.headersGrupo) {

                this.grupoCsv.label[h.value] = h.text
                this.grupoCsv.colunas.push(h.value)
            }

            let dados = this.dadosGrupoTemp.sort((a,b) => (a.grupo > b.grupo) ? 1 : ((b.grupo > a.grupo) ? -1 : 0))

            let grupoAnterior = ''

            for (const d of dados) {

                if(grupoAnterior != d.grupo) {
                
                    let grupo = {}

                    for (const h of this.headersGrupo) {
                        
                        if(h.fnTotal) {

                            grupo[h.value] = d[h.value + '_total'].toLocaleString('pt-br')
                        }
                        if(h.fnPercentual) {

                            grupo[h.value] = d[h.value + '_total'] + '%'
                        }
                        if(h.value == 'grupo') {

                            grupo[h.value] = d[h.value]
                        }
                        if(h.value == 'nome') {

                            grupo[h.value] = ''
                        }
                    }

                    this.grupoCsv.dados.push(grupo)
                }

                grupoAnterior = d.grupo

                let item = {}

                for (const h of this.headersGrupo) {
                    
                    if(h.fnTotal) {

                        item[h.value] = d[h.value].toLocaleString('pt-br')
                    }
                    if(h.fnPercentual) {

                        item[h.value] = d[h.value] + '%'
                    }
                    if(h.value == 'grupo') {

                        item[h.value] = ''
                    }
                    if(h.value == 'nome') {

                        item[h.value] = d[h.value]
                    }
                }

                this.grupoCsv.dados.push(item)
            }
        },
        async exportarFotos() {

            if (this.modelosLista.length == 0) return
            
            if (!this.$refs.formFiltro.validate()) return

            let corPrimary = this.$helpers.classeCor(this.$vuetify, 'primary-base')
            let corPrimaryLighten1 = this.$helpers.classeCor(this.$vuetify, 'primary-lighten1')
            let corPrimaryLighten2 = this.$helpers.classeCor(this.$vuetify, 'primary-lighten3')

            this.fotos = 0
            this.index = 0
            this.arquivo = 1

            this.carregando = true

            var data = {id_cargo: this.$store.getters.getCargo.id_cargo}

            data.filtro = {...this.filtro}
            data.gerencial = 'auditoria'
            data.fnLista = true
            data.evidencia = true

            var response = await this.$http().post('/gerencial/auditoria', {data: data})

            var lista = []

            for (const a of response.data.lista) {

                if (!a.evidencia || !a.evidencia.length) continue
                
                let item = 1
                let itens = []

                for (const e of a.evidencia) {

                    if (e.tipo.split('/')[0] == 'image') {

                        let obj = {...a}
                        obj.item = {...e}
                        obj.item.elemento = 'Evidência: ' + item + ' de ' + a.evidencia.length

                        item++

                        lista.push(obj)
                    }
                    else {

                        itens.push(e)
                    }
                }

                if (itens.length > 0) {

                    let obj = {...a}
                    obj.item = itens
                    obj.item.tipo = 'lista'
                    obj.item.elemento = itens.length > 1 ? 'Evidência: ' + item + ' - ' + a.evidencia.length + ' de ' + a.evidencia.length : 'Evidência: ' + item + ' de ' + a.evidencia.length

                    lista.push(obj)
                }
            }

            this.fotos = lista.length

            if (this.fotos == 0) {

                this.carregando = false

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = 3000
                this.alertaTexto = 'Não existem fotos para serem exportadas.'

                return
            }

            this.dialogPPT = true

            var pptx = []

            var hora = this.$moment().format("DD-MM-YYYY_hh-mm-ss")

            for (let i = 1; i <= Math.ceil(lista.length/100); i++) {

                pptx[i] = {}    
                
                pptx[i].nome = `Evidencias_auditorias_${this.$store.getters.getCliente}_${hora}_arquivo_${i}.pptx`

                pptx[i].pptx = new this.$pptxgen()

                pptx[i].pptx.author = 'IPDV'
                pptx[i].pptx.company = 'IPDV'
                pptx[i].pptx.subject = 'Auditorias'
                pptx[i].pptx.title = 'Evidência Auditorias' 
            }

            const proximaFoto = (i) => {

                let e = lista[i]

                if(e.item.tipo.split('/')[0] == 'image') {

                    e.image = e.item.evidencia
                }
                else {

                    e.fnArquivo = true
                    e.image = require("@/assets/images/icone-pdf.png")
                }

                let img = new Image()

                img.addEventListener("load", () => {

                    this.index = Math.ceil(100*(i/lista.length))

                    //slida comprimento e altura = 10 / 5,62519685
                    let slide = pptx[this.arquivo].pptx.addSlide()

                    let texto = ''
                    texto += 'Contrato: ' + e.id_contrato + '      ' + 'Acordo: ' + e.id_acordo + '      ' + 'Mês: ' + e.mes_nome + '      ' + e.item.elemento
                    texto += '\n'
                    texto += 'Razão Social: ' + e.loja_razao_social + '      ' + 'CNPJ PAGADOR: ' + e.loja_cnpj
                    texto += e.cnpj_filial ? '\n' + 'Razão Social: ' + e.razao_social_filial + '      ' + 'CNPJ FILIAL: ' + e.cnpj_filial : ''
                    texto += '\n'
                    texto += 'Usuário que Comprovou: ' + e.usuario

                    slide.addText(texto, { 
                        x: 0, 
                        y: 0, 
                        w: 10,
                        h: 0.7,
                        color: '#ffffff', 
                        fontSize: 9,
                        fill: '#'+corPrimary,
                        bold: true,
                    })

                    let linha = []

                    for (const c of this.camposInvestimentoPpt) {

                        linha.push({ text: c.nome, options: {fill: corPrimary, color: "ffffff", bold: true,} })
                    }

                    linha.push({ text: "Investimento", options: {fill: corPrimary, color: "ffffff", bold: true,} })

                    let rows = [linha]

                    for (let p = 0; p < e.produtos.length; p++) {
                        
                        let linha = []

                        for (const c of this.camposInvestimentoPpt) {

                            linha.push({ text: e.produtos[p][c.tabela+'_'+c.campo], options: {fill: p % 2 ? corPrimaryLighten1 : corPrimaryLighten2} })
                        }

                        linha.push({ text: e.produtos[p].investimento, options: {fill: p % 2 ? corPrimaryLighten1 : corPrimaryLighten2} })

                        rows.push(linha)
                    }
                    slide.addTable(rows, { 
                        x: 4.9, 
                        y: 0.9,
                        w: 4.9,
                        h: 4.5,
                        rowH: 0.2,
                        color: '#000000',
                        fontSize: 7,
                        fill: '#cccccc',
                        align: 'center',
                        border: {
                            pt: 1,
                            color: '#ffffff',
                            type: 'solid',
                        },
                        autoPage: true,
                        autoPageRepeatHeader: true,
                    })  

                    let altura = 4.5
                    let comprimento = 4.5

                    if(img.naturalWidth > img.naturalHeight) { //foto na horizontal
                        
                        altura = Math.round(100*comprimento*img.naturalHeight/img.naturalWidth)/100
                    }
                    else {  //foto na vertical

                        comprimento = Math.round(100*altura*img.naturalWidth/img.naturalHeight)/100
                    }

                    let imagenObj = {
                        path: e.image, 
                        x: 0.2, 
                        y: 0.9,
                        w: comprimento, 
                        h: altura,
                    }

                    if(e.fnArquivo) {

                        let y = 0.9

                        for (const item of e.item) {

                            slide.addText(item.nome, { 
                                x: 0.1, 
                                y: y, 
                                w: 4.8,
                                h: 0.3,
                                //fill: '#ffffff', 
                                fontSize: 10,
                                color: '#'+corPrimary,
                                bold: true,
                                hyperlink: {url: item.evidencia_link},
                            })

                            y = y + 0.3
                        }
                    }
                    else {

                        slide.addImage(imagenObj)
                    }

                    

                    if((i+1) == lista.length  || (i+1) % 100 == 0) {

                        pptx[this.arquivo].pptx.writeFile({fileName: pptx[this.arquivo].nome})
                        .then((data) => {

                            console.log(data)

                            pptx[this.arquivo] = null

                            this.arquivo++

                            if((i+1) == lista.length){

                                this.carregando = false
                                this.dialogPPT = false
                            } 
                            else {

                                proximaFoto(i+1)
                            }  
                        })
                        .catch((err) => {

                            console.log(err)  

                            this.carregando = false
                            this.dialogPPT = false
                        })
                    } 
                    else {
                    
                        proximaFoto(i+1)
                    }
                })

                img.addEventListener("error", () => {

                    if((i+1) == lista.length  || (i+1) % 100 == 0) {

                        pptx[this.arquivo].pptx.writeFile({fileName: pptx[this.arquivo].nome})
                        .then((data) => {

                            console.log(data)  

                            pptx[this.arquivo] = null

                            this.arquivo++

                            if((i+1) == lista.length){

                                this.carregando = false
                                this.dialogPPT = false
                            } 
                            else {

                                proximaFoto(i+1)
                            }  
                        })
                        .catch((err) => {

                            console.log(err)  

                            this.carregando = false
                            this.dialogPPT = false
                        })
                    } 
                    else {
                    
                        proximaFoto(i+1)
                    } 
                })
            
                img.crossOrigin = 'anonymous'
                img.src = e.image
            } 

            if(lista.length){

                proximaFoto(0)
            } 
            else {

                this.carregando = false
                this.dialogPPT = false

                this.alerta = true
                this.alertaColor = 'info'
                this.alertaTimeout = 3000
                this.alertaTexto = 'Nenhuma foto encontrada.'
            }
        },
        async buscaAuditoriasHistorico(item) {

            this.carregando = true
            
            this.auditoriasHistorico.dados = []
            
            this.auditoriasHistorico.titulo = 'Auditorias Histórico (Acordo: '+item.id_acordo+' / Auditoria: '+item.id_auditoria+')'

            var response = await this.$http().post('/acordo/auditoriasHistorico', {data: {id_auditoria: item.id_auditoria}})

            this.auditoriasHistorico.dados = response.data

            this.auditoriasHistorico.dialogo = true
            this.carregando = false
        },
     },
}

</script>
