<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-toolbar-title class="ml-2 mt-2">{{titulo}}</v-toolbar-title>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">   
                <v-btn
                    class="ml-2 my-1"
                    color="primary"
                    @click="novo()"
                >
                    <v-icon left>mdi-plus</v-icon> Criar
                </v-btn>    
                <v-btn
                    class="ml-2 my-1"
                    color="primary"
                    @click="exportar()"
                >
                    <v-icon left>mdi-microsoft-excel</v-icon> Exportar
                </v-btn>
            </v-col>
        </v-row>
        <v-card class="mt-2 py-4 px-2" elevation="1">
            <v-row no-gutters class="mb-4">
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                        label="Ativos"
                        :items="[{text:'Sim', value: 1},{text:'Não', value: 0},{text:'Todos', value: null}]"
                        v-model="ativo"
                        dense 
                        outlined
                        hide-details
                    >
                    </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="px-2">
                    <v-select
                        label="Colunas"
                        :items="headers"
                        v-model="selectHeaders"
                        dense 
                        multiple
                        clearable
                        outlined
                        hide-details
                    >
                    </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4" align="end">
                    <v-text-field
                        v-model="busca"
                        append-icon="mdi-magnify"
                        label="Pesquisar"
                        dense 
                        outlined
                        hide-details
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-data-table
                :headers="computedHeaders"
                :items="dados"
                :server-items-length="total" 
                :items-per-page="5"
                :footer-props="{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100]
                }"
                :options.sync="pagination"
                multi-sort
                class="elevation-0"
            >
                <template v-slot:[`item.modelos_nome`]="{ item }"> 
                    <div v-for="(value, index) in (item.modelos_nome ? item.modelos_nome.split(', ') : [])" :key="index">{{value}}</div>
                </template>
                <template v-slot:[`item.status_entrada_nome`]="{ item }"> 
                    <div v-for="(value, index) in (item.status_entrada_nome ? item.status_entrada_nome.split(', ') : [])" :key="index">{{value}}</div>
                </template>
                <template v-slot:[`item.status_saida`]="{ item }"> 
                    <div v-for="(value, index) in item.status_saida" :key="index">
                        <div>{{value.nome}}</div>
                        <div v-if="value.regra" class="info--text">{{value.regra}}</div>
                    </div>
                </template>
                <template v-slot:[`item.padrao`]="{ item }"> 
                    {{(item.padrao ? 'sim' : 'não')}}
                </template>
                <template v-slot:[`item.botao`]="{ item }"> 
                    <v-icon
                        small
                        class="mr-2"
                        color="primary"
                        @click="editar(item)"
                        title="Editar"
                    >
                        mdi-pencil
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog
            v-model="dialogo"
            width="90%"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{tituloDialogo}}
                </v-card-title>
                <v-card-text class="mt-4"> 
                    <v-form ref="form">
                        <v-tabs v-model="tab" class="mb-4">
                            <v-tab>Dados do Processo</v-tab>
                            <v-tab v-if="obj.id_processo">Justificativas</v-tab>
                            <v-tab v-if="obj.id_processo">Modelos</v-tab>
                            <v-tab v-if="obj.id_processo">Status de Entrada</v-tab>
                            <v-tab v-if="obj.id_processo">Status de Saida</v-tab>
                            <v-tab v-if="obj.id_processo && obj.function=='alteraStatus'">Importar Lote</v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab">
                            <v-tab-item key="Dados do Processo">
                                <v-row no-gutters class="mt-4">
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            disabled
                                            label="ID Processo"
                                            v-model="obj.id_processo"
                                        ></v-text-field> 
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="Processo (Nome do botão)"
                                            v-model="obj.nome"
                                            :rules="regra.obrigatorio"
                                            :disabled="obj.padrao"
                                        ></v-text-field> 
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="Descrição (Descriçao no histórico)"
                                            v-model="obj.desc"
                                            :rules="regra.obrigatorio"
                                            :disabled="obj.padrao"
                                        ></v-text-field> 
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="Ordem"
                                            v-model="obj.ordem"
                                            :rules="regra.obrigatorio"
                                            :disabled="obj.padrao"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4" lg="4" xl="3" class="px-2"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="Icone (mdi- + icone)"
                                            v-model="obj.icon"
                                            :rules="regra.obrigatorio"
                                            :disabled="obj.padrao"
                                        ></v-text-field> 
                                    </v-col>
                                    <v-col cols="12" sm="12" md="2" lg="2" xl="1" class="px-2"> 
                                        <v-btn
                                            icon
                                            color="primary"
                                            title="Icones de Referência"
                                            @click="ajuda"
                                        >
                                            <v-icon>mdi-comment-question</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2">
                                        <v-select
                                            label="Cor"
                                            :items="lista.color"
                                            v-model="obj.color"
                                            :rules="regra.obrigatorio"
                                            :disabled="obj.padrao"
                                            dense 
                                            outlined
                                        >
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="pt-2 px-2" v-if="obj.icon && obj.color && obj.nome">
                                        <v-icon :color="obj.color ? obj.color : 'primary'">{{obj.icon}}</v-icon> <b>{{obj.nome}}</b>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2">
                                        <v-switch
                                            label="Confirmar"
                                            v-model="obj.confirm"
                                            :disabled="obj.padrao"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="Confirmação Mensagem"
                                            v-model="obj.confirm_msg"
                                            :rules="regra.confirm"
                                            :disabled="!obj.confirm"
                                        ></v-text-field> 
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="Confirmação Mensagem Seleção Multipla"
                                            v-model="obj.confirm_msg_multiple"
                                            :rules="regra.confirm"
                                            :disabled="!obj.confirm"
                                        ></v-text-field> 
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2">
                                        <v-switch
                                            label="Upload de Arquivo"
                                            v-model="obj.upload"
                                            :disabled="!obj.confirm"
                                            class="mb-2"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2">
                                        <v-switch
                                            label="Upload de Arquivo Obrigatório"
                                            v-model="obj.upload_obrigatorio"
                                            :disabled="!obj.confirm"
                                            class="mb-2"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                        <v-text-field
                                            outlined
                                            dense 
                                            label="Nome do Upload"
                                            v-model="obj.upload_nome"
                                            :rules="regra.upload"
                                            :disabled="!obj.confirm"
                                        ></v-text-field> 
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2">
                                        <v-select
                                            label="Função"
                                            :items="lista.function"
                                            v-model="obj.function"
                                            :rules="regra.obrigatorio"
                                            :disabled="obj.padrao"
                                            dense 
                                            outlined
                                        >
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2" v-show="obj.function=='comprovaAcordo'">
                                        <v-switch
                                            label="Editar Investimento"
                                            v-model="obj.editar_investimento"
                                            class="mb-2"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="px-2"> 
                                        <v-select
                                            label="Ativo"
                                            :items="[{text:'Sim', value: 1},{text:'Não', value: 0}]"
                                            v-model="obj.ativo"
                                            :disabled="obj.padrao"
                                            outlined
                                            dense 
                                        >
                                        </v-select>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item key="Justificativas">
                                <v-row 
                                    no-gutters 
                                    class="mb-2"
                                >
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" align="end">
                                        <v-btn
                                            color="primary"
                                            class="pr-4"
                                            @click="novaObj('justificativa')"
                                            :disabled="obj.padrao || (!obj.confirm && !['comprovaAcordo', 'validarAuditoria'].includes(obj.function))"
                                        >
                                            <v-icon left>mdi-plus</v-icon> Adicionar Justificativa
                                        </v-btn> 
                                    </v-col>
                                </v-row>
                                <v-data-table
                                    :headers="justificativa.headers"
                                    :items="justificativa.dados"
                                    :items-per-page="5"
                                    :footer-props="{
                                        'items-per-page-options': [5, 10, 15, 25, 50, 100]
                                    }"
                                    :options.sync="justificativa.pagination"
                                    class="elevation-0"
                                >
                                    <template v-slot:[`item.remover`]="{ item }"> 
                                        <v-icon
                                            small
                                            class="mr-2"
                                            color="primary"
                                            @click="removerConfirm(item, 'justificativa')"
                                            title="Remover Justificativa"
                                            :disabled="obj.padrao"
                                        >
                                            mdi-trash-can
                                        </v-icon>
                                    </template>
                                </v-data-table>
                            </v-tab-item>
                            <v-tab-item key="Modelos">
                                <v-row 
                                    no-gutters 
                                    class="mb-2"
                                >
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" align="end">
                                        <v-btn
                                            color="primary"
                                            class="pr-4"
                                            @click="novaObj('modelo')"
                                        >
                                            <v-icon left>mdi-plus</v-icon> Adicionar Modelo
                                        </v-btn> 
                                    </v-col>
                                </v-row>
                                <v-data-table
                                    :headers="modelo.headers"
                                    :items="modelo.dados"
                                    :items-per-page="5"
                                    :footer-props="{
                                        'items-per-page-options': [5, 10, 15, 25, 50, 100]
                                    }"
                                    :options.sync="modelo.pagination"
                                    class="elevation-0"
                                >
                                    <template v-slot:[`item.remover`]="{ item }"> 
                                        <v-icon
                                            small
                                            class="mr-2"
                                            color="primary"
                                            @click="removerConfirm(item, 'modelo')"
                                            title="Remover Modelo"
                                            :disabled="obj.padrao"
                                        >
                                            mdi-trash-can
                                        </v-icon>
                                    </template>
                                </v-data-table>
                            </v-tab-item>
                            <v-tab-item key="Status de Entrada">
                                <v-row 
                                    no-gutters 
                                    class="mb-2"
                                >
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" align="end">
                                        <v-btn
                                            color="primary"
                                            class="pr-4"
                                            @click="novaObj('status_entrada')"
                                            :disabled="obj.padrao"
                                        >
                                            <v-icon left>mdi-plus</v-icon> Adicionar Status
                                        </v-btn> 
                                    </v-col>
                                </v-row>
                                <v-data-table
                                    :headers="status_entrada.headers"
                                    :items="status_entrada.dados"
                                    :items-per-page="5"
                                    :footer-props="{
                                        'items-per-page-options': [5, 10, 15, 25, 50, 100]
                                    }"
                                    :options.sync="status_entrada.pagination"
                                    class="elevation-0"
                                >
                                    <template v-slot:[`item.remover`]="{ item }"> 
                                        <v-icon
                                            small
                                            class="mr-2"
                                            color="primary"
                                            @click="removerConfirm(item, 'status_entrada')"
                                            title="Remover Status"
                                            :disabled="obj.padrao"
                                        >
                                            mdi-trash-can
                                        </v-icon>
                                    </template>
                                </v-data-table>
                            </v-tab-item>
                            <v-tab-item key="Status de Saida">
                                <v-row 
                                    no-gutters 
                                    class="mb-2"
                                >
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" align="end">
                                        <v-btn
                                            color="primary"
                                            class="pr-4"
                                            @click="novaObj('status_saida')"
                                            :disabled="obj.padrao"
                                        >
                                            <v-icon left>mdi-plus</v-icon> Adicionar Status
                                        </v-btn> 
                                    </v-col>
                                </v-row>
                                <v-data-table
                                    :headers="status_saida.headers"
                                    :items="status_saida.dados"
                                    :items-per-page="5"
                                    :footer-props="{
                                        'items-per-page-options': [5, 10, 15, 25, 50, 100]
                                    }"
                                    :options.sync="status_saida.pagination"
                                    class="elevation-0"
                                >
                                    <template v-slot:[`item.remover`]="{ item }"> 
                                        <v-icon
                                            small
                                            class="mr-2"
                                            color="primary"
                                            @click="removerConfirm(item, 'status_saida')"
                                            title="Remover Status"
                                            :disabled="obj.padrao"
                                        >
                                            mdi-trash-can
                                        </v-icon>
                                    </template>
                                </v-data-table>
                            </v-tab-item>
                            <v-tab-item key="Importar Lote">
                                <v-row 
                                    no-gutters 
                                    class="mb-2"
                                >
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2">
                                        <v-switch
                                            label="Abilitar Importação"
                                            v-model="obj.importar_lote"
                                            :disabled="obj.padrao"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" align="end">
                                        <v-btn
                                            color="primary"
                                            class="pr-4"
                                            @click="novaObj('importar_lote')"
                                            :disabled="obj.padrao"
                                        >
                                            <v-icon left>mdi-plus</v-icon> Adicionar Atributo
                                        </v-btn> 
                                    </v-col>
                                </v-row>
                                <v-data-table
                                    :headers="importar_lote.headers"
                                    :items="importar_lote.dados"
                                    :items-per-page="5"
                                    :footer-props="{
                                        'items-per-page-options': [5, 10, 15, 25, 50, 100]
                                    }"
                                    :options.sync="importar_lote.pagination"
                                    class="elevation-0"
                                >
                                    <template v-slot:[`item.remover`]="{ item }"> 
                                        <v-icon
                                            small
                                            class="mr-2"
                                            color="primary"
                                            @click="removerConfirm(item, 'importar_lote')"
                                            title="Remover Atributo"
                                            :disabled="obj.padrao"
                                        >
                                            mdi-trash-can
                                        </v-icon>
                                    </template>
                                </v-data-table>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="salvar()"
                        :disabled="obj.padrao"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="justificativa.dialogo"
            width="90%"
        >
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Adicionar Justificativa
                </v-card-title>
                <v-card-text class="mt-2">
                    <v-form ref="formJustificativa">
                        <v-row no-gutters class="mt-4">
                            <v-col cols="12" class="px-2">
                                <v-text-field
                                    outlined
                                    dense 
                                    label="Justificativa"
                                    v-model="justificativa.obj.justificativa"
                                    :rules="regra.obrigatorio"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="justificativa.dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="salvarObj('justificativa')"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="modelo.dialogo"
            width="90%"
        >
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Adicionar Modelo
                </v-card-title>
                <v-card-text class="mt-2">
                    <v-form ref="formModelo">
                        <v-row no-gutters class="mt-4">
                            <v-col cols="12" class="px-2">
                                <v-select
                                    label="Modelo"
                                    :items="lista.modelo.filter(e => !obj.modelos.includes(e.id_modelo))"
                                    v-model="modelo.obj.id_modelo"
                                    :rules="regra.obrigatorio"
                                    outlined
                                    dense 
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="modelo.dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="salvarObj('modelo')"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="status_entrada.dialogo"
            width="90%"
        >
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Adicionar Status de Entrada
                </v-card-title>
                <v-card-text class="mt-2">
                    <v-form ref="formStatus_entrada">
                        <v-row no-gutters class="mt-4">
                            <v-col cols="12" class="px-2">
                                <v-select
                                    label="Status"
                                    :items="lista.status.filter(e => !obj.status_entrada.includes(e.id_status))"
                                    v-model="status_entrada.obj.id_status"
                                    :rules="regra.obrigatorio"
                                    outlined
                                    dense 
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="status_entrada.dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="salvarObj('status_entrada')"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="status_saida.dialogo"
            width="90%"
        >
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Adicionar Status de Saída
                </v-card-title>
                <v-card-text class="mt-2">
                    <v-form ref="formStatus_saida">
                        <v-row no-gutters class="mt-4">
                            <v-col cols="12" class="px-2">
                                <v-select
                                    label="Status"
                                    :items="lista.status.filter(e => !obj.status_saida.map(e => e.id_status).includes(e.id_status))"
                                    v-model="status_saida.obj.id_status"
                                    :rules="regra.obrigatorio"
                                    outlined
                                    dense 
                                >
                                </v-select>
                            </v-col>
                            <v-col cols="12" class="px-2" v-if="obj.function!='validarAuditoria'">
                                <v-text-field
                                    outlined
                                    dense 
                                    label="Regra"
                                    v-model="status_saida.obj.regra"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" class="px-2" v-if="obj.function=='validarAuditoria'">
                                <v-select
                                    label="Regra"
                                    :items="['Validado', 'Reprovado']"
                                    v-model="status_saida.obj.regra"
                                    :rules="regra.obrigatorio"
                                    outlined
                                    dense 
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="status_saida.dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="salvarObj('status_saida')"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="importar_lote.dialogo"
            width="90%"
        >
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Adicionar Atributo
                </v-card-title>
                <v-card-text class="mt-2">
                    <v-form ref="formImportar_lote">
                        <v-row no-gutters class="mt-4">
                            <v-col cols="12" class="px-2">
                                <v-select
                                    label="Status"
                                    :items="lista.campos.filter(e => !obj.campos.map(e => e.campo).includes(e.value))"
                                    v-model="importar_lote.obj.campo"
                                    :rules="regra.obrigatorio"
                                    outlined
                                    dense 
                                    v-on:change="changeCampoNovo"
                                >
                                </v-select>
                            </v-col>
                            <v-col cols="12" class="px-2">
                                <v-text-field
                                    outlined
                                    dense 
                                    disabled
                                    label="Campo"
                                    v-model="importar_lote.obj.campo"
                                ></v-text-field> 
                            </v-col>
                            <v-col cols="12" class="px-2">
                                <v-text-field
                                    outlined
                                    dense 
                                    label="Nome"
                                    v-model="importar_lote.obj.nome"
                                    :rules="regra.obrigatorio"
                                ></v-text-field> 
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="importar_lote.dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="salvarObj('importar_lote')"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="confirm.dialogo"
            width="600"
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{confirm.titulo}}
                </v-card-title>
                <v-card-text class="mt-4"> 
                    {{confirm.texto}}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="confirm.dialogo=false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="error"
                        class="pr-4"
                        @click="confirm.funcao"
                    >
                        <v-icon left>mdi-close</v-icon> Remover
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        
        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn
                dark
                text
                class="float-right"
                @click="alerta=false"
            >
            Fechar
            </v-btn>
        </v-snackbar>

    </v-container>
</template>

<script>

export default {
    data() {
        return {
            tab: null,
            tabela: 'processo',
            titulo: 'Processos',
            tituloDialogo: 'Processo',
            nomeCampo: null,
            headers: [
                { text: 'ID Processo', value: 'id_processo', align: 'center'},
                { text: 'Menu', value: 'botao', align: 'center', sortable: false },
                { text: 'Processo', value: 'nome', align: 'center'},
                { text: 'Descrição', value: 'desc', align: 'center'},
                { text: 'Função', value: 'function_nome', align: 'center'},
                { text: 'Status de Entrada', value: 'status_entrada_nome', align: 'center'},
                { text: 'Status de Saída', value: 'status_saida', align: 'center'},
                { text: 'Modelos', value: 'modelos_nome', align: 'center'},
                { text: 'Ordem', value: 'ordem', align: 'center'},
                { text: 'Ativo', value: 'ativo_nome', align: 'center'},
                { text: 'Padrão', value: 'padrao', align: 'center'},
                { text: 'Menu', value: 'botao', align: 'center', sortable: false },
            ],
            selectHeaders: [],
            lista: {
                color: [
                    {text:'primary (cor padão)', value: 'primary'},
                    {text:'success (verde padão)', value: 'success'},
                    {text:'error (vermelho padão)', value: 'error'},
                ],
                function: [
                    {text:'Criação / Visualisação', value: 'novo'},
                    {text:'Edição do Investimento', value: 'editaAcordo'},
                    {text:'Edição do Acordo', value: 'editaAcordoCompleto'},
                    {text:'Alteração de Status', value: 'alteraStatus'},
                    {text:'Alteração de Status e Reprova Auditorias', value: 'reprovaAuditorias'},
                    {text:'Auditoria de Acordos', value: 'comprovaAcordo'},
                    {text:'Validação de Auditorias', value: 'validarAuditoria'},
                    {text:'Gerencial de Auditorias', value: 'gerencialAuditoria'},
                    {text:'Envio do Recibo', value: 'enviaRecibo'},
                ],
                modelo: [],
                status: [],
                campos: [
                    { text: 'ID Acordo', value: 'id_acordo'},
                    { text: 'ID Contrato', value: 'id_contrato'},
                    { text: 'Mês do Acordo', value: 'mes'},
                    { text: 'Acordo Cliente 1', value: 'modelo_acordo_1'},
                    { text: 'Acordo Cliente 2', value: 'modelo_acordo_2'},
                    { text: 'Acordo Cliente 3', value: 'modelo_acordo_3'},
                ],
            },
            ads: [],
            dados: [], 
            objNovo: {
                id_status: null,
                nome: null,
                ordem: null,
                ativo: 1,
                modelos: [],
                status_entrada: [],
                status_saida: [],
                campos: [],
                editar_investimento: true,
            },
            obj: {
                modelos: [],
                status_entrada: [],
                status_saida: [],
                campos: [],
            },
            pagination: {}, 
            total: null,
            carregando: false, 
            ativo: 1,
            busca: null, 
            dialogo: false,
            validaForm: false,
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            regra: { 
                obrigatorio: [
                    v => !!v || 'Campo obrigatório.',
                ],
                confirm: [
                    v => !this.obj.confirm || !!v || 'Campo obrigatório, quando a confirmação estiver ativada.',
                ],
                upload: [
                    v => !this.obj.upload || !!v || 'Campo obrigatório, quando a confirmação estiver ativada.',
                ],
            },
            justificativa: {
                headers: [
                    { text: 'ID Processo', value: 'id_processo', align: 'center'},
                    { text: 'Descrição do Processo', value: 'desc', align: 'center'},
                    { text: 'Justificativa', value: 'justificativa', align: 'center'},
                    { text: '', value: 'remover', align: 'center', sortable: false },
                ],
                dados: [],
                pagination: {}, 
                dialogo: false,
                objNovo: {
                    id_processo: null,
                    justificativa: null,
                },
                obj: {},
            },
            modelo: {
                headers: [
                    { text: 'ID Processo', value: 'id_processo', align: 'center'},
                    { text: 'Descrição do Processo', value: 'desc', align: 'center'},
                    { text: 'ID Modelo', value: 'id_modelo', align: 'center'},
                    { text: 'Modelo', value: 'modelo', align: 'center'},
                    { text: '', value: 'remover', align: 'center', sortable: false },
                ],
                dados: [],
                pagination: {}, 
                dialogo: false,
                objNovo: {
                    id_processo: null,
                    id_modelo: null,
                },
                obj: {},
            },
            status_entrada: {
                headers: [
                    { text: 'ID Processo', value: 'id_processo', align: 'center'},
                    { text: 'Descrição do Processo', value: 'desc', align: 'center'},
                    { text: 'ID Status', value: 'id_status', align: 'center'},
                    { text: 'Status', value: 'status', align: 'center'},
                    { text: '', value: 'remover', align: 'center', sortable: false },
                ],
                dados: [],
                pagination: {}, 
                dialogo: false,
                objNovo: {
                    id_processo: null,
                    id_status: null,
                },
                obj: {},
            },
            status_saida: {
                headers: [
                    { text: 'ID Processo', value: 'id_processo', align: 'center'},
                    { text: 'Descrição do Processo', value: 'desc', align: 'center'},
                    { text: 'ID Status', value: 'id_status', align: 'center'},
                    { text: 'Status', value: 'status', align: 'center'},
                    { text: 'Regra', value: 'regra', align: 'center'},
                    { text: '', value: 'remover', align: 'center', sortable: false },
                ],
                dados: [],
                pagination: {}, 
                dialogo: false,
                objNovo: {
                    id_processo: null,
                    id_status: null,
                    regra: null,
                },
                obj: {},
            },
            importar_lote: {
                headers: [
                    { text: 'ID Processo', value: 'id_processo', align: 'center'},
                    { text: 'Descrição do Processo', value: 'desc', align: 'center'},
                    { text: 'Campo', value: 'campo', align: 'center'},
                    { text: 'Atributo', value: 'atributo', align: 'center'},
                    { text: '', value: 'remover', align: 'center', sortable: false },
                ],
                dados: [],
                pagination: {}, 
                dialogo: false,
                objNovo: {
                    id_processo: null,
                    campo: null,
                    nome: null,
                },
                obj: {},
            },
            confirm: {
                dialogo: false,
                titulo: null,
                texto: null,
                obj: {},
            },
        }
    },
    watch: {
        pagination: {
            handler () {
                this.filtrar()
            },
            deep: true,
        },
        busca(v) {

            setTimeout(() => {

                if(v == this.busca){

                    this.filtrar()
                }

            }, 1000)
        },
        ativo() {

            this.filtrar()
        },
    },
    computed: {
        computedHeaders() {

            var headers = this.selectHeaders.length ? this.headers.filter(e => this.selectHeaders.includes(e.value)) : this.headers

            return headers
        },
    },
    async created() {

        this.lista.modelo = ( await this.$http().post(`/modelo/filtro`, {data: {ativo: [0,1]}}) ).data

        this.lista.status = ( await this.$http().post(`/status/filtro`, {data: {ativo: [0,1]}}) ).data
    },
    methods: {
        async filtrar() {

            this.carregando = true

            var data = {}

            data.pagination = this.pagination
            data.busca = this.busca

            data.filtro = {}
            data.filtro.ativo = this.ativo == null ? [] : [this.ativo]

            var response = await this.$http().post(`/${this.tabela}/lista`, {data: data})   

            this.dados = response.data
            this.total = response.data.length ? response.data[0].total : 0

            if(this.total == 0 && this.pagination.page > 1) {

                this.pagination.page = 1
                this.filtrar(false)
            }

            this.carregando = false
        },
        async exportar() {

            this.carregando = true

            var data = {}

            data.filtro = {}
            data.filtro.ativo = this.ativo == null ? [] : [this.ativo]

            var response = await this.$http().post(`/${this.tabela}/exportar`, {data: data})   

            window.location = this.$http('baseURL')+response.data.url

            this.carregando = false
        }, 
        novo() {

            this.tab = 'Dados do Processo'

            this.obj = {...this.objNovo}

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }

            this.dialogo = true
        },
        setDados() {

            this.obj.justificativas = this.dados.find(e => e.id_processo == this.obj.id_processo).justificativas
            this.justificativa.dados = this.obj.justificativas.filter(e => e).map(e => ({
                id_processo: this.obj.id_processo, 
                desc: this.obj.desc, 
                justificativa: e
            }))

            this.obj.modelos = this.dados.find(e => e.id_processo == this.obj.id_processo).modelos
            this.modelo.dados = this.obj.modelos.filter(e => e).map(e => ({
                id_processo: this.obj.id_processo, 
                desc: this.obj.desc, 
                id_modelo: e, 
                modelo: this.lista.modelo.find(m => m.id_modelo == e).nome
            }))

            this.obj.status_entrada = this.dados.find(e => e.id_processo == this.obj.id_processo).status_entrada
            this.status_entrada.dados = this.obj.status_entrada.filter(e => e).map(e => ({
                id_processo: this.obj.id_processo, 
                desc: this.obj.desc, 
                id_status: e, 
                status: this.lista.status.find(s => s.id_status == e).nome
            }))

            this.obj.status_saida = this.dados.find(e => e.id_processo == this.obj.id_processo).status_saida
            this.status_saida.dados = this.obj.status_saida.filter(e => e.id_status).map(e => ({
                id_processo: this.obj.id_processo, 
                desc: this.obj.desc, 
                id_status: e.id_status, 
                status: this.lista.status.find(s => s.id_status == e.id_status).nome,
                regra: e.regra,
            }))

            this.obj.campos = this.dados.find(e => e.id_processo == this.obj.id_processo).campos
            this.importar_lote.dados = this.obj.campos.filter(e => e.campo).map(e => ({
                id_processo: this.obj.id_processo, 
                desc: this.obj.desc, 
                campo: e.campo, 
                atributo: e.nome
            }))
        },
        editar(item) {
           
            this.obj = {...item}

            this.setDados()

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }  
            
            this.dialogo = true
        }, 
        async salvar() {

            for (const key in this.obj) {

                if (typeof this.obj[key] == 'string') {

                    this.obj[key] = this.obj[key].trim()
                }
            }

            if(!this.$refs.form.validate()) return

            this.carregando = true

            var data = this.obj

            this.obj.confirm = this.obj.confirm ? this.obj.confirm : false

            var response = await this.$http().post(`/${this.tabela}/salvar`, {data: data})

            this.carregando = false

            if(response.data.sucesso){

                await this.filtrar()

                let item = this.dados.find(e => e.id_processo == response.data.obj.id_processo)

                this.editar(item)
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }
        },
        ajuda() {

            window.open('https://materialdesignicons.com/', '_blank')
        },
        novaObj(tipo) {

            this[tipo].obj = {...this[tipo].objNovo}

            this[tipo].obj.id_processo = this.obj.id_processo
            this[tipo].obj.tipo = tipo

            let form = `form${tipo.charAt(0).toUpperCase() + tipo.slice(1)}`

            try {
                this.$refs[form].resetValidation()
            } catch (error) {
                //console.log(error)
            }

            this[tipo].dialogo = true
        },
        async salvarObj(tipo) {

            for (const key in this[tipo].obj) {

                if (typeof this[tipo].obj[key] == 'string') {

                    this[tipo].obj[key] = this[tipo].obj[key].trim()
                }
            }

            let form = `form${tipo.charAt(0).toUpperCase() + tipo.slice(1)}`

            if(!this.$refs[form].validate()) return

            this.carregando = true

            var data = this[tipo].obj

            var response = await this.$http().post(`/${this.tabela}/salvarObj`, {data: data})

            this.carregando = false

            if(response.data.sucesso){

                await this.filtrar()

                this.setDados()

                this[tipo].dialogo = false
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }
        },
        removerConfirm(item, tipo) {

            this.confirm.obj = {...item}

            this.confirm.obj.tipo = tipo

            if (tipo == 'justificativa') {

                this.confirm.titulo = 'Remover Justificativa'
                this.confirm.texto = `Deseja realmente remover a justificativa '${this.confirm.obj.justificativa}'?`
                this.confirm.funcao = this.removerObj
            }
            if (tipo == 'modelo') {

                this.confirm.titulo = 'Remover Modelo'
                this.confirm.texto = `Deseja realmente remover o modelo '${this.confirm.obj.modelo}'?`
                this.confirm.funcao = this.removerObj
            }
            if (['status_entrada', 'status_saida'].includes(tipo)) {

                this.confirm.titulo = 'Remover Status'
                this.confirm.texto = `Deseja realmente remover o status '${this.confirm.obj.status}'?`
                this.confirm.funcao = this.removerObj
            }
            if (tipo == 'importar_lote') {

                this.confirm.titulo = 'Remover Atributo'
                this.confirm.texto = `Deseja realmente remover o atributo '${this.confirm.obj.atributo}'?`
                this.confirm.funcao = this.removerObj
            }

            this.confirm.dialogo = true
        }, 
        async removerObj() {

            this.carregando = true

            var data = this.confirm.obj

            var response = await this.$http().post(`/${this.tabela}/removerObj`, {data: data})

            this.carregando = false

            if(response.data.sucesso){

                await this.filtrar()

                this.setDados()

                this.confirm.dialogo = false
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }
        },
        changeCampoNovo() {
           
           let obj = this.lista.campos.find(e => e.value == this.importar_lote.obj.campo)

           this.importar_lote.obj.nome = obj.text
       },
    },
}
</script>
<style>
.v-input--selection-controls {
    margin-top: 4px;
    padding-top: 4px;
}
</style>
